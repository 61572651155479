import React, { useEffect, useState } from "react";
import { Button, Select, notification } from "antd";
import Api from "../../shared/Api";
import { ISelectProps } from "../../shared/Interfaces";
import { useTranslation } from "react-i18next";
import EqSelect from "../../custom/eqSelect/EqSelect";
import "./UserManagement.scss";
import { Col, Row } from "antd-v5";
import EqButton from "../../custom/eqButton/EqButton";

const { Option } = Select;

// Define types for User and API responses
interface User {
    id: number;
    name: string;
    email: string;
}

interface ResetLinkResponse {
    resetLink: string;
}

const UserManagement: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<number | null>(null);
    const [actionResult, setActionResult] = useState<string>("");
    const { t } = useTranslation();

    // Fetch all users on component mount
    useEffect(() => {
        
        Api.get<User[]>("/api/User/GetAllUsers")
            .then((response: any) => {
                setUsers(response.data);
            })
            .catch((error: any) => {
                console.error("Error fetching users:", error);
                notification.error({ message: "Failed to fetch users!" });
            });
    }, []);

    const handleGenerateResetLink = () => {
        if (!selectedUser) {
            notification.error({ message: "Please select a user!" });
            return;
        }

        Api.post<ResetLinkResponse>("/api/User/GenerateResetLink", { email: selectedUser })
            .then((response) => {
                
                setActionResult(`${response.data}`);
                notification.success({ message: "Reset link generated successfully!" });
            })
            .catch((error) => {
                console.error("Error generating reset link:", error);
                notification.error({ message: "Failed to generate reset link!" });
            });
    };


    const GenerateVerificationLink = () => {
        if (!selectedUser) {
            notification.error({ message: "Please select a user!" });
            return;
        }

        Api.post<ResetLinkResponse>("/api/User/GenerateVerificationLink", { email: selectedUser })
            .then((response) => {
                
                setActionResult(`${response.data}`);
                notification.success({ message: "link generated successfully!" });
            })
            .catch((error) => {
                console.error("Error generating link:", error);
                notification.error({ message: "Failed to generate link!" });
            });
    };


    // const handleVerifyUser = () => {
    //     if (!selectedUser) {
    //         notification.error({ message: "Please select a user!" });
    //         return;
    //     }

    //     Api.post("/api/User/VerifyUser", { userId: selectedUser })
    //         .then(() => {
    //             setActionResult("User verified successfully!");
    //             notification.success({ message: "User verified successfully!" });
    //         })
    //         .catch((error) => {
    //             console.error("Error verifying user:", error);
    //             notification.error({ message: "Failed to verify user!" });
    //         });
    // };

    const userSelectProps: ISelectProps = {
        // mode: "multiple",
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,
        isTag: true,
        allowClear: true,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        placeholder: `${t("Select a user")}`,
        options: users?.map((user: any) => ({
            key: user.email,
            value: user.email,
            label: user.email,
        })),
        defaultSelectedValue: selectedUser,
        changeFunc: (value: any) => {
            setSelectedUser(value);
        },
    };

    const handleCopyLink = () => {
        if (actionResult) {
            navigator.clipboard.writeText(actionResult).then(() => {
                notification.success({ message: "Link copied to clipboard!" });
            });
        } else {
            notification.error({ message: "No link to copy!" });
        }
    };

    return (
        <div className="eq-user-management">
            <div className="card">
                <h1>User Management</h1>

                <EqSelect {...userSelectProps} />

                <EqButton
                    type="primary"
                    onClick={handleGenerateResetLink}
                    style={{ marginLeft: 10 }}
                >
                    Generate Reset Link
                </EqButton>

                <EqButton type="default" onClick={GenerateVerificationLink} style={{ marginLeft: 10 }}>
                    Generate Verification Link
                </EqButton>

                {actionResult && (
                    <>
                        <Row style={{ marginTop: 20 }}>
                            <EqButton type="primary" onClick={handleCopyLink}>
                                Copy Generated Link
                            </EqButton>
                        </Row>
                        {/* <Row style={{ marginTop: 20 }}>
                            <strong>Link:</strong> 
                            <Col span={24}>
                            {actionResult}
                            </Col>
                        </Row> */}
                    </>
                )}
            </div>
        </div>
    );
};

export default UserManagement;
