import { Row, Col, Skeleton, Avatar, Modal, Input, message } from "antd-v5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnimationDiv from "../../../custom/animationDiv/AnimationDiv";
import EqButton from "../../../custom/eqButton/EqButton";
import Api from "../../../shared/Api";
import {
    ApiEndpoints,
    FrontEndPoints,
    getDefaultImage,
    GetEventMessage,
    getUserIdFromToken,
    imageType,
    openNotificationWithIcon,
    ValidateIsSchool,
    ValidateIsStudent,
    ValidateIsUser,
} from "../../../shared/Common";
import "./TrainingCourseBoard.scss";
import TrainingCourseDrawer from "../trainingCourseDrawer";
import EqCourseCard from "../EqCourseCard/EqCourseCard";
import { useAuthState } from "../../../context/Context";
import {
    CourseUI,
    IEqTrainingCourseBoard,
    ISchoolStudents,
    EqImageInfoDto,
    ITrainerOption,
    ICourseLessonUI,
    ICourseLessonOrderUI,
    EqEntityCredit,
    CreditType,
} from "../../../shared/interfaces/ridingCourse/IRidingCourse";
import { CourseOrderType, eqNotificationTypes, EqOrderStatus } from "../../../shared/enums";
import { useLocation, useNavigate } from "react-router-dom";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import { ISelectProps } from "../../../shared/Interfaces";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const TrainingCourseBoard = ({ updateCount, activeCourse }: IEqTrainingCourseBoard) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const userDetails: any = useAuthState();
    const isLoggedIn: boolean = ValidateIsUser(userDetails);
    const studentVal = ValidateIsStudent(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const loggedUserId = getUserIdFromToken();

    const [isError, setIsError] = useState(false);
    const [isStudent, setIsStudent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [spinning, setSpinning] = useState(false);
    const [isEventLoading, setIsEventLoading] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [editCourse, setEditCourse] = useState<CourseUI | undefined>(undefined);

    const [imagesData, setImagesData] = React.useState<EqImageInfoDto[]>([]);
    const [schoolStudents, setSchoolStudents] = useState<ISchoolStudents[]>([]);
    const [studentHorsesList, setStudentHorsesList] = useState([]);

    const [courseList, setCourseList] = useState<any[]>([]);
    const [courseListUnsorted, setCourseListUnsorted] = useState<any[]>([]);
    const [trainersOptions, setTrainersOptions] = useState<ITrainerOption[]>([]);
    const [isOwner, setIsOwner] = React.useState(false);
    const [sortBy, setSortBy] = useState<number | undefined>(undefined);
    const [confirmModal, contextHolderConfirm] = Modal.useModal();
    const [paymentModal, contextHolderPaymentModal] = Modal.useModal();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderAmount, setOrderAmount] = useState<string>("");
    const [orderId, setOrderId] = useState<string>("");
    const [credits, setCredits] = useState<EqEntityCredit[]>([]);

    useEffect(() => {
        if (isLoggedIn) {
            if (studentVal) {
                fetchUserCredits();
                setIsStudent(studentVal);
                fetchUserCourses(studentVal);
            } else {
                fetchUserCourses();
                fetchTrainersOptions();
                fetchImages();
                fetchSchoolStudentsWithHorses();
            }
        }

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (isLoggedIn && !studentVal) {
            fetchUserCourses();
        }
    }, [activeCourse]);

    //initial gets
    const fetchUserCredits = async () => {
        try {
            const response = await Api.get(ApiEndpoints.UserCredit.GetAll, {
                params: { unused: true },
            });

            setCredits(response.data);
        } catch (error: any) {
            handleError(error, t("fetchUserCreditsError"));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setOrderAmount("");
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleError = (error: any, customMessage?: string) => {
        const errorMessage = customMessage || GetEventMessage(error?.response?.status || 500);

        setIsError(true);
        setErrorMessage(errorMessage);
        console.error(errorMessage, error);
    };
    const fetchUserCourses = async (isStudent: boolean = false) => {
        const path = isStudent ? ApiEndpoints.Course.GetStudentAll : ApiEndpoints.Course.GetAll;
        try {
            const response = await Api.get(path);
            if (response.status === 200) {
                const sortedCourses = response.data.sort(
                    (a: any, b: any) => b.createdDate - a.createdDate
                );
                setCourseListUnsorted([...sortedCourses]);
                setCourseList(sortedCourses);
            }
        } catch (error) {
            handleError(error, t("fetchCoursesError"));
        }
    };

    const applySort = (sortedKey: number | undefined) => {
        switch (sortedKey) {
            case 0:
                const today = new Date();
                const upcomingCourses = courseListUnsorted.sort((a, b) => {
                    const dateA = new Date(a.startDate);
                    const dateB = new Date(b.startDate);

                    const isUpcomingA = dateA >= today;
                    const isUpcomingB = dateB >= today;

                    // Place upcoming events first
                    if (isUpcomingA && !isUpcomingB) return -1;
                    if (!isUpcomingA && isUpcomingB) return 1;

                    // Sort by date (ascending) within the same group
                    return dateA.getTime() - dateB.getTime();
                });

                setCourseList(upcomingCourses);
                return;
            case 1:
                const newestFirstCourses = courseListUnsorted.sort(
                    (a: any, b: any) => b.createdDate - a.createdDate
                );
                setCourseList(newestFirstCourses);
                return;
            default:
                const sortedCourses = courseListUnsorted.sort(
                    (a: any, b: any) => b.createdDate - a.createdDate
                );
                setCourseList(sortedCourses);
                return;
        }
    };

    const fetchImages = async () => {
        try {
            const response = await Api.get(ApiEndpoints.Image.GetUserImages);
            if (response.data) {
                setImagesData(response.data as EqImageInfoDto[]);
            }
        } catch (error) {
            handleError(error, t("fetchImagesError"));
        }
    };

    const fetchUserCourseDetailsById = async (courseKey: number) => {
        setIsEventLoading(true);
        let path = ApiEndpoints.Course.GetById + "/" + courseKey;

        Api.get(path).then(
            (response) => {
                if (response && response.status === 200) {
                    var data: CourseUI = response.data;
                    console.log(
                        "The response that we get from the API is on the course board is ",
                        data
                    );
                    setEditCourse(data);
                    console.log("EventListObject", data);
                } else {
                    setIsError(true);
                    console.error("GetUserCourses", GetEventMessage(response.status));
                }
                setIsEventLoading(false);
            },
            (error) => {
                setIsError(true);
                console.error("GetUserCourses", GetEventMessage(error.response.status));
                setIsEventLoading(false);
            }
        );
    };

    const fetchTrainersOptions = async () => {
        try {
            const response = await Api.get(ApiEndpoints.SchoolGetTrainers);
            if (response.status === 200) {
                const trainers = response.data;
                const trainerOptions = [
                    {
                        key: loggedUserId,
                        value: userDetails.name,
                        label: userDetails.name,
                        icon: (
                            <Avatar
                                size={30}
                                src={
                                    userDetails.userImage?.url || getDefaultImage(imageType.trainer)
                                }
                            />
                        ),
                    },
                    ...trainers.map((item: any) => ({
                        key: item.id,
                        value: `${item.firstName} ${item.lastName}`,
                        label: item.firstName,
                        icon: (
                            <Avatar
                                size={30}
                                src={item.userImage?.url || getDefaultImage(imageType.trainer)}
                            />
                        ),
                    })),
                ];
                setTrainersOptions(trainerOptions);
            }
        } catch (error: any) {
            setIsError(true);
            setErrorMessage(GetEventMessage(error.response?.status || 500));
        }
    };
    //console.log("Fetched Data Type is", trainersOptions);

    const handleCreateClick = () => {
        setEditCourse(undefined); // Reset edit course data
        setIsDrawerVisible(true);
    };

    const handleCloseDrawer = () => {
        setEditCourse(undefined);
        setIsDrawerVisible(false);
    };

    // const handleOnCreateCourse = async (data: any) => {
    //     try {
    //         //
    //         setSpinning(true);
    //         const response = await Api.post(ApiEndpoints.Course.Create, data);
    //         if (response.status === 200) {
    //             const newCourse = response.data;
    //             const updatedCourses = [...courseList];
    //             updatedCourses.push(newCourse);
    //             setCourseList(updatedCourses);
    //             updateCount && updateCount(updatedCourses.length);
    //             setIsDrawerVisible(false);
    //         } else {
    //             openNotificationWithIcon(t("serverError"), t("generalServerError"));
    //         }
    //     } catch (error) {
    //         openNotificationWithIcon(t("serverError"), t("generalServerError"));
    //         console.error("Error creating or updating course:", error);
    //     } finally {
    //         setSpinning(false);
    //     }
    // };

    const handleOnCreateCourse = async (
        data: any,
        isPublishDraft = false,
        draftCourseId?: string
    ) => {
        try {
            setSpinning(true);
            
            let endpoint = ApiEndpoints.Course.Create;
            let payload = data;

            // Check if the action is to publish a draft
            if (isPublishDraft && draftCourseId) {
                endpoint = `${ApiEndpoints.Course.PublishDraftedCourse}?courseId=${draftCourseId}`;
            }

            const response = await Api.post(endpoint, payload);

            if (response.status === 200) {
                const newCourse = response.data;
                const updatedCourses = [...courseList];

                if (isPublishDraft) {
                    // Replace the draft in the list with the published course
                    const draftIndex = updatedCourses.findIndex(
                        (course) => course.id === draftCourseId
                    );
                    if (draftIndex !== -1) {
                        updatedCourses[draftIndex] = newCourse;
                    }
                } else {
                    // Add a new course to the list
                    updatedCourses.push(newCourse);
                }

                setCourseList(updatedCourses);
                updateCount && updateCount(updatedCourses.length);
                setIsDrawerVisible(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        } catch (error) {
            openNotificationWithIcon(t("serverError"), t("generalServerError"));
            console.error("Error creating or updating course:", error);
        } finally {
            setSpinning(false);
        }
    };

    const handleOnUpdateCourseDetails = async (data: any) => {
        try {
            setSpinning(true);
            // Replace with your API endpoint and method
            const response = await Api.put(ApiEndpoints.Course.UpdateDetails, data);
            if (response && response.status === 200) {
                const updatedEvent = response.data;

                // Find the event in the list
                const courses = [...courseList];
                const foundIndex = courses.findIndex((x) => x.id === updatedEvent.id);

                if (foundIndex !== -1) {
                    const prevEvent = courses[foundIndex];

                    // Check draft status before and after update to show appropriate notification
                    openNotificationWithIcon(
                        t("updated"),
                        t("eventUpdated"),
                        eqNotificationTypes.success
                    );

                    // Update the event in the list
                    UpdateCourseList(updatedEvent);
                    setEditCourse(updatedEvent);
                }
            } else {
                openNotificationWithIcon(
                    t("serverError"),
                    t("generalServerError"),
                    eqNotificationTypes.error
                );
            }
        } catch (error) {
            console.error("Error creating or updating event:", error);
            openNotificationWithIcon(
                t("serverError"),
                t("generalServerError"),
                eqNotificationTypes.error
            );
        } finally {
            setSpinning(false);
        }
    };

    const UpdateCourseList = (updatedCourse: any) => {
        // Only update if eventsList is defined
        if (courseList) {
            const updatedCourses = courseList.map((course: any) =>
                course.id === updatedCourse.id ? updatedCourse : course
            );
            setCourseList(updatedCourses);
        }

        // If editing the event, update the edit state
        // if (editEvent?.id === updatedEvent.id) {
        //     setEditEvent(updatedEvent);
        // }
    };

    const handleOnUpdateCourseLesson = async (data: any) => {
        try {
            setSpinning(true);
            // Replace with your API endpoint and method
            const response = await Api.put(ApiEndpoints.Course.UpdateLesson, data);
            if (response && response.status === 200) {
                const updatedLesson = response.data;

                if (editCourse !== undefined) {
                    const prevEvent = editCourse;
                    // Find and update the specific lesson in the lessons array
                    const lessonIndex = prevEvent.courseLessons.findIndex(
                        (lesson: any) => lesson.id === updatedLesson.id
                    );
                    if (lessonIndex !== -1) {
                        prevEvent.courseLessons[lessonIndex] = {
                            ...prevEvent.courseLessons[lessonIndex],
                            ...updatedLesson,
                        };
                    }
                    setEditCourse(prevEvent);
                }

                // Check draft status before and after update to show appropriate notification
                openNotificationWithIcon(
                    t("updated"),
                    t("eventLUpdated"),
                    eqNotificationTypes.success
                );

                // find lesson in prev Event and update
            } else {
                openNotificationWithIcon(
                    t("serverError"),
                    t("generalServerError"),
                    eqNotificationTypes.error
                );
            }
        } catch (error) {
            console.error("Error creating or updating event:", error);
            openNotificationWithIcon(
                t("serverError"),
                t("generalServerError"),
                eqNotificationTypes.error
            );
        } finally {
            setSpinning(false);
        }
    };

    const handleOnRemoveCourseOrder = (order: any, refund: boolean, refundAmount?: number) => {
        setSpinning(true);
        Api.delete(
            `${ApiEndpoints.Course.removeFromCourse}/${order.id}?refund=${refund}&refundAmount=${refundAmount}`
        )
            .then((response) => {
                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("removed"),
                        t("attendeeRemoved"),
                        eqNotificationTypes.success
                    );

                    UpdateCourseList(response.data);
                    setEditCourse(response.data);
                } else {
                    setIsError(true);
                    // Optionally handle error messages here
                    // setErrorMessage(GetEventMessage(response.status));
                }
                setSpinning(false);
            })
            .catch((error) => {
                setIsError(true);
                // Optionally handle error messages here
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                setSpinning(false);
            });
    };

    const handleOnRemoveLessonOrder = (
        lessonId: string,
        order: any,
        refund: boolean,
        refundAmount?: number
    ) => {
        
        setSpinning(true);
        Api.delete(
            `${ApiEndpoints.Course.removeFromLesson}/${order.id}?lessonId=${lessonId}&refund=${refund}&refundAmount=${refundAmount}`
        )
            .then((response) => {
                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("removed"),
                        t("attendeeRemoved"),
                        eqNotificationTypes.success
                    );

                    UpdateCourseList(response.data);
                    setEditCourse(response.data);
                } else {
                    setIsError(true);
                    // Optionally handle error messages here
                    // setErrorMessage(GetEventMessage(response.status));
                }
                setSpinning(false);
            })
            .catch((error) => {
                setIsError(true);
                // Optionally handle error messages here
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                setSpinning(false);
            });
    };

    const fetchSchoolStudentsWithHorses = () => {
        Api.get(ApiEndpoints.SchoolStudents.GetSchoolStudentsGroupDto).then(
            (response) => {
                if (response && response.status === 200) {
                    setErrorMessage("");
                    var schoolStudents = response.data;
                    // getting both student and School Horses and making list
                    setSchoolStudents(response.data);

                    // getting both student and School Horses and making list
                    // and adding school horse in each student horse list
                    var studentHorsesListOptions = [] as any;

                    [...schoolStudents].map((item: any) => {
                        let horseListObj = [] as any;

                        if (item.user.horses.length > 0) {
                            [...item.user.horses].map((h: any) => {
                                let horseObj = {
                                    key: h.id,
                                    value: h.name,
                                    label: h.name,
                                };
                                horseListObj.push(horseObj);
                            });
                        }
                        if (item.school.horses.length > 0) {
                            [...item.school.horses].map((h: any) => {
                                let horseObj = {
                                    key: h.id,
                                    value: h.name,
                                    label: h.name,
                                };
                                horseListObj.push(horseObj);
                            });
                        }

                        let studentHorsesObj = {
                            key: item.user.id,
                            horseList: horseListObj,
                        };

                        studentHorsesListOptions.push(studentHorsesObj);
                    });
                    setStudentHorsesList(studentHorsesListOptions);
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };

    const updateImageLibrary = (newImage: any) => {
        setImagesData((prevImages: any) => [...prevImages, newImage]);
    };

    const removeFromImageLibrary = (imageId: any) => {
        setImagesData((prevImages: any) => prevImages.filter((image: any) => image.id !== imageId));
    };

    const handlePayNow = (course: CourseUI, orderId: string) => {
        if (credits && credits.length > 0) {
            var owner = course.createdBy;
            let creditExist = credits.some(
                (u: EqEntityCredit) =>
                    u.fromUserId === owner.id && u.creditType === CreditType.Course
            );

            if (creditExist) {
                PayNowModal(orderId);
            } else {
                GetCourseOrderCheckoutSession(orderId);
            }
        } else {
            GetCourseOrderCheckoutSession(orderId);
        }
    };

    const GetCourseOrderCheckoutSession = async (orderId: any) => {
        try {
            let path = "api/CoursePayment/GetCourseOrderCheckoutSession" + `/${orderId}`;
            // Replace with your API endpoint and method
            const response = await Api.get(path);
            if (response && response.status === 200) {
                window.location.href = response.headers.location;
            }
            // Optionally handle the result
        } catch (error: any) {
            if (error.response.status === 409) {
                // already exist
                openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
                // setIsEventSubscribed(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                console.error("Error Buy Course:", error);
            }
        }
    };
    const handlePayWithCredit = async (orderId: any) => {
        try {
            let path = "api/CoursePayment/PayWithCredit" + `/${orderId}`;
            // Replace with your API endpoint and method
            const response = await Api.get(path);
            if (response && response.status === 200) {
                // update course
                const updatedCourseList = courseList.map((course) => {
                    if (course.id === response.data.courseId) {
                        // Update the specific order in courseLessonOrders
                        const updatedLessonOrders = course.courseLessonOrders.map((order: any) =>
                            order.id === orderId ? { ...order, ...response.data } : order
                        );
                        return { ...course, courseLessonOrders: updatedLessonOrders };
                    }
                    return course;
                });

                // Update the state with the updated course list
                setCourseList(updatedCourseList);

                // update credit
                fetchUserCredits();
            }
            // Optionally handle the result
        } catch (error: any) {
            if (error.response.status === 409) {
                // already exist
                openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
                // setIsEventSubscribed(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                console.error("Error Buy Course:", error);
            }
        }
    };

    const GetCourseLessonOrderCheckoutSession = async (orderId: any) => {
        try {
            let path = "api/CoursePayment/GetCourseLessonOrderCheckoutSession" + `/${orderId}`;
            // Replace with your API endpoint and method
            const response = await Api.get(path);
            if (response && response.status === 200) {
                window.location.href = response.headers.location;
            }
            // Optionally handle the result
        } catch (error: any) {
            if (error.response.status === 409) {
                // already exist
                openNotificationWithIcon("Error!", error.response.data, eqNotificationTypes.error);
                // setIsEventSubscribed(false);
            } else {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
                console.error("Error Buy Course:", error);
            }
        }
    };

    const navigateToLogin = (courseId: any) => {
        // e.stopPropagation();

        navigate(FrontEndPoints.login, {
            state: {
                prevPath: `${location.pathname + location.search}&courseKey=${courseId}`,
            },
        });
    };

    const PayNowModal = (orderId: string) => {
        paymentModal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "You have Credits Available.\n Would you like to Use Credit Or Go to Checkout",
            okText: "Credit",
            cancelText: "Checkout",
            onOk: () => handlePayWithCredit && handlePayWithCredit(orderId),
            onCancel: () => GetCourseOrderCheckoutSession(orderId),
            // update footer
        });
    };

    const confirmJoinRequest = (courseId: any) => {
        confirmModal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content:
                "You may not be able to join all lessons from the course because some are already taken or the course has already started.",
            okText: "request",
            cancelText: "cancel",
            // onOk: () => removeAttendeeFunc(courseData),
            onOk: () => onClickBuy && onClickBuy(courseId),
            //   onCancel: () => AcceptProductOrderInvite(productOrder),
            footer: (
                <Row justify={"end"}>
                    {/* Discard Button */}
                    <EqButton
                        style={{ marginRight: "2px" }}
                        bordered
                        onClick={() => {
                            Modal.destroyAll(); // Close the modal on discard
                        }}
                    >
                        {t("cancel")}
                    </EqButton>

                    {/* Save as Draft Button */}
                    <EqButton
                        onClick={() => {
                            onClickBuy(courseId); // Call your save as draft function
                            Modal.destroyAll(); // Close the modal after saving
                        }}
                    >
                        {t("request")}
                    </EqButton>
                </Row>
            ),
            // Hide the default OK and Cancel buttons
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
        });
    };

    const handleJoinRequest = (course: CourseUI) => {
        // Check if the course has started or any lessons/lesson orders are taken
        const courseStarted = new Date(course.startDate) < new Date();
        const lessonsTaken = course.courseLessons.some((lesson: ICourseLessonUI) =>
            lesson.courseLessonOrders.some(
                (order: ICourseLessonOrderUI) =>
                    (order.orderStatus === EqOrderStatus.Accepted ||
                        order.orderStatus === EqOrderStatus.Paid) &&
                    order.orderType === CourseOrderType.LessonOnly
            )
        );

        if (courseStarted || lessonsTaken) {
            // Show the popup
            confirmJoinRequest(course.id);
        } else {
            // Proceed with the join request
            console.log("Proceeding with the join request...");
            // Add your join logic here
            onClickBuy(course.id);
        }
    };

    const onClickBuy = (courseId: any) => {
        const path = ApiEndpoints.CourseJoin.Request;

        Api.post(path, courseId).then(
            (response) => {
                if (response && response.status === 200) {
                    const newLessonOrder = response.data; // Response data containing new lesson orders

                    // Update courseLessonOrders in the courseList state
                    setCourseList((prevCourses) =>
                        prevCourses.map((course) =>
                            course.id === courseId
                                ? {
                                      ...course,
                                      courseLessonOrders: [
                                          ...(course?.courseLessonOrders || []),
                                          newLessonOrder,
                                      ],
                                  }
                                : course
                        )
                    );
                    setEditCourse((prevEdit: any) => {
                        if (prevEdit?.id === courseId) {
                            return {
                                ...prevEdit,
                                courseLessonOrders: [
                                    ...(prevEdit?.courseLessonOrders || []),
                                    newLessonOrder,
                                ],
                            };
                        }
                        return prevEdit;
                    });
                    openNotificationWithIcon(
                        "Requested!",
                        "Course Request is Sent",
                        eqNotificationTypes.success
                    );
                }
            },
            (error) => {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        );
    };

    const onCourseLessonJoinRequest = (courseId: any, lessonId: any) => {
        const path = ApiEndpoints.CourseJoin.LessonRequest;

        Api.post(path, lessonId).then(
            (response) => {
                if (response && response.status === 200) {
                    const newLessonOrder = response.data; // Response data containing new lesson orders

                    // Update courseList state
                    setCourseList((prevCourses) =>
                        prevCourses.map((course) =>
                            course.id === courseId
                                ? {
                                      ...course,
                                      courseLessons: course.courseLessons.map((lesson: any) =>
                                          lesson.id === lessonId
                                              ? {
                                                    ...lesson,
                                                    courseLessonOrders: [
                                                        ...(lesson?.courseLessonOrders || []),
                                                        newLessonOrder,
                                                    ],
                                                }
                                              : lesson
                                      ),
                                  }
                                : course
                        )
                    );

                    // Update editCourse state
                    setEditCourse((prevEdit: any) => {
                        if (prevEdit?.id === courseId) {
                            return {
                                ...prevEdit,
                                courseLessons: prevEdit.courseLessons.map((lesson: any) =>
                                    lesson.id === lessonId
                                        ? {
                                              ...lesson,
                                              courseLessonOrders: [
                                                  ...(lesson?.courseLessonOrders || []),
                                                  newLessonOrder,
                                              ],
                                          }
                                        : lesson
                                ),
                            };
                        }
                        return prevEdit;
                    });

                    openNotificationWithIcon(
                        "Requested!",
                        "Course Request is Sent",
                        eqNotificationTypes.success
                    );
                }
            },
            (error) => {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        );
    };

    const editClick = async (course: any): Promise<void> => {
        await fetchUserCourseDetailsById(course.id);
        setIsDrawerVisible(true);
    };
    //courseList.map((course, index) => {
    //    console.log("The Course is", course);
    //})

    // const DeleteCourse = (courseId: string) => {
    //     
    //     Api.delete(`${ApiEndpoints.Course.Delete}/${courseId}`).then(
    //         (response) => {
    //             if (response && response.status === 200) {
    //                 openNotificationWithIcon(
    //                     t("deleted"),
    //                     t("courseDeleted"),
    //                     eqNotificationTypes.success
    //                 );
    //                 removeItem(courseId);
    //             }
    //         },
    //         (error) => {
    //             if (error?.response?.status === 412) {
    //                 openNotificationWithIcon(
    //                     t("Conflict"),
    //                     t("Cannot delete the event as it has associated class orders.")
    //                 );
    //             } else {
    //                 openNotificationWithIcon(t("serverError"), t("generalServerError"));
    //             }
    //         }
    //     );
    // };

    const DeleteCourse = async (courseId: string) => {
        try {
            const response = await Api.delete(`${ApiEndpoints.Course.Delete}/${courseId}`);
            
            if (response && response.status === 200) {
                // Check if the response contains the expected data
                const message = response.data || t("courseDeleted");
                
                openNotificationWithIcon(
                    t("deleted"),
                    message,
                    eqNotificationTypes.success
                );
                removeItem(courseId); // Remove the item from the UI
            }
        } catch (error: any) {
            // Check if the error has a response object
            if (error?.response) {
                switch (error.response.status) {
                    case 412:
                        openNotificationWithIcon(
                            t("Conflict"),
                            t("Cannot delete the Course as it has associated orders."),
                            eqNotificationTypes.error
                        );
                        break;
                    default:
                        openNotificationWithIcon(
                            t("serverError"),
                            t("generalServerError"),
                            eqNotificationTypes.error
                        );
                }
            } else {
                // Handle network or unexpected errors
                console.error("Unexpected error occurred:", error);
                openNotificationWithIcon(
                    t("networkError"),
                    t("Something went wrong. Please try again later."),
                    eqNotificationTypes.error
                );
            }
        }
    };
    

    const removeItem = (courseId: any) => {
        if (courseList !== undefined) {
            var updatedList =
                courseList !== undefined
                    ? courseList.filter((item: any) => item.id !== courseId)
                    : new Array<any>();
            setCourseList(updatedList);
        }
    };

    const LeaveCourse = (course: any) => {
        let courseId = course.courseLessonOrders[0].id;
        Api.delete(`${ApiEndpoints.Course.StudentLeaveJoinedCourse}/${courseId}`).then(
            (response) => {
                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("removed"),
                        t("removed from the course"),
                        eqNotificationTypes.success
                    );
                    UpdateCourseList(response.data);
                }
            },
            (error) => {
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        );
    };

    const handleAcceptRequest = (orderId: any) => {
        
        if (editCourse === undefined) {
            return;
        }
        // check if first lesson has not passed and each lesson has slot available

        // get first lesson of course
        var firstLesson = editCourse?.courseLessons?.sort(
            (a: any, b: any) => a.startTime - b.startTime
        )[0];
        var lessonPassed = firstLesson?.startTime < new Date().getTime();

        let courseOrders = editCourse.courseLessonOrders;
        let lessonOrders = firstLesson.courseLessonOrders;
        let orders = [...courseOrders, ...lessonOrders] as any;

        let uniqueLessonOrders = orders.reduce((acc: any, curr: any) => {
            const existing = acc.find(
                (item: any) => item.courseId === curr.courseId && item.studentId === curr.studentId
            );
            if (!existing) {
                acc.push(curr); // If no object with the same courseId, add the current one
            } else if (curr.orderType === CourseOrderType.LessonOnly) {
                // If the current one is 'lessonOnly', replace the existing one
                const index = acc.indexOf(existing);
                acc[index] = curr;
            }
            return acc;
        }, []);
        let filteredOrdersList = uniqueLessonOrders.filter((or: any) => {
            return (
                or.orderStatus === EqOrderStatus.Accepted || or.orderStatus === EqOrderStatus.Paid
            );
        }) as any;

        let spaceLeft = firstLesson.capacity - filteredOrdersList.length;
        if (lessonPassed || spaceLeft <= 0) {
            setOrderId(orderId);
            openModal();
        } else {
            // Proceed with the join request
            console.log("Proceeding with the join request...");
            handleAcceptCourseOrderRequest(orderId, null);
        }

        // const courseStarted = new Date(editCourse.startDate) < new Date();
        // const lessonsTaken = editCourse.courseLessons.some((lesson: ICourseLessonUI) =>
        //     lesson.courseLessonOrders.some(
        //         (order: ICourseLessonOrderUI) =>
        //             (order.orderStatus === EqOrderStatus.Accepted ||
        //                 order.orderStatus === EqOrderStatus.Paid) &&
        //             order.orderType === CourseOrderType.LessonOnly
        //     )
        // );
        // if (courseStarted || lessonsTaken) {
        //     setOrderId(orderId);
        //     openModal();

        // } else {
        //     // Proceed with the join request
        //     console.log("Proceeding with the join request...");
        //     // Add your join logic here
        //     handleAcceptCourseOrderRequest(orderId, null);

        //     closeModal();
        // }
    };

    const handleAcceptRequestWithPrice = () => {
        const amount = Number(orderAmount);
        if (!orderAmount || isNaN(amount) || amount <= 0) {
            message.error(t("Please enter a valid amount"));
            return;
        }
        handleAcceptCourseOrderRequest(orderId, amount);

        closeModal();
    };

    const handleAcceptCourseOrderRequest = async (orderId: string, price: number | null) => {
        try {
            const response = await Api.put(`${ApiEndpoints.UserCourseRequest.Accept}/${orderId}`, {
                price,
            });
            if (response.status === 200) {
                const updatedLessonOrder = response.data;
                if (editCourse !== undefined) {
                    const updatedCourse = { ...editCourse };

                    const courseLessonOrderIndex = updatedCourse.courseLessonOrders?.findIndex(
                        (lessonOrder: any) => lessonOrder.id === updatedLessonOrder.id
                    );

                    if (courseLessonOrderIndex !== -1) {
                        const updatedLessonOrders = [...updatedCourse.courseLessonOrders];
                        updatedLessonOrders[courseLessonOrderIndex] = {
                            ...updatedLessonOrders[courseLessonOrderIndex],
                            ...updatedLessonOrder,
                        };
                        updatedCourse.courseLessonOrders = updatedLessonOrders;
                        // Update course object with modified lesson orders
                        setEditCourse(updatedCourse);
                        UpdateCourseList(updatedCourse);
                    }
                }
                openNotificationWithIcon(
                    "Accepted!",
                    "Successfully Accepted Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error: any) {
            if (error.response.status === 409) {
                openNotificationWithIcon(
                    "Error!",
                    `Unable to Accept Request for ${error.response.data.product.title}`,
                    eqNotificationTypes.error
                );
            } else {
                console.error("Error Accepting invite:", error);
                openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
            }
        }
    };

    const handleAcceptCourseLessonOrderRequest = async (orderId: any, price: number | null) => {
        try {
            const response = await Api.put(
                `${ApiEndpoints.UserCourseLessonRequest.Accept}/${orderId}`,
                { price }
            );

            if (response.status === 200) {
                const updatedLessonOrder = response.data;

                if (editCourse) {
                    const updatedCourse = { ...editCourse };

                    // Locate the specific lesson in courseLessons
                    const lessonIndex = updatedCourse.courseLessons?.findIndex(
                        (lesson: any) => lesson.id === updatedLessonOrder.courseLessonId
                    );

                    if (lessonIndex !== undefined && lessonIndex !== -1) {
                        const updatedLessons = [...(updatedCourse.courseLessons || [])];
                        const selectedLesson = { ...updatedLessons[lessonIndex] };

                        // Update the specific order in lessonOrders
                        const lessonOrderIndex = selectedLesson.courseLessonOrders?.findIndex(
                            (lessonOrder: any) => lessonOrder.id === updatedLessonOrder.id
                        );

                        if (lessonOrderIndex !== undefined && lessonOrderIndex !== -1) {
                            const updatedLessonOrders = [
                                ...(selectedLesson.courseLessonOrders || []),
                            ];
                            updatedLessonOrders[lessonOrderIndex] = {
                                ...updatedLessonOrders[lessonOrderIndex],
                                ...updatedLessonOrder,
                            };
                            selectedLesson.courseLessonOrders = updatedLessonOrders;
                        }

                        updatedLessons[lessonIndex] = selectedLesson;
                        updatedCourse.courseLessons = updatedLessons;
                    }

                    setEditCourse(updatedCourse);
                    UpdateCourseList(updatedCourse);
                }

                openNotificationWithIcon(
                    "Accepted!",
                    "Successfully Accepted Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error: any) {
            if (error.response?.status === 409) {
                openNotificationWithIcon(
                    "Error!",
                    `Unable to Accept Request for ${error.response.data?.product?.title}`,
                    eqNotificationTypes.error
                );
            } else {
                console.error("Error Accepting invite:", error);
                openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
            }
        }
    };

    const handleRejectCourseLessonOrderRequest = async (orderId: any) => {
        try {
            const response = await Api.put(
                `${ApiEndpoints.UserCourseLessonRequest.Reject}/${orderId}`
            );

            if (response.status === 200) {
                const updatedLessonOrder = response.data;

                if (editCourse) {
                    const updatedCourse = { ...editCourse };

                    // Locate the specific lesson in courseLessons
                    const lessonIndex = updatedCourse.courseLessons?.findIndex(
                        (lesson: any) => lesson.id === updatedLessonOrder.courseLessonId
                    );

                    if (lessonIndex !== undefined && lessonIndex !== -1) {
                        const updatedLessons = [...(updatedCourse.courseLessons || [])];
                        const selectedLesson = { ...updatedLessons[lessonIndex] };

                        // Update the specific order in lessonOrders
                        const lessonOrderIndex = selectedLesson.courseLessonOrders?.findIndex(
                            (lessonOrder: any) => lessonOrder.id === updatedLessonOrder.id
                        );

                        if (lessonOrderIndex !== undefined && lessonOrderIndex !== -1) {
                            const updatedLessonOrders = [
                                ...(selectedLesson.courseLessonOrders || []),
                            ];
                            updatedLessonOrders[lessonOrderIndex] = {
                                ...updatedLessonOrders[lessonOrderIndex],
                                ...updatedLessonOrder,
                            };
                            selectedLesson.courseLessonOrders = updatedLessonOrders;
                        }

                        updatedLessons[lessonIndex] = selectedLesson;
                        updatedCourse.courseLessons = updatedLessons;
                    }

                    setEditCourse(updatedCourse);
                    UpdateCourseList(updatedCourse);
                }

                openNotificationWithIcon(
                    "Accepted!",
                    "Successfully Accepted Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error: any) {
            if (error.response?.status === 409) {
                openNotificationWithIcon(
                    "Error!",
                    `Unable to Accept Request for ${error.response.data?.product?.title}`,
                    eqNotificationTypes.error
                );
            } else {
                console.error("Error Accepting invite:", error);
                openNotificationWithIcon("Error!", "Unable to Accept", eqNotificationTypes.error);
            }
        }
    };
    const handleRejectCourseOrderRequest = async (requestId: string) => {
        try {
            const response = await Api.put(`${ApiEndpoints.UserCourseRequest.Reject}/${requestId}`);

            if (response.status === 200) {
                const updatedLessonOrder = response.data;
                if (editCourse !== undefined) {
                    const prevCourse = { ...editCourse };
                    const lessonIndex = prevCourse.courseLessonOrders.findIndex(
                        (lessonOrder: any) => lessonOrder.id === updatedLessonOrder.id
                    );

                    if (lessonIndex !== -1) {
                        prevCourse.courseLessonOrders[lessonIndex] = {
                            ...prevCourse.courseLessonOrders[lessonIndex],
                            ...updatedLessonOrder,
                        };
                    }
                    setEditCourse(prevCourse);
                    UpdateCourseList(prevCourse);
                }
                openNotificationWithIcon(
                    "Rejected!",
                    "Successfully Rejected Request",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error Reject invite:", error);
            openNotificationWithIcon("Error!", "Unable to Reject", eqNotificationTypes.error);
        }
    };

    const handleDeleteCourseInvite = async (inviteId: string) => {
        try {
            const response = await Api.delete(
                `${ApiEndpoints.UserCourseInvite.DeleteInvite}/${inviteId}`
            );

            if (response.status === 200) {
                if (editCourse !== undefined) {
                    const prevCourse = { ...editCourse };
                    const CourseOrderIndex = prevCourse.courseLessonOrders.findIndex(
                        (courseOrder: any) => courseOrder.id === inviteId
                    );

                    if (CourseOrderIndex !== -1) {
                        prevCourse.courseLessonOrders = [
                            ...prevCourse.courseLessonOrders.filter(
                                (item: any) => item.id !== inviteId
                            ),
                        ];
                    }
                    setEditCourse(prevCourse);
                    UpdateCourseList(prevCourse);
                }
                openNotificationWithIcon(
                    "Deleted!",
                    "Successfully Removed Invite",
                    eqNotificationTypes.success
                );
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error deleting invite:", error);
            openNotificationWithIcon("Error!", "Unable to Delete", eqNotificationTypes.error);
        }
    };

    const sortOptions = [
        { key: 0, value: "Upcoming", label: t("upcoming") },
        { key: 1, value: "New", label: t("new") },
    ];

    const sortSelectProps: ISelectProps = {
        classNames: "sort-select",
        placeholder: `${t("sortBy")}`,
        allowClear: true,
        options: sortOptions,
        defaultSelectedValue: sortBy,
        changeFunc: (key: string) => {
            console.log("sortvalue", key);
            const intVal = key !== undefined ? parseInt(key, 10) : undefined;
            setSortBy(intVal);
            applySort(intVal);
        },
    };

    return (
        <div className="overall-container">
            <div className="training-course-board">
                {/* <AnimationDiv> */}
                <div className="eq-trainer-products-board eq-container-fluid">
                    <Row
                        align="middle"
                        style={{ margin: "10px 0" }}
                        justify="space-between"
                        gutter={16}
                    >
                        <Col flex="0 0 200px">
                            <EqSelect {...sortSelectProps} />
                        </Col>
                        {isSchool && !isDrawerVisible && (
                            <Col className="eq-event-buttons" flex="auto">
                                <Row justify="end">
                                    <Col>
                                        <EqButton
                                            type="button"
                                            classNames="btn-background-orange"
                                            onClick={handleCreateClick}
                                        >
                                            {editCourse ? t("update") : t("createCourse")}
                                        </EqButton>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                    <Row className="eq-event-heading-line"></Row>
                    <Row>
                        {[...Array(5)].map((_, index) => (
                            <Skeleton
                                key={index}
                                loading={!courseList}
                                paragraph={{ rows: 2 }}
                                active
                            />
                        ))}
                    </Row>
                    {courseList.length > 0 && (
                        <Row gutter={[16, 16]} className="event-card-list">
                            {courseList.map((course: any, index: number) => (
                                <Col xs={24} sm={12} xl={8} xxl={6} key={course.id}>
                                    <EqCourseCard
                                        key={index}
                                        isStudent={isStudent}
                                        // onBuy={isStudent ? () => { onClickBuy(course.id) } : undefined}
                                        onBuy={isStudent ? () => editClick(course) : undefined}
                                        onPayNow={(orderId: string) =>
                                            handlePayNow(course, orderId)
                                        }
                                        courseData={course}
                                        onEdit={() => editClick(course)}
                                        onDelete={
                                            isStudent
                                                ? () => LeaveCourse(course)
                                                : () => DeleteCourse(course.id)
                                        }
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}

                    {isDrawerVisible && (
                        <TrainingCourseDrawer
                            isEventLoading={isEventLoading}
                            onBuy={
                                isLoggedIn
                                    ? isStudent
                                        ? () => editCourse && handleJoinRequest(editCourse)
                                        : undefined
                                    : () => editCourse && navigateToLogin(editCourse.id) // Replace with your actual navigation logic
                            }
                            onPayNow={GetCourseOrderCheckoutSession}
                            onCourseLessonJoinRequest={(lessonId: any) =>
                                onCourseLessonJoinRequest(editCourse?.id, lessonId)
                            }
                            isStudent={isStudent}
                            onClose={handleCloseDrawer}
                            trainersOptions={trainersOptions}
                            schoolStudents={schoolStudents}
                            studentHorsesList={studentHorsesList}
                            imagesData={imagesData}
                            onImageUploadSuccess={updateImageLibrary}
                            onImageDeleteSuccess={removeFromImageLibrary}
                            onCreateCourse={handleOnCreateCourse}
                            onEditCourseDetails={handleOnUpdateCourseDetails}
                            onEditCourseLesson={handleOnUpdateCourseLesson}
                            editCourse={editCourse}
                            onRemoveLessonOrder={handleOnRemoveLessonOrder}
                            onRemoveCourseOrder={handleOnRemoveCourseOrder}
                            onDeleteCourseInvite={handleDeleteCourseInvite}
                            onAcceptCourseOrderRequest={(requestId: any) => {
                                handleAcceptRequest(requestId);
                            }}
                            onAcceptCourseLessonOrderRequest={(requestId: any) => {
                                handleAcceptCourseLessonOrderRequest(requestId, null);
                            }}
                            onRejectCourseOrderRequest={handleRejectCourseOrderRequest}
                            onRejectCourseLessonOrderRequest={handleRejectCourseLessonOrderRequest}
                            onPayNowLessonOrder={GetCourseLessonOrderCheckoutSession}
                        />
                    )}
                </div>
                {/* </AnimationDiv> */}
            </div>
            {contextHolderConfirm}
            {contextHolderPaymentModal}
            <Modal
                title={t("Enter Custom Price For Course Order")}
                className="refund-confirm-modal"
                open={isModalOpen}
                onCancel={closeModal}
                footer={null} // Custom footer
                closable={true}
                // closeIcon={<span style={{ fontSize: '16px', color: '#000' }}>X</span>}
            >
                <div>
                    {/* <p>{t("refundAttendee")}</p> */}
                    <Input
                        type="number"
                        placeholder={t("amount")}
                        min={0}
                        value={orderAmount}
                        onChange={(e) => setOrderAmount(e.target.value)}
                        style={{ marginBottom: "10px" }}
                    />
                </div>

                <Row style={{ padding: "2px" }} justify={"end"}>
                    {/* Refund Button */}
                    <EqButton
                        style={{ marginRight: "2px" }}
                        bordered
                        onClick={handleAcceptRequestWithPrice}
                    >
                        {t("accept")}
                    </EqButton>

                    {/* Pay Later Button */}
                    {/* <EqButton onClick={handlePayLater}>{t("cancel")}</EqButton> */}
                </Row>
            </Modal>
        </div>
    );
};

export default TrainingCourseBoard;
