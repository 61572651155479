import React, { useEffect, useState } from "react";
import "./HorsesList.scss";
import EqButton from "../../custom/eqButton/EqButton";
import EqInviteStudent from "../eqInviteStudent/EqInviteStudent";
import EqEditTrainer from "../editTrainer/eqEditTrainer";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { useTranslation } from "react-i18next";
import { Card, Checkbox, Col, Collapse, Modal, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Item from "antd/lib/list/Item";
import AsyncImage from "../../custom/asyncImage/AsyncImage";
import moment from "moment";
import { genderType } from "../../shared/enums";
import { dateFormat } from "../../shared/Common";
import EqCollapse, { IEqCollapse } from "../../custom/eqCollapse/EqCollapse";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EditorCreateProduct from "../../custom/editorCreateProduct/EditorCreateProduct";
import EditorCreateHorse from "../../custom/editorCreateHorse/EditorCreateHorse";
import { AxiosResponse } from "axios";
import Api from "../../shared/AApi";
import { IEqGroupMember } from "../eqGroupMembers/EqGroupMembers";
import { Popconfirm } from "antd-v5";


export interface IEqHorseList {
  horseList: any[];
  display: boolean;
  horse: any
  onEdit: any
  delFunc?: (item: any) => void;
  selected?: boolean;
  hideGroup?: boolean;
  hideName?: boolean;
  onCheck?: any;
}
const HorsesList = ({
  horseList,
  horse,
  onEdit,
  delFunc,
  display,
  selected = false,
  hideGroup,
  hideName,
  onCheck,
  ...rest
}: IEqHorseList) => {


  const { t, i18n } = useTranslation();



  const [editingHorse, setEditingHorse] = useState(null);
  const [selectedHorses, setSelectedHorses] = useState();

  const onCancel = () => {
    setEditingHorse(null);
  };


  const removeCard = (item: any) => {
    if (delFunc != null) {
      delFunc(item);
    }
  };
  const editCard = (item: any) => {
    setEditingHorse(item);
  };
  const Oncancel = () => {
  };


  const toggleCheck = (item: any) => {
    const list = [...horseList]
    var foundIndex = list.findIndex((x) => x.id == item.id);
    var prevhorse = list.find((x) => x.id === item.id);
    prevhorse.selected = !prevhorse.selected
    list[foundIndex] = prevhorse;
    setSelectedHorses(prevhorse);
  };

  useEffect(() => {
    console.log();
  }, [horseList]);


  return (
    <div className="horse-list">

      <Col md={12} xs={24} sm={24} className="mb-16"></Col>

      {horseList.map((item: any, index: any) => (

        <>
          <Row className="eq-f-row hidden-sm">
            {/* <Col className="eq-f-td" span={1}>
              <Checkbox
                onChange={() => {
                  onCheck(item);
                }}
                checked={item.selected}

              ></Checkbox>
            </Col> */}
            <Col className="eq-f-td horse-detail" span={9}>
              <div className="horse-info">
                <AsyncImage
                  className="event-img"
                  alt="example"
                  src={item.imageUrl}
                />
              </div>
              <div>
                {item.name}
              </div>
            </Col>
            <Col className="eq-f-td" span={6}>
              {/* {EqDisciplineType[financial.event.disciplineType]} */}
              {moment(item?.dateOfBirth).format(dateFormat)}
            </Col>
            <Col className="eq-f-td" span={6}>
              {t(genderType[item.type])}
            </Col>

            <Col className="eq-f-td" span={2}>
            <Popconfirm
                      title={t("delete")}
                      description={t("areYouSureToDeleteHorse")}
                      onConfirm={() => removeCard(item)}
                      onCancel={Oncancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined className="action-delete "

                      />

                    </Popconfirm>

              <EditOutlined className="action-edit "
                onClick={() => editCard(item)}
              />
            </Col>
            <div className="edit-horse-panel">
              {editingHorse === item && (
                <EqCollapsePanel

                  defaultOpen={true}
                  header={"Edit Horse"}
                  children={<EditorCreateHorse
                    horse={item}
                    onEdit={onEdit}
                    onCancel={onCancel}
                  />}
                />
              )}
            </div>
          </Row>

          <div className="hidden-md">
            <EqCollapse header={
              <Row justify="start" align="middle">
                {/* <Col className="" span={2}>
                  <Checkbox
                    onChange={() => {
                      onCheck(item);
                    }}
                    checked={item.selected}

                  ></Checkbox>
                </Col> */}
                <Col className="" flex="auto">
                  {item?.name}
                </Col>
              </Row>


            } >
              <>
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    {t("name")}
                  </Col>
                  <Col className="eq-card-row horse-detail" span={24}>
                    <div className="horse-info">
                      <AsyncImage
                        className="event-img"
                        alt="example"
                        src={item.imageUrl}
                      />
                    </div>
                    <div>
                      {item.name}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col flex="auto" className="eq-card-divider"></Col>
                </Row>
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    {t("dateofBirth")}
                  </Col>
                  <Col className="" span={24}>
                    {moment(item?.dateOfBirth).format(dateFormat)}
                  </Col>
                </Row>
                <Row>
                  <Col flex="auto" className="eq-card-divider"></Col>
                </Row>
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    {t("gender")}
                  </Col>
                  <Col className="" span={24}>
                    {t(genderType[item.type])}
                  </Col>
                </Row>
                <Row>
                  <Col flex="auto" className="eq-card-divider"></Col>
                </Row>
                <Row className="eq-card-row">
                  <Col className="eq-card-col-heading" span={24}>
                    {t("actions")}
                  </Col>
                  <Col className="" span={24}>

                    <Popconfirm
                      title="Delete Horses"
                      description="Are you sure to delete the selected Horse info?"
                      onConfirm={() => removeCard(item)}
                      onCancel={Oncancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined className="action-delete "

                      />

                    </Popconfirm>
                    <EditOutlined className="action-edit "
                      onClick={() => editCard(item)}
                    />
                  </Col>
                </Row>
              </>
              {editingHorse === item && (
                <EqCollapsePanel
                  defaultOpen={true}
                  header={"Edit Horse"}
                  children={<EditorCreateHorse
                    horse={item}
                    onEdit={onEdit} />}
                />
              )}
            </EqCollapse>

          </div>

        </>


      ))}

      {/* )} */}
    </div>
  );
};

export default HorsesList;
