import { loadState, LocalData } from "./LocalStorage";
import jwt_decode from "jwt-decode";
import Api from "./Api";
import { IEqEventData } from "./interfaces/IEqEventDto";
import moment from "moment";
import { eqNotificationTypes } from "./enums";
import { notification } from "antd";
import { GetLocations } from "./TempDataProvider";
import UserManagement from "../eqAdmin/userManagment/UserManagement";
// import { useTranslation } from "react-i18next";

export const dateFormat = "MMM DD, YY";
export const googleMap_API_Key = "AIzaSyDK8NH7UvnkaGZjMDtMdB_2P8oRtbIhZ4U";
export enum imageType {
    banner = 1,
    explanationBg,
    events,
    login,
    signup,
    partner,
    trainer,
    trainerIcon,
    training,
    groupChat,
}
// const { t, i18n } = useTranslation();

export const getDefaultImage = (type: imageType): string => {
    switch (type) {
        case imageType.banner:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/64ef5769-4ad8-485e-9fdf-4950009e6600/public";
        case imageType.events:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/a76588d5-bcf9-45e5-b308-0e7e75fdd900/public";
        case imageType.login:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/c6931165-b933-4be3-e9b6-0b4e32890d00/public";
        case imageType.signup:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/1af8e6ce-5571-420d-5116-2c02869aac00/public";
        case imageType.partner:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/cea9e3ba-fb42-4e62-c1ee-27356acfb900/public";
        case imageType.explanationBg:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/c898034d-e78c-4636-4daf-603ff0ec5e00/public";
        case imageType.trainer:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/422ebfde-9011-4ed2-1d42-2a72d1d42f00/public";
        case imageType.trainerIcon:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/fbe0a82b-78fc-4483-8ce8-109b22d37400/public";
        case imageType.training:
            return "https://imagedelivery.net/rPGP4q2oVwt6hVugC2ipgQ/3c12c270-a2fc-46e8-2aca-abd211724000/public";
        case imageType.groupChat:
            return"/icons/groupchat.svg"
            default:
            return "";
    }
};

export enum ErrorCodes {
    badRequest400 = 400,
    unAuthorized401 = 401,
    Forbidden403 = 403,
    NotAcceptable406 = 406,
    preConditionFailed409 = 409,
    userNotFound412 = 412,
    emailNotConfirmed417 = 417,
    serverError500 = 500,
    serverException502 = 502,
}

export enum EqStatusCode {
    Status405MethodNotAllowed = 405,
    Status406NotAcceptable=406,
    unAuthorized401 = 401,
    Forbidden403 = 403,
    ok = 200,
    accepted = 202,
    badRequest400 = 400,
    inCompleteCredentials406 = 406,
    preConditionFailed409 = 409,
    userNotFound412 = 412,
    emailNotConfirmed417 = 417,
    serverError500 = 500,
    serverException502 = 502,
}

export const ApiEndpoints = {

    // UserAccount
    UserAccount: {
        Login: "UserAccount/Login",
        ResetUserPassword: "UserAccount/ResetUserPassword",
        ResetPasswordWithToken: "UserAccount/ResetPasswordWithToken",
        UpdateUserPasswordAndVerifyEmail: "UserAccount/UpdateUserPasswordAndVerifyEmail"
    },

    Location: {
        GetLocationOptions: "Location/GetLocationOptions",
        GetUserLocationsOptions: "Location/GetUserLocationsOptions",
        GetUserAreaOptions: "Location/GetUserAreaOptions",

    },
    Image: {
        GetUserImages: "image/GetUserImages",
        uploadImage: "image/uploadImage",
        DeleteById: "image/DeleteById",
    },
    login: "Account/Login",

    RefreshToken: "Account/RefreshToken",
    resetPassword: "login/ResetPasswordWithToken",
    getMessages: "Message/GetMessages",
    GetNotifications: "Message/GetNotifications",
    GetMessagesByGroupKey: "Message/GetMessagesByGroupKey",

    sendMessage: "Message/SendMessage",
    SendReminder: "Message/SendReminder",
    sendReply: "Message/SendReply",
    saveMessage: "Message/SaveMessage",
    deleteMessage: "Message/DeleteMessage",
    deleteSelectedMessages: "Message/DeleteSelectedMessages",
    saveSelectedMessages: "Message/SaveSelectedMessages",
    setFlag: "Message/SetFlag",
    GetAllEvents: "Event/GetAllEvents",
    GetEventWithClassesByKey: "Event/GetEventWithClassesByKey",
    GetAllPublicEvents: "Event/GetAllPublicEvents",
    GetTrainerPublicEvents: "Event/GetTrainerPublicEvents",
    GetAllPrivateEvents: "Event/GetAllPrivateEvents",
    GetAllTrainings: "Event/GetAllTrainings",
    GetAllTrainingsByTrainer: "Event/GetAllTrainingsByTrainer",
    GetAllTrainingsByTrainerGroup: "Event/GetAllTrainingsByTrainerGroup",
    GetAllStudentTrainingsBySubscribedTrainers:
        "Event/GetAllStudentTrainingsBySubscribedTrainers",

    eventEdit: "Event/Edit",
    deleteEvent: "Event/DeleteEvent",
    deleteEventAttendee: "Event/DeleteEventAttendee",
    eventGetAreas: "Event/GetAreas",
    eventGetPopulation: "Event/GetPopulation",
    eventGetGroupPopulation: "Event/GetGroupPopulation",
    eventGetStudentPopulation: "Event/GetStudentPopulation",
    eventGetTrainerStudents: "Event/GetTrainerStudents",
    eventGetStudentsByGroupPopulation: "Event/GetStudentsByGroupPopulation",
    eventGetWeekDays: "Event/GetWeekDays",
    sendInvite: "Account/SendInvite",
    eventCreate: "Event/Create",

    // EqPublicEvent
    EqPublicEvent:{        
        GetAll: "/api/EqPublicEvent",
    },


    EqOwnerPublicEvent:{
        Create: '/api/EqOwnerPublicEvent',
        Update: '/api/EqOwnerPublicEvent',
        Delete: "/api/EqOwnerPublicEvent",
        GetAll: "/api/EqOwnerPublicEvent",
        GetAllPrevious: "/api/EqOwnerPublicEvent/previous",
        GetByUserKey:"/api/EqOwnerPublicEvent/GetByUserKey",
        RemoveAttendeeClassOrder: "/api/EqOwnerPublicEvent/remove-class-order",
        RemoveAttendeeClassOrderWithAmount: "/api/EqOwnerPublicEvent/remove-class-order-with-amount",
    },

    Course:{
        Create: '/api/Course',
        PublishDraftedCourse: '/api/Course/PublishDraftedCourse',
        GetAll: '/api/Course',
        GetAllPrevious: '/api/Course/Previous',
        GetStudentAll: '/api/Course/student-schools-courses',
        GetById: '/api/Course/details',
        GetByUserKey: '/api/Course/ByUserKey',
        Delete: "/api/Course",
        StudentLeaveJoinedCourse: "/api/Course/student-leave-course",
        removeFromLesson: "/api/Course/remove-lesson-order-with-amount",
        removeFromCourse: "/api/Course/remove-course-order-with-amount",
        UpdateDetails:"/api/Course/update-details",
        UpdateLesson:"/api/Course/update-lesson",

     
    },
    CourseJoin:{
        Request: '/api/CourseJoin/Request',
        LessonRequest: '/api/CourseJoin/lesson-request',
    
    },

    UserCourseRequest: {
        GetAll: "api/UserCourseRequest",
        Accept:"api/UserCourseRequest/Accept",
        Reject:"api/UserCourseRequest/Reject",
        AcceptMultipleRequests:"api/UserCourseRequest/Accept-multiple",
        RejectMultipleRequests:"api/UserCourseRequest/Reject-multiple",
        
      },

      UserCourseLessonRequest: {
        Accept:"api/UserCourseLessonRequest/Accept",
        Reject:"api/UserCourseLessonRequest/Reject",
        
        
      },
      CourseOrderInvite: {
        GetAll: "api/CourseOrderInvite",
        Accept:"api/CourseOrderInvite/Accept",
        Reject:"api/CourseOrderInvite/Reject",
        
      },

      CoursePayment: {
        GetCourseOrderCheckoutSession: "api/CoursePayment/GetCourseOrderCheckoutSession",
        GetCourseLessonOrderCheckoutSession: "api/CoursePayment/GetCourseLessonOrderCheckoutSession",
        
      },
    
    
      UserCourseInvite: {
        DeleteInvite:"api/UserCourseInvite",
        // GetAll: "api/UserCourseInvite",
        // DeleteMultipleInvites:"api/UserCourseInvite/delete-multiple",
        // UpdateInviteStatus:"api/UserCourseInvite/update-status",
        
      },
      Student:{
        GetUnpaidOrders: "Student/UnPaidOrders",
      },
    // TrainerTraining
    TrainerTraining: {
        CreateTraining: "TrainerTraining/CreateTraining",
        GetTrainerTrainingsOfMonths: "TrainerTraining/GetTrainerTrainingsOfMonths",
        GetTrainerPreviousTrainingsOfMonths: "TrainerTraining/GetTrainerPreviousTrainingsOfMonths",
        GetTrainerTrainingsByTrainerKeyOfMonths: "TrainerTraining/GetTrainerTrainingsByTrainerKeyOfMonths",
        GetAllTrainerTrainingsLessonsForStudents: "TrainerTraining/GetAllTrainerTrainingsLessonsForStudents",


        GetAllByTrainerKey: "TrainerTraining/GetTrainerTrainingsByKey",
        // GetTrainerTrainings: "TrainerTraining/GetTrainerTrainings",
        GetAllTrainerOpenSlotsByTrainerKey: "TrainerTraining/GetAllTrainerOpenSlotsByTrainerKey",
        GetAllLessonsByTrainerKey:
            "TrainerTraining/GetAllTrainerTrainingsLessonsByTrainerKey",
        UpdateTraining: "TrainerTraining/UpdateTrainingWithAllLessons",
        UpdateTrainingInfo: "TrainerTraining/UpdateTrainingInfoWithAllLessons",
        Delete: "TrainerTraining/Delete",
        SettleAttendeeOrder: "TrainerTraining/SettleAttendeeOrder",
        AcceptAttendeeOrderRequest: "TrainerTraining/AcceptAttendeeOrderRequest"
    },
    // TrainerTrainingsOfMonths: "TrainerTraining/GetTrainerTrainingsOfMonths",
    TrainerTrainingInvite: {
        GetAll: "api/TrainerTrainingInvite",
        DeleteInvite: "api/TrainerTrainingInvite",
        DeleteMultipleInvites: "api/TrainerTrainingInvite/delete-multiple",
        UpdateInviteStatus: "api/TrainerTrainingInvite/update-status",
    },


    UserMessage: {
        Create: "api/UserMessage",
        SendMessageToEmail: "api/UserMessage/SendMessageToEmail",
        GetAll: "api/UserMessage",
        Delete: "api/UserMessage",
        DeleteMultiple: "api/UserMessage/delete-multiple",
        UpdateStatus: "api/UserMessage",
        getUserMessagesCount: "api/UserMessageCount"
    },

    UserMessageReply: {
        Create: "api/UserMessageReply",
    },


    UserCredit: {
        GetAll: "api/UserCredit",
    },
    UserMessageRecipients: "api/UserMessageRecipients",

    SchoolTrainingInvite: {
        GetAll: "api/SchoolTrainingInvite",
        DeleteInvite: "api/SchoolTrainingInvite",
        DeleteMultipleInvites: "api/SchoolTrainingInvite/delete-multiple",
        UpdateInviteStatus: "api/SchoolTrainingInvite/update-status",

    },

  UserProductInvite: {
    GetAll: "api/UserProductInvite",
    DeleteInvite:"api/UserProductInvite",
    DeleteMultipleInvites:"api/UserProductInvite/delete-multiple",
    UpdateInviteStatus:"api/UserProductInvite/update-status",
    
  },
  UserProductRequest: {
    GetAll: "api/UserProductRequest",
    Accept:"api/UserProductRequest/Accept",
    Reject:"api/UserProductRequest/Reject",
    AcceptMultipleRequests:"api/UserProductRequest/Accept-multiple",
    RejectMultipleRequests:"api/UserProductRequest/Reject-multiple",
    
  },

    //will be Removed -- using in app
    CreateTraining: "Event/CreateTraining",

    // not in use
    GetTrainingWithLessonsByKey: "Event/GetTrainingWithLessonsByKey",
    GetTrainingWithLessonByKey: "Event/GetTrainingWithLessonByKey",
    UpdateTrainingLesson: "Event/UpdateTrainingLesson",
    UpdateTrainingWithAllLessons: "Event/UpdateTrainingWithAllLessons",
    UpdateTraining: "Event/UpdateTraining",

    // Trainings EndPoints
    // eventGetEvents: "Event/GetEvents",
    GetTrainerPrivateTrainings: "Event/GetTrainerPrivateTrainings",
    GetAllTrainingsByTrainerWithLessons:
        "Event/GetAllTrainingsByTrainerWithLessons",
    GetAllTrainingsByTrainerWithLesson:
        "Event/GetAllTrainingsByTrainerWithLesson",

    CreateEvent: "Event/CreateEvent",
    GetEventByKey: "Event/GetEventByKey",
    UpdateEvent: "Event/UpdateEvent",
    Trainer: {
        GetTrainerStudentsAndGroupsAsOptions: "Trainer/GetTrainerStudentsAndGroupsAsOptions",
        GetUnpaidOrders: "Trainer/UnPaidOrders",
    
    },
    //----------School----------//
    //  School: "School",
    School: {
        GetSchoolStudentsAndGroupsAsOptions: "School/GetSchoolStudentsAndGroupsAsOptions",
        GetUnpaidOrders: "School/UnPaidOrders"
    },

    // get counts trainer
    GetTrainingEventCount: "Trainer/GetTrainerTrainingsAndEventsCount",
    // get counts school
    GetSchoolTrainingEventCount: "School/GetSchoolTrainingsAndEventsCount",

    UserPublicEvent: {
        CreateEvent: "UserPublicEvent/CreateEvent",
        // By userKey
        GetPublicEventsByKeyForMonths: "UserPublicEvent/GetPublicEventsByKeyForMonths",
    },

    
    // TrainerEvent
    CreateTrainerEvent: "TrainerEvent/CreateEvent",
    GetTrainerEvents: "TrainerEvent/GetTrainerEvents",
    GetTrainerEventsByMonths: "TrainerEvent/GetTrainerEventsByMonths",
    GetTrainerEventsByKey: "TrainerEvent/GetTrainerEventsByKey",
    UpdateTrainerEvent: "TrainerEvent/UpdateTrainerEvent",
    DeleteTrainerEvent: "TrainerEvent/Delete",
    GetTrainerPreviousEventsByMonth: "TrainerEvent/GetPreviousEventsByMonth",
    GetTrainerEventsByMonth: "TrainerEvent/GetTrainerEventsByMonths",

    // get dashboard state
    GetDashboardState: "Trainer/GetDashboardStats",
    GetSchoolDashboardState: "School/GetDashboardStats",

    GetStudentDashboardState: "Student/GetDashboardStats",

    // // TrainerTraining
    // GetTrainerTrainingsByKey: "TrainerTraining/GetTrainerTrainingsByKey",
    // GetAllTrainerTrainingsLessonsByTrainerKey: "TrainerTraining/GetAllTrainerTrainingsLessonsByTrainerKey",

    // Trainer Profile
    GetTrainerProfileByKey: "Profile/GetTrainerProfileByKey",

    GetTrainerProfile: "Profile/GetTrainerProfile",
    getProfessioanalProfile: "Profile/GetProfessionalProfile",
    getTrainers: "Profile/GetUsersByProfession",
    GetAllTrainers: "Profile/GetAllTrainers",
    getUnApprovedTrainers: "Profile/GetUnApprovedTrainers",
    approveTrainer: "Profile/ApproveTrainer",
    getMembers: "Group/GetAllStudents",
    getTrainerStudents: "Student/GetTrainerStudents",
    getAllUsers: "Message/GetAllUsers",
    getAllRecipients: "Message/GetAllRecipients",
    getGroups: "Group/GetGroups",
    GetTrainerGroups: "Group/GetTrainerGroups",
    GetStudentGroups: "Group/GetStudentGroups",
    GetGroupMessages: "Group/GetGroupMessages",
    GetUserGroups: "Group/GetUserGroups",
    GetTrainerUsersEventsPayments: "Payment/GetUserEventsPayments",
    GetUserEventsPayments: "Payment/GetUserPayments",

    getInvites: "Student/GetInvites",
    getRequests: "Student/GetPendingRequests",
    acceptJoiningRequest: "Student/AcceptJoiningRequest",
    getStudents: "Student/GetCurrentStudents",
    deleteStudent: "Student/DeleteStudent",
    deleteRequest: "Student/DeleteRequest",
    deleteSelectedRequest: "Student/DeleteSelectedRequest",
    deleteInvitation: "Student/DeleteInvitation",
    deleteSelectedInvitation: "Student/DeleteSelectedInvitation",
    getStudentsUnpaidPayments: "Payment/GetStudentsUnpaidPayments",
    eventGetEventMember: "Event/GetEventMembers",
    groupCreate: "Group/Create",
    groupEdit: "Group/Edit",
    groupDelete: "Group/Delete",
    deleteUserGroup: "Group/DeleteUserGroup",
    homeLocations: "Home/GetLocations",
    homeDisciplines: "Home/GetDisciplines",
    homeReachOut: "Home/ReachOut",
    homeTrainers: "Home/GetTrainers",

    eventGetEvent: "Event/GetEvent",
    eventSubscribe: "Event/Subscribe",
    // StudentSessionBookingRequest: "Event/StudentSessionBookingRequest",
    eventDuePayment: "Event/GetDuePayments",
    eventSubscribeAll: "Event/SubscribeAll",
    eventGetEventByStartDate: "Event/GetEventByStartDate",
    eventGetUserEvents: "Event/GetUserEvents",
    GetProfessionalEvents: "Event/GetProfessionalEvents",
    GetProfessionalActivitiesByKey: "Event/GetProfessionalActivitiesByKey",
    eventGetTrainerOpenEvents: "Event/GetTrainerOpenEvents",
    getAllUserEventsInvites: "Event/GetAllUserEventsInvites",
    getAcceptedUserEventsInvites: "Event/GetAcceptedUserEventsInvites",
    AcceptEventInvite: "Event/AcceptEventInvite",
    RejectEventInvite: "Event/RejectEventInvite",
    AcceptAllEventInvites: "Event/AcceptAllEventInvites",
    RejectAllEventInvites: "Event/RejectAllEventInvites",
    GetUserAcceptedEvents: "Event/GetUserAcceptedEvents",
    PaidSuccessFully: "Event/PaidSuccessFully",

    AcceptStudentEventInvite: "Event/AcceptStudentEventInvite",
    RejectStudentEventInvite: "Event/RejectStudentEventInvite",
    AcceptAllStudentEventInvites: "Event/AcceptAllStudentEventInvites",
    RejectAllStudentEventInvites: "Event/RejectAllStudentEventInvites",

    // Student
    getPendingUserEventsInvites: "Event/GetPendingUserEventsInvites",
    // GetAllPendingStudentEventInvites: "Event/GetAllPendingStudentEventInvites",

    // StudentTrainingInvite
    StudentTrainingInvite: {
        GetAllPending: "StudentTrainingInvite/GetAllPending",
        GetAllPendingInvites: "StudentTrainingInvite/GetAllPendingInvites",
        GetAllAcceptedRequests: "StudentTrainingInvite/GetAllAcceptedRequests",
        AcceptEventInvite: "StudentTrainingInvite/AcceptEventInvite",
        AcceptEventInvitesList: "StudentTrainingInvite/AcceptEventInvitesList",
        RejectEventInvite: "StudentTrainingInvite/RejectEventInvite",
        RejectEventInvitesList: "StudentTrainingInvite/RejectEventInvitesList",
    },

    //StudentTraining
    StudentJoinTraining: "StudentTraining/StudentJoinTraining",
    StudentSessionBookingRequest: "StudentTraining/StudentSessionBookingRequest",
    GetAllStudentTrainings: "StudentTraining/GetAllStudentTrainings",
    StudentCancelJoinedTraining: "StudentTraining/StudentCancelJoinedTraining",
    StudentAllJoinedLessons: "StudentTraining/GetAllJoinedLessons",

    StudentTraining:{
        PayWithCredit: "StudentTraining/PayWithCredit",
        GetAllTrainingLessonsByConnections:"StudentTraining/GetAllTrainingLessonsByConnections"

    },
    
    //StudentEvent
    StudentJoinEventClassesWithCheckout:
        "StudentEvent/StudentJoinEventClassesWithCheckout",
    GuestStudentJoinEventClasses: "StudentEvent/GuestStudentJoinEventClasses",

    // StudentJoinEventClasses: "StudentEvent/StudentJoinEventClasses",
    GetStudentJoinedClasses: "StudentEvent/GetStudentJoinedClasses",
    GetStudentJoinedClassesByEventId:
        "StudentEvent/GetStudentJoinedClassesByEventId",
    GetAllJoinedEventClassesByEventKey:
        "StudentEvent/GetAllJoinedEventClassesByEventKey",

    StudentJoinEvent: "StudentEvent/StudentJoinEvent",
    GuestStudentJoinEvent: "StudentEvent/GuestStudentJoinEvent",
    StudentEventCreateCheckoutSession: "StudentEvent/CreateCheckoutSession",





    //Trainer
    GetAllPendingTrainerEventInvites: "Event/GetAllPendingTrainerEventInvites",
    GetAllEventJoinRequestsByStudents:
        "TrainerEventInvite/GetAllEventJoinRequestsByStudents",

    // TrainerStudents
    TrainerStudents:{
        GetTrainerStudentsGroupDto:"TrainerStudents/GetTrainerStudentsGroupDto",
        CurrentStudents: "TrainerStudents/GetCurrentStudents",
        SendInvite: "TrainerStudents/SendInvite",

    },
    
    
    GetTrainerStudents: "TrainerStudents/GetTrainerStudents",
    GetTrainerStudentsForGroups: "TrainerStudents/GetTrainerStudentsForGroups",
    GetStudentTrainers: "TrainerStudents/GetStudentTrainers",
    SubscribeTrainer: "TrainerStudents/SubscribeTrainer",
    GetAllPendingTrainerInvites: "TrainerStudents/GetAllPendingTrainerInvites",
    AcceptTrainerInvite: "TrainerStudents/AcceptTrainerInvite",
    RejectTrainerInvite: "TrainerStudents/RejectTrainerInvite",
    // Horse
    CreateHorse: "Horse/Create",
    EditHorse: "Horse/Edit",
    DeleteHorse: "Horse/Delete",
    DeleteMultiHorse: "Horse/DeleteMultiple",
    GetAllHorses: "Horse/GetAllHorses",
    GetHorseGroups: "Horse/GetHorseGroups",
    horse: {
        task: {
            add: "/horse/addhorseTask",
            update: "/horse/updatehorseTask",
            delete: "/horse/deletehorseTask",
        },
        training: {
            add: "/horse/addhorseTraining",
            update: "/horse/updatehorseTraining",
            delete: "/horse/deletehorseTraining",
        },
        getAllHorsesWithGroupsOnly: "/horse/getAllHorsesWithGroupsOnly",
        getAllHorsesIncluded: "/horse/getAllHorsesIncluded",
        getAllHorsesEventsIncluded: "/horse/getAllHorsesEventsIncluded",
        getAllHorseActivityStatsDto: "/horse/getAllHorseActivityStatsDto"
    },
    // StudentParent
    CreateStudentParent: "StudentParent/Create",
    GetStudentParents: "StudentParent/GetStudentParents",
    DeleteParent: "StudentParent/Delete",

    // Payment
    CreateStripeCheckoutSession: "Payment/CreateStripeCheckoutSession",

    // Product
    UserProduct: "UserProduct",
    GetUserProductByUserKey: "UserProduct/GetByUserKey",
    Product: "Product",
    GetPublicProductsByConnections: "Product/PublicProductsByConnections",


    // Student Products
    StudentProduct:{
        GetPublicProductsByConnections:"StudentProduct/GetPublicProductsByConnections",
        RequestPublicProduct:"StudentProduct/RequestPublicProduct"
    }, 

    // DeleteProduct: "Product/Delete",
    // GetAllHorses: "Horse/GetAllHorses",

    // ProductOrder
    ProductOrder: "ProductOrder",
    ProductOrderCreateForStudentAndGroups: "ProductOrder/CreateForStudentAndGroups",
    ProductOrderStudentInvites: "ProductOrder/StudentInvites",
    ProductOrderAccept: "ProductOrder/Accept/",
    ProductOrderAcceptAndPay: "ProductOrder/AcceptAndPay/",
    ProductOrderAcceptAll: "ProductOrder/AcceptAll",
    ProductOrderAcceptAndPayAll: "ProductOrder/AcceptAndPayAll",
    ProductOrderReject: "ProductOrder/Reject/",
    ProductOrderRejectAll: "ProductOrder/RejectAll",
    ProductOrderBuySchoolPublicProduct: "ProductOrder/BuySchoolPublicProduct",
    ProductOrderBuySchoolPublicProductWithCheckout: "ProductOrder/BuySchoolPublicProductWithCheckout",




    // StripeCard
    StripeCard: "StripeCard",
    StripeCardCreate: "StripeCard/SaveCard",
    //----------Student----------//

    allProfessionals: "Student/GetAllProfessionals",

    GetAllStudentSchoolTrainings:
        "StudentSchoolTraining/GetAllStudentSchoolTrainings",
    GetAllSchools: "Profile/GetAllSchools",

    // School Training
    SchoolTraining: {
        GetSchoolTrainingsOfMonths: "SchoolTraining/GetSchoolTrainingsOfMonths",
        GetAllSchoolProductTrainings: "SchoolTraining/GetAllSchoolProductTrainings",
        GetTrainerSchoolTrainingsOfMonths: "SchoolTraining/GetTrainerSchoolTrainingsOfMonths",
        GetSchoolTrainingsBySchoolKeyOfMonths: "SchoolTraining/GetSchoolTrainingsBySchoolKeyOfMonths",
        GetAllSchoolsTrainingsLessonsForStudents: "SchoolTraining/GetAllSchoolsTrainingsLessonsForStudents",
        Delete: "SchoolTraining/Delete",
        GetSchoolPreviousTrainingsOfMonths: "SchoolTraining/GetSchoolPreviousTrainingsOfMonths",
        SettleAttendeeOrder: "SchoolTraining/SettleAttendeeOrder",
        AcceptAttendeeOrderRequest: "SchoolTraining/AcceptAttendeeOrderRequest"

    },

    SchoolTrainingsOfMonths: "SchoolTraining/GetSchoolTrainingsOfMonths",
    CreateSchoolTraining: "SchoolTraining/CreateSchoolTraining",
    GetSchoolTrainingsByKey: "SchoolTraining/GetSchoolTrainingsByKey",
    GetAllSchoolTrainingsLessonsBySchoolKey:
        "SchoolTraining/GetAllSchoolTrainingsLessonsBySchoolKey",
    GetAllSchoolProductTrainings: "SchoolTraining/GetAllSchoolProductTrainings",
    GetAllSchoolTrainings: "SchoolTraining/GetAllSchoolTrainings",

    GetAllSchoolTrainingsWithLessons:
        "SchoolTraining/GetAllSchoolTrainingsWithLessons",
    GetAllTrainerSchoolTrainingsWithLessons:
        "SchoolTraining/GetAllTrainerSchoolTrainingsWithLessons",
    UpdateSchoolTrainingWithAllLessons:
        "SchoolTraining/UpdateSchoolTrainingWithAllLessons",
    // DeleteSchoolTraining: "SchoolTraining/Delete",

    // SchoolStudents

    SchoolStudents:{
        GetSchoolStudentsGroupDto:"SchoolStudents/GetSchoolStudentsGroupDto"
    },
    SchoolStudentInvite: "SchoolStudents/SendInvite",
    SchoolGetCurrentStudents: "SchoolStudents/GetCurrentStudents",
    GetSchoolStudents: "SchoolStudents/GetSchoolStudents",
    GetSchoolStudentsAndGroups: "SchoolStudents/GetSchoolStudentsAndGroups",
    SchoolGetSentInvites: "SchoolStudents/GetSentInvites",
    SchoolStudentDeleteSelectedInvitation:
        "SchoolStudents/DeleteSelectedInvitation",
    SchoolStudentDeleteInvitation: "SchoolStudents/DeleteInvitation",
    SchoolDeleteStudent: "SchoolStudents/DeleteStudent",

    SchoolStudentDeleteSelectedRequest: "SchoolStudents/DeleteSelectedRequest",

    //SchoolTrainer


    SchoolTrainerInvite: "SchoolTrainers/SendInvite",
    SchoolGetCurrentTrainers: "SchoolTrainers/GetCurrentTrainers",
    SchoolGetTrainers: "SchoolTrainers/GetSchoolTrainers",
    SchoolGetSentTrainerInvites: "SchoolTrainers/GetSentInvites",
    SchoolTrainerDeleteSelectedInvitation:
        "SchoolTrainers/DeleteSelectedInvitation",
    SchoolTrainerDeleteInvitation: "SchoolTrainers/DeleteInvitation",
    SchoolDeleteTrainer: "SchoolTrainers/DeleteTrainer",

    SchoolTrainerDeleteSelectedRequest: "SchoolTrainers/DeleteSelectedRequest",

    // StudentSchool
    StudentSchoolGetAllPendingInvites: "StudentSchool/GetAllPendingInvites",
    StudentSchoolAcceptInvite: "StudentSchool/AcceptSchoolInvite",
    StudentSchoolRejectInvite: "StudentSchool/RejectSchoolInvite",
    GetStudentSchools: "StudentSchool/GetStudentSchools",
    StudentSubscribeSchool: "StudentSchool/SubscribeSchool",

    // TrainerSchool
    TrainerSchoolGetAllPendingInvites: "TrainerSchool/GetAllPendingInvites",
    TrainerSchoolAcceptInvite: "TrainerSchool/AcceptSchoolInvite",
    TrainerSchoolRejectInvite: "TrainerSchool/RejectSchoolInvite",
    GetTrainerSchools: "TrainerSchool/GetTrainerSchools",
    GetTrainerSchoolsStudents: "TrainerSchool/GetTrainerSchoolsStudents",
    GetTrainerSchoolTrainers: "TrainerSchool/GetTrainerSchoolTrainers",
    TrainerSubscribeSchool: "TrainerSchool/SubscribeSchool",



    UserTrainingRequest: {
        GetAll: "api/UserTrainingRequest",
        Accept:"api/UserTrainingRequest/Accept",
        Reject:"api/UserTrainingRequest/Reject",
        AcceptMultipleRequests:"api/UserTrainingRequest/Accept-multiple",
        RejectMultipleRequests:"api/UserTrainingRequest/Reject-multiple",
        AcceptWaiting:"api/UserTrainingRequest/AcceptWaiting",
        
    },

    // TrainingRequest
    TrainingRequest: {
        GetAllTrainingLessonsJoinRequestsByStudents:
        "TrainingRequest/GetAllTrainingLessonsJoinRequestsByStudents",
        GetAllTrainingLessonsJoinRequestsByStudentsB:
        "TrainingRequest/GetAllTrainingLessonsJoinRequestsByStudentsB",
        GetAllTrainingJoinRequestsByStudents:
            "TrainingRequest/GetAllTrainingJoinRequestsByStudents",
        AcceptStudentTrainingRequest:
            "TrainingRequest/AcceptStudentTrainingRequest",
        AcceptStudentTrainingMultipleRequests:
            "TrainingRequest/AcceptStudentTrainingMultipleRequests",
        RejectStudentTrainingRequest:
            "TrainingRequest/RejectStudentTrainingRequest",
        RejectStudentTrainingMultipleRequests:
            "TrainingRequest/RejectStudentTrainingMultipleRequests",
    },

    // Financials
    Financial: {
        GetOwnerFinancials: "Financial/GetOwnerFinancials",
        GetOwnerEventFinancials: "Financial/GetOwnerEventFinancials",
        GetOwnerTrainingFinancials: "Financial/GetOwnerTrainingFinancials",
        GetOwnerProductFinancials: "Financial/GetOwnerProductFinancials",
        GetOwnerCourseFinancials: "Financial/GetOwnerCourseFinancials",
        GetStudentCourseFinancials: "Financial/GetStudentCourseFinancials",
        GetStudentActivityFinancials: "Financial/GetStudentActivityFinancials",
        GetStudentProductFinancials: "Financial/GetStudentProductFinancials",
        GetStudentEventFinancials: "Financial/GetStudentEventFinancials"
    },

    // Stripe
    StripeConnectAccount: {
        Create: "StripeConnectAccount/create",
        Get: "StripeConnectAccount"
    },

    StudentTrainingStripeCheckout: {
        Create: "StudentTrainingStripeCheckout/create",
        CreateForMultiple: "StudentTrainingStripeCheckout/create-for-multiple",

    },

    // professionalUsers
    ProfessionalUser: "ProfessionalUser",

    ProductPayment: {
        GetProductOrderCheckoutSession: "ProductPayment/GetProductOrderCheckoutSession",
        GetProductOrderCheckoutSessionForMultiple: "ProductPayment/GetProductOrderCheckoutSessionForMultiple",

    },

    UnApprovedProfessional: {
        GetAll: "UnApprovedProfessional/GetAll",
        Approve: "UnApprovedProfessional/Approve",

    },

    PublicEvent: {
        GetAllPublicEventsByMonths: "PublicEvent/GetAllPublicEventsByMonths",
    },
    IntegrationPartner: "IntegrationPartner",

    InvoiceRequest: {
        TrainerInvoice: "TrainerInvoice/",
    }
    // ProductPayment
    // ProductPaymentCheckoutSession: "ProductPayment/CheckoutSession",

};

export const GetLoginError = (errorCode: number = 200): string => {
    switch (errorCode) {
        case EqStatusCode.unAuthorized401:
             return "logInUnAuthorized401";  
        case EqStatusCode.Forbidden403:
            return "logInUserNotApproved403";
        case EqStatusCode.Status405MethodNotAllowed:
            return "logInRegisterInvited405";
        case EqStatusCode.Status406NotAcceptable:
            return "logInEmailNotConfirmed406";

        case ErrorCodes.badRequest400:
            return "Invalid Credentials, try again";
        case ErrorCodes.preConditionFailed409:
            return "User/Email already exists. Login or Register with another email";
        case ErrorCodes.userNotFound412:
            return "User not Found";
        case ErrorCodes.emailNotConfirmed417:
            return "Please check your email and confirm your account first.";
        case ErrorCodes.serverError500:
            return "regServerError500";
        case ErrorCodes.serverException502:
            return "regServerException502";
        default:
            return "";
    }
};

export const GetRegistrationMessageKey = (statusCode: number): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "regOk";
        case EqStatusCode.accepted:
            return "regAccepted";
        case EqStatusCode.badRequest400:
            return "regBadRequest400";
        case EqStatusCode.unAuthorized401:
            return "regUnAuthorized401";
        case EqStatusCode.inCompleteCredentials406:
            return "regPreConditionFailed406";
        case EqStatusCode.preConditionFailed409:
            return "regPreConditionFailed409";
        case EqStatusCode.userNotFound412:
            return "regUserNotFound412";
        case EqStatusCode.emailNotConfirmed417:
            return "regEmailNotConfirmed417";
        case EqStatusCode.serverError500:
            return "regServerError500";
        case EqStatusCode.serverException502:
            return "regServerException502";
        default:
            return "regServerError500";
    }
};


export const GetForgotPasswordMessageKey = (statusCode: number): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "regOk";
        case EqStatusCode.accepted:
            return "regAccepted";
        case EqStatusCode.badRequest400:
            return "regBadRequest400";
        case EqStatusCode.unAuthorized401:
            return "regUnAuthorized401";
        case EqStatusCode.inCompleteCredentials406:
            return "regInCompleteCredentials406";
        case EqStatusCode.preConditionFailed409:
            return "regPreConditionFailed409";
        case EqStatusCode.userNotFound412:
            return "regUserNotFound412";
        case EqStatusCode.emailNotConfirmed417:
            return "regEmailNotConfirmed417";
        case EqStatusCode.serverError500:
            return "regServerError500";
        case EqStatusCode.serverException502:
            return "regServerException502";
        default:
            return "regServerError500";
    }
};

export const GetAdminMessage = (eqStatusCode: number): string => {
    switch (eqStatusCode) {
        case EqStatusCode.ok:
            return "Trainer has been approved.";
        case EqStatusCode.badRequest400:
            return "Invalid Credentials, try again";
        case EqStatusCode.unAuthorized401:
            return "Invalid Credentials, try again";
        case EqStatusCode.inCompleteCredentials406:
            return "Invalid Credentials, Write complete username(with domain). abs@abc.abc";
        case EqStatusCode.preConditionFailed409:
            return "Could not find the user";
        case EqStatusCode.userNotFound412:
            return "User not Found";
        case EqStatusCode.emailNotConfirmed417:
            return "Please check your email and confirm your account first.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetInvitesMessage = (eqStatusCode: number): string => {
    switch (eqStatusCode) {
        case EqStatusCode.ok:
            return "Invites list recieved.";
        case EqStatusCode.badRequest400:
            return "Invalid Credentials, try again";
        case EqStatusCode.unAuthorized401:
            return "Invalid Credentials, try again";
        case EqStatusCode.inCompleteCredentials406:
            return "Invalid input information";
        case EqStatusCode.preConditionFailed409:
            return "Could not find the user";
        case EqStatusCode.userNotFound412:
            return "User not Found";
        case EqStatusCode.emailNotConfirmed417:
            return "Please check your email and confirm your account first.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};



export const GetSentInviteMessage = (statusCode: number): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Invite send successfully!";
        case EqStatusCode.badRequest400:
            return "Something bad happened, please try again...";
        case EqStatusCode.inCompleteCredentials406:
            return "Incomplete information, enter valid email and message.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again...";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again...";
        default:
            return "";
    }
};

export const GetSendMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Message sent.";
        case EqStatusCode.badRequest400:
            return "Please log in and try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.inCompleteCredentials406:
            return "write proper message";
        case EqStatusCode.preConditionFailed409:
            return "Login to send message.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Complete login process to send message.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetEventMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Please log in adn try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Already Exists try again";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetProfessionalsMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Please log in adn try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Please log in.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetHomeMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Please log in adn try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Please log in.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetGroupMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Please log in adn try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Please log in.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetAdminHomeMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Please log in adn try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Please log in.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GetBookSessionMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Please log in and try again";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Please log in.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const GenericMessage = (statusCode: number = 200): string => {
    switch (statusCode) {
        case EqStatusCode.ok:
            return "Process successful.";
        case EqStatusCode.badRequest400:
            return "Invalid Request";
        case EqStatusCode.unAuthorized401:
            return "Please log in and try again";
        case EqStatusCode.preConditionFailed409:
            return "Please log in.";
        case EqStatusCode.userNotFound412:
            return "Invalid profile. Please contact your admin.";
        case EqStatusCode.emailNotConfirmed417:
            return "Please complete login process.";
        case EqStatusCode.serverError500:
            return "Something bad happened, please try again";
        case EqStatusCode.serverException502:
            return "Something bad happened, please try again";
        default:
            return "";
    }
};

export const FrontEndPoints = {
  contact:"/contact",
  register: "/newUser",
  login: "/login",
  logout: "/logout",
  confirmEmail: "/account/confirmEmail",
  forgotPassword: "/account/forgotPassword",
  resetPassword: "/account/resetPassword",
  updatePassword: "/account/updatePassword",
  profile: "/profile",
  RidingSchools: "/RidingSchools",
  all: "/",
  guestCheckout: "/checkOut",
  allEvents: "allEvents",
  features: "/features",
  ridingSchoolFeatures: "/ridingSchool",
  eventHolderFeatures:"/eventHolder",
  trainerFeatures:"/trainer",
  horseEnthusiastFeatures:"/horseEnthusiast",
  eventSuccess:"/eventSuccess",
  publicEventSingle: "/publicEventSingle",
  allPublicEvents: "/allPublicEvents",
  allOldEvents: "/allOldEvents",
  publicEventBookingConfirm: "/publicEventBookingConfirm",
  unSubscribe: "/unSubscribe",

  admin: {
    dashboard: "/admin/home",
    approveTrainers: "/admin/approve",
    approveProfessionals: "/admin/approveProfessionals",
    userManagement: "/admin/userManagement",
    emailManagement: "/admin/emailManagement",
    logs: "/admin/logs",
    messageBoard: "/admin/messages",
      composeMessage: "/admin/messages/compose",
      subscriptionSettings: "/admin/subscriptionSettings",
      createSubscription:"/admin/createSubscription",
      subscriptionProduct:"/admin/subscriptionProduct",
      subscriptionSetting:"/admin/subscriptionSetting",
      subscription:"/admin/subscription",
      emailReport:"/admin/emailReport"
  },
  school: {
    dashboard: "/school/home",
    profileBoard: "/school/profile",
    editSchool: "/school/Edit",
    activityBoard: "/school/activityBoard",
    productBoard: "/school/productBoard",
    studentBoard: "/school/students",
    trainerBoard: "/school/trainers",
    groupsBoard: "/school/groups",
    paymentBoard: "/school/payment",
    horses: "/school/horses",
    invitations: "/school/invitations",
    requests: "/school/requests",
    messageBoard: "/school/messages",
    taskList: "/school/taskList",
    trainingCourse: "/school/trainingCourse"
  },
  trainer: {
    dashboard: "/trainer/home",
    allTrainings: "/trainer/allTrainings",
    productBoard: "/trainer/productBoard",
    activityBoard: "/trainer/activityBoard",
    allEvents: "/trainer/allEvents",
    search: "/trainer/search",
    createEvent: "/trainer/events/create",
    messageBoard: "/trainer/messages",
    eventBoard: "/trainer/events",
    trainings: "/trainer/trainings",
    studentBoard: "/trainer/students",
    editStudentBoard: "/trainer/students/edit",
    paymentBoard: "/trainer/payment",
    groupsBoard: "/trainer/groups",
    professionalsBoard: "/trainer/professionals",
    inviteStudent: "/student/invite",
    createGroup: "/trainer/groups/create",
    composeMessage: "/trainer/messages/compose",
    editTrainer: "/trainer/edit",
    profileBoard: "/trainer/profile",
    payment: "Payment/GetPayment",
    allEventsBoard: "/trainer/allEventsBoard",
    allProfessionalsBoard: "/trainer/allProfessionals",
    eventPayment: "Payment/PayNow",
    accountCreated: "/trainer/stripereturn",
    trainerEventInvitationsBoard: "/trainer/eventInvites",
    eventPrint: "/trainer/eventPrint",
    horses: "/school/horses",
    invitations: "/trainer/invitations",
      requests: "/trainer/requests",
      trainerHorses: "/trainer/horses",
      taskList: "/trainer/taskList"
  },
  eventHolder: {
    dashboard: "/eventHolder/home",
    activityBoard: "/eventHolder/activityBoard",
    editEventHolder: "/eventHolder/Edit",
    profileBoard: "/eventHolder/profile",

        // allEvents: "/eventHolder/allEvents",
        // createEvent: "/eventHolder/events/create",
        // messageBoard: "/eventHolder/messages",
        // eventBoard: "/eventHolder/events",
        // paymentBoard: "/eventHolder/payment",
        // groupsBoard: "/eventHolder/groups",
        // professionalsBoard: "/eventHolder/professionals",
        // createGroup: "/eventHolder/groups/create",
        // composeMessage: "/eventHolder/messages/compose",
        // editTrainer: "/eventHolder/edit",
        // payment: "Payment/GetPayment",
        // allEventsBoard: "/eventHolder/allEventsBoard",
    },
    student: {
        dashboard: "/student/home",
        editProfile: "/edit/profile",
        composeMessage: "/student/compose",
        trainingBoard: "/student/trainings",
        allTrainings: "/student/allTrainings",
        schoolTrainings: "/student/schoolTrainings",
        groupBoard: "/student/groups",
        messageBoard: "/student/messages",
        viewEvent: "/student/event/view",
        joinEvent: "/student/event/join",
        studentBoard: "/student/invites",
        paymentBoard: "/student/payment",
        profileBoard: "/student/profile",
        eventInvitationsBoard: "/student/eventInvites",
        trainerInvitationsBoard: "/student/trainerInvites",
        allProfessionalsBoard: "/student/allProfessionals",
        checkout: "/student/checkout",
        horses: "/student/horses",
        connection: "/student/subscribedProfessionals",
        schedule: "/student/Calendar",
        productBoard: "/student/products",
        publicEvents: "/student/publicEvents",       
        taskList: "/student/taskList",
    trainingCourse: "/student/trainingCourse"
    }
};

export const FrontendRoles = {
    Admin: "EqAdministrator",
    Trainer: "EqTrainer",
    User: "EqUser",
    Parent: "EqParent",
    School: "EqRidingSchool",
    StudentCanBook: "EqStudentCanBook",
    StudentCanPay: "EqStudentCanPay",
    EventHolder: "EqEventHolder",
};

export const FrontendPermissions = {
    underAge: "EqUnderAge",
    canBook: "EqCanBook",
    canPay: "EqCanPay",
    validPersonal: "EqValidPersonal",
};

export const ValidateIsAdmin = (param: LocalData): boolean => {
    return param && param.isSignedin && param.roles.includes(FrontendRoles.Admin);
};

export const ValidateIsTrainer = (param: LocalData): boolean => {
    return (
        param && param.isSignedin && param.roles.includes(FrontendRoles.Trainer)
    );
};
export const ValidateIsEventHolder = (param: LocalData): boolean => {
    return (
        param && param.isSignedin && param.roles.includes(FrontendRoles.EventHolder)
    );
};
export const ValidateIsSchool = (param: LocalData): boolean => {
    return (
        param && param.isSignedin && param.roles.includes(FrontendRoles.School)
    );
};
export const ValidateIsStudent = (param: LocalData): boolean => {
    return param && param.isSignedin && param.roles.includes(FrontendRoles.User);
};

export const ValidateIsUser = (param: LocalData): boolean => {
    return param && param.isSignedin;
};
export const ValidateIsParent = (param: LocalData): boolean => {
    return (
        param && param.isSignedin && param.roles.includes(FrontendRoles.Parent)
    );
};

export const getEmailFromToken = (): string => {
    let localData = loadState() as LocalData;
    if (localData != undefined) {
        if (localData.accessToken !== "") {
            let tokenData: any = jwt_decode(localData.accessToken);
            return tokenData.email;
        } else {
            return "";
        }
    } else {
        return "";
    }
};

export const getUserIdFromToken = (): string => {
    let localData = loadState() as LocalData;
    if (localData != undefined) {
        if (localData.accessToken !== "") {
            let tokenData: any = jwt_decode(localData.accessToken);
            return tokenData.Id;
        } else {
            return "";
        }
    } else {
        return "";
    }
};

export const getRoleFromToken = (): string => {
    let localData = loadState() as LocalData;
    if (localData != undefined) {
        let tokenData: any = jwt_decode(localData.accessToken);
        return tokenData.role;
    } else {
        return "";
    }
};

export const IsStudentUnderAge = (param: LocalData): boolean => {
    return (
        param &&
        param.isSignedin &&
        param.roles.includes(FrontendRoles.User) &&
        param.permissions.includes(FrontendPermissions.underAge)
    );
};

// export const ValidateStudentCanBook = (param: LocalData): boolean => {

//   return param && param.isSignedin && param.roles.includes(FrontendRoles.User) && param.permissions.includes(FrontendPermissions.canBook);
// };

// export const ValidateStudentCanPay = (param: LocalData): boolean => {

//   return param && param.isSignedin && param.roles.includes(FrontendRoles.User) && param.permissions.includes(FrontendPermissions.canPay);
// };

export const ValidateStudentCanBook = (param: LocalData): boolean => {
    return (
        param &&
        param.isSignedin &&
        param.roles.includes(FrontendRoles.User) &&
        param.roles.includes(FrontendRoles.StudentCanBook)
    );
};

export const ValidateStudentCanPay = (param: LocalData): boolean => {
    return (
        param &&
        param.isSignedin &&
        param.roles.includes(FrontendRoles.User) &&
        param.roles.includes(FrontendRoles.StudentCanPay)
    );
};

export const openNotificationWithIcon = (
    msg: any,
    desc: any,
    type = eqNotificationTypes.error
) => {
    if (type == eqNotificationTypes.success) {
        notification["success"]({
            message: msg,
            description: desc,
        });
    } else if (type == eqNotificationTypes.info) {
        notification["info"]({
            message: msg,
            description: desc,
        });
    } else {
        notification["error"]({
            message: msg,
            description: desc,
        });
    }
};



export const MapPath = (): string => {
    console.log("in map path", window.location.pathname);
    var pathName = window.location.pathname;
    switch (pathName) {
        case FrontEndPoints.register:
            return FrontEndPoints.register + window.location.search;
        case FrontEndPoints.login:
            return FrontEndPoints.login;
        case FrontEndPoints.profile:
            return FrontEndPoints.profile;
        case FrontEndPoints.confirmEmail:
            return FrontEndPoints.confirmEmail + window.location.search;
        case FrontEndPoints.forgotPassword:
            return FrontEndPoints.forgotPassword;
        case FrontEndPoints.resetPassword:
            return FrontEndPoints.resetPassword + window.location.search;
        case FrontEndPoints.updatePassword:
            return FrontEndPoints.updatePassword + window.location.search;
        default:
            console.log("in default");
            return pathName + window.location.search;
    }
};

export enum EqMessageAction {
    reply = 1,
    flag = 2,
    delete = 3,
}

export enum EqMessageType {
    Message = 1,
    Invitation = 2,
    Booking = 3,
    Payment = 4,
    Notification = 5,
    GroupMessage = 6,
}

export const addDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const addDaysUTC = (date: Date, days: number) => {
    var result = new Date(date.getUTCDate());
    result.setDate(result.getDate() + days);
    return result;
};

export const subtractDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
};

export const subtractDaysUTC = (date: Date, days: number) => {
    var result = new Date(date.getUTCDate());
    result.setDate(result.getDate() - days);
    return result;
};

export const checkDuePayment = (
    openNotificationWithIcon: any
): Promise<boolean> => {
    var promise: Promise<boolean> = new Promise((resolve, reject) => {
        resolve(false);
        // Api.get(ApiEndpoints.eventDuePayment).then(
        //   (response) => {
        //     console.log(response);
        //     if (response && response.status == 200) {
        //       var event: IEqEventData = response.data;
        //       if (event != null || undefined) {
        //         var msg = "Overdue payment for event " + event.title;
        //         // openNotificationWithIcon("Due Payment", msg, event);
        //         // resolve(true);
        //         resolve(false);
        //       }
        //       resolve(false);
        //     } else {
        //       resolve(false);
        //     }
        //   },
        //   (error) => {
        //     reject(false);
        //   }
        // );
    });
    return promise;
};

// Date Time

// start date of month number
export const DateWithZeroTime = (date: Date) => {
    var formattedDate = date;
    formattedDate.setUTCHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    return formattedDate;
    // var milliseconds = formattedDate.getTime();
    // return milliseconds;
};

export const GetStartDateForCalendar = (
    startDate: number,
    startTime: number
) => {
    var d1 = moment(startDate).toDate();
    var d2 = moment(startTime).toDate();
    var startDateTime = new Date(
        d1.getFullYear(),
        d1.getMonth(),
        d1.getDate(),
        d2.getHours(),
        d2.getMinutes(),
        0
    );
    return startDateTime;
};

export const GetEndDateForCalendar = (endDate: Date, endTime: Date) => {
    var endDateTime = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endTime.getHours(),
        endTime.getMinutes(),
        0
    );
    return endDateTime;
};

// no need to remove offset
export const covertStringDateToMilliSeconds = (stringDate: any) => {
    var date = new Date(stringDate);
    const dateMillisecond = date.getTime();
    return dateMillisecond;
};

export const covertMillisecondToStringDate = (milliseconds: number) => {
    const utcDate = moment(milliseconds).format(dateFormat);
    return utcDate;
};

export const ticksToDate = (ticks: number) => {
    // Convert .NET ticks to milliseconds
    const milliseconds = (ticks / 10000) - 62135596800000;
    // Create a new Date object from milliseconds
    return new Date(milliseconds);
};


export const ticksToMilliSeconds = (ticks: number) => {
    // Convert .NET ticks to milliseconds
    const milliseconds = (ticks / 10000) - 62135596800000;
    // Create a new Date object from milliseconds
    return milliseconds;
};

// pdfGenerate/Common.js

export const blackColor = "#000000";
export const lightBlackColor = "#333333";
// Add more color values as needed

// export const getMillisecondFromTicks = (ticks: number) => {
//   const TicksPerMillisecond=1000;
//   const epochOffset=621355968000000000;
//   var milliseconds = (ticks - epochOffset) / TicksPerMillisecond;
//   return milliseconds;
// };
