import React, { useState } from "react";
import "./EqLogin.scss";
import Api from "../shared/Api";
import { saveState } from "../shared/LocalStorage";
import {
    ApiEndpoints,
    FrontEndPoints,
    GetLoginError,
    FrontendRoles,
    getRoleFromToken,
} from "../shared/Common";
import { loginUser, logout } from "../context/Actions";
import { useAuthDispatch } from "../context/Context";
import { useNavigate, Navigate, useLocation, Link } from "react-router-dom";
import {
    Form,
    Typography,
    Checkbox,
    Modal,
    Divider,
    Row,
} from "antd";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
    UnlockFilled,
} from "@ant-design/icons";
import { EqInput, EqInputPassword } from "../custom/eqInput/EqInput";
import { EqSpace } from "../custom/eqSpace/EqSpace";
import EqButton, { IEqButton } from "../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";


const EqLogin = (props: any) => {
    const { t, i18n } = useTranslation();
    const [rememberMe, setRememberMe] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [trainerKey, setTrainerKey] = useState();
    const [schoolKey, setSchoolKey] = useState();
    const [joinTrainingKey, setJoinTrainingKey] = useState();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValidUser, setIsValidUser] = useState(false);
    const [isResendEmail, setIsResendEmail] = useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    // confirmEmail
    const [isError, setIsError] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const dispatch = useAuthDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { Text, Title } = Typography;
    // const { state } = useLocation();

    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };




    React.useEffect(() => {

        confirmEmail();

        
        if (location.state) {
            // if from Trainer
            if (location.state.trainerKey) {
                setTrainerKey(location.state.trainerKey);
            }
            // from school
            if (location.state.schoolKey) {
                setSchoolKey(location.state.schoolKey);
            }
            if (location.state.joinTrainingKey) {
                setJoinTrainingKey(location.state.joinTrainingKey);
            }

            if (location.state.prevPath) {
                if (location.state.prevPath === FrontEndPoints.register) {
                    setSuccessModalVisible(true);
                }
            }
        }

        // console.log(location);
        // console.log(state);

        logout(dispatch);
    }, []);

    const confirmEmail = (): void => {
        setIsError("");
    
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams != null || undefined) {
        var trainerKey = searchParams.get("trainerKey")|| searchParams.get("ByTrainer");
        var schoolKey = searchParams.get("schoolKey")|| searchParams.get("BySchool");
    
        if (trainerKey) {
            setTrainerKey(trainerKey as any);
        }
        // from school
        if (schoolKey) {
            setSchoolKey(schoolKey as any);
        }

        let email = searchParams.get("email");
        let emailToken = searchParams.get("emailToken");
        if (email && emailToken) {
          Api.post("account/ValidateEmail", {
            email: email,
            EmailToken: emailToken,
          }).then(
            (response) => {
              setIsError("");
              setIsSuccess(true);
            },
            (err) => {
              setIsError("emailConfirmFail");
              setIsSuccess(false);
            }
          );
        }

        }
      };

    const NavigateToRegister = (e: any) => {
        if (trainerKey) {
            navigate(
                FrontEndPoints.register + `?trainerKey=${trainerKey}&profession=3`
            );
        }
        else if (schoolKey) {
            navigate(
                FrontEndPoints.register + `?schoolKey=${schoolKey}&profession=3`
            );
        }
        else {
            navigate(FrontEndPoints.register);
        }
    };

    const validateAndLoginUser = async () => {
        setLoginError("");

        try {
            const response = await Api.post(ApiEndpoints.UserAccount.Login, {
                Email: email,
                Password: password,
                rememberMe: rememberMe,
            });

            if (response && response.status === 200) {
                
                saveState(response.data);
                loginUser(dispatch, response.data);
                setLoginError("");
                setIsValidUser(true);

                if (rememberMe) {
                    localStorage.setItem("rememberMe", "true");
                }

                const currentUserRoles = getRoleFromToken();

                if (currentUserRoles.includes(FrontendRoles.Trainer)) {
                    if (!response.data.isSubscribed) {
                        navigate(FrontEndPoints.trainer.editTrainer);
                    } else {
                        navigate(FrontEndPoints.trainer.dashboard);
                    }
                } else if (currentUserRoles.includes(FrontendRoles.EventHolder)) {

                    if (!response.data.isSubscribed) {
                        navigate(FrontEndPoints.eventHolder.editEventHolder);
                    } else {
                        navigate(FrontEndPoints.eventHolder.dashboard);
                    }


                } else if (currentUserRoles.includes(FrontendRoles.User)) {
                    // Rest of the conditions...

                    // don't redirect to subscription for now 

                    // if(!response.data.isSubscribed){
                    //   navigate(FrontEndPoints.student.editProfile);
                    // }else 

                    if (joinTrainingKey) {
                        // navigate(FrontEndPoints.trainer.allProfessionalsBoard);
                        navigate(
                            `${FrontEndPoints.trainer.profileBoard}?key=${trainerKey}`,
                            {
                                state: {
                                    prevPath: location.pathname,
                                    joinTrainingKey: joinTrainingKey,
                                },
                            }
                        );
                    } else if (trainerKey) {
                        // navigate(FrontEndPoints.trainer.allProfessionalsBoard);
                        navigate(
                            `${FrontEndPoints.trainer.profileBoard}?key=${trainerKey}`,
                            { state: { prevPath: location.pathname } }
                        );
                    }

                    else if (schoolKey) {
                        // navigate(FrontEndPoints.trainer.allProfessionalsBoard);
                        navigate(
                            `${FrontEndPoints.school.profileBoard}?key=${schoolKey}`,
                            { state: { prevPath: location.pathname } }
                        );
                    }
                    else if (location?.state?.prevPath) {
                        navigate(
                            location.state.prevPath
                            // { state: { prevPath: location.pathname } }
                        );
                    // } else if (response.data.isPaymentDue) {
                    //     navigate(
                    //         FrontEndPoints.student.allTrainings,
                    //         { state: { prevPath: location.pathname } }
                    //     );
                    } else {
                        navigate(FrontEndPoints.student.dashboard);
                    }


                } else if (currentUserRoles.includes(FrontendRoles.Admin)) {
                    navigate(FrontEndPoints.admin.dashboard);
                } else if (currentUserRoles.includes(FrontendRoles.School)) {
                    navigate(FrontEndPoints.school.dashboard);
                } else {
                    navigate(FrontEndPoints.profile);
                }
            } else {
                setLoginError(GetLoginError(response.status));
                setIsValidUser(false);
                document.getElementById("merrorMsg")?.classList.remove("hide");
                document.getElementById("merrorMsg")?.classList.add("show", "login-error");
            }
        } catch (err: any) {
            // Handle errors
         if (err?.response?.status == 406) {
                setIsResendEmail(true);
                setLoginError(
                    "logInEmailNotConfirmed406"
                );
            } else {
                setLoginError(GetLoginError(err.response.status));
            }

            setIsValidUser(false);
            document.getElementById("merrorMsg")?.classList.remove("hide");
            document
                .getElementById("merrorMsg")
                ?.classList.add("show", "login-error");
        }
    };




    const buttonProps: IEqButton = {
        //changeFunc: validateUser,
        classNames: "eq-sign-btn",
    };

    // useEffect(() => {

    // }, []);

    const onChangeCheckBox = (e: any) => {
        //
        setRememberMe(e.target.checked);
        console.log(`checked = ${e.target.checked}`);
    };
    const onFinishFailed = (errorInfo: any) => { };

    const resendEmailFunc = (): void => {
        Api.post("account/resendEmail", { Email: email, Password: password }).then(
            (response) => {
                setLoginError("emailConfirmSent");
                document.getElementById("merrorMsg")?.classList.remove("hide");
                document
                    .getElementById("merrorMsg")
                    ?.classList.add("show", "resend-email");
                setTimeout(() => {
                    setIsResendEmail(false);
                }, 500);
            },
            (err) => {
                setLoginError("Email could not be sent, please contact administrator.");
                setIsValidUser(false);
                document.getElementById("merrorMsg")?.classList.remove("hide");
                document
                    .getElementById("merrorMsg")
                    ?.classList.add("show", "login-error");
            }
        );
    };

    if (isValidUser == true) {
        return <Navigate to={FrontEndPoints.profile} />;
    }

    return (
        <div className="eq-login">
       
            <div className="eq-form-wrapper">
                {/* <Image className="eq-logo" src="logo.png" /> */}
                <div className="confirm-message">

{isSuccess && (
    <Text className="success" style={{ color: "green" }}>
            {t("emailConfirmSuccess")}
        </Text>
    )}
    {isError && (
    <Text className="fail">
           {t(isError)}
        </Text>
    )}
    </div>
                <Title className=" ant-col ant-col-20 ant-col-offset-2 custom-title">
                    {t("logIn")}
                </Title>
                <Form
                    {...tailLayout}
                    layout="vertical"
                    size="small"
                    // onFinish={validateUser}
                    onFinish={validateAndLoginUser}
                    onFinishFailed={onFinishFailed}
                >
                    <p className="label">{t("email")}</p>
                    <div className="eq-form-item-wrapper">
                        <EqInput
                            type="email"
                            placeholder={t("emailAddress")}
                            prefix={<UserOutlined />}
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                        />
                    </div>
                    <p className="label">{t("password")}</p>
                    <div className="eq-form-item-wrapper">
                        <EqInputPassword
                            placeholder={t("inputPassword")}
                            iconRender={(visible: any) =>
                                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                            prefix={<UnlockFilled />}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="eq-form-item-wrapper">
                        <Text id="merrorMsg" className="hide">
                            {t(loginError)}
                        </Text>
                        {isResendEmail && (
                            <>
                                <EqButton
                                    type="primary"
                                    htmlType="button"
                                    onClick={resendEmailFunc}
                                    centered="centered"
                                    {...buttonProps}
                                >
                                    {t("resendEmail")}
                                </EqButton>
                            </>
                        )}
                    </div>

                    <div className="eq-form-item-wrapper">
                        <div className="remember-text">

                            <Checkbox className="check-box" onChange={onChangeCheckBox}>
                                {t("rememberMe")}
                            </Checkbox>
                            {/* <a className="eq-anchor" href={FrontEndPoints.forgotPassword}>
                                <u>{t("forgotPassword")}</u>
                            </a> */}

                            <Link
                                    color="primary"
                                    type="link"
                                    to=""
                                    className="eq-anchor"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        navigate(
                                            FrontEndPoints.forgotPassword
                                        );
                                    }}
                                >
                                    {t("forgotPassword")}
                                </Link>

                        </div>
                    </div>
                    <div className="eq-form-item-wrapper">
                        <EqSpace direction="vertical" style={{ padding: "1% 8%" }}>

                        </EqSpace>
                    </div>
                    <div className="eq-form-item-wrapper">
                        <EqButton
                            type="primary"
                            htmlType="submit"
                            centered="centered"
                            {...buttonProps}
                        >
                            {t("logIn")}
                        </EqButton>
                    </div>
                  
                    <Divider plain>{t("or")}</Divider>

                    <div className="eq-form-item-wrapper">
                        <EqSpace
                            direction="vertical"
                            style={{
                                width: "100%",
                                padding: "1% 8%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div className="eq-anchor-register">
                                {t("doYouNeedToRegister")}{(" ")}
                                <span
                                    onClick={NavigateToRegister}
                                // href={FrontEndPoints.register}
                                className="eq-register"
                                >
                                    {t("signUpHere")}
                                </span>
                            </div >
                        </EqSpace>
                    </div>
                </Form>
            </div>
        </div>
    );
};
export default EqLogin
