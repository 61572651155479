import React, { useState, useEffect } from "react";
import "./EqSchoolTrainingCard.scss";
import { Row, Col, Tabs, Space, Modal, Switch, Tooltip } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Draggable } from "@hello-pangea/dnd";
import { ISelectProps } from "../../../shared/Interfaces";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import EqTimePicker, {
  ITimePickerOptions,
} from "../../../custom/eqTimePicker/EqTimePicker";

import {
  EqDisciplineType,
  EqEventAttendeeStatus,
  EqSlotStatusEnum,
  EqTrainingLevels,
} from "../../../shared/enums";
import { EqInput, EqInputNumber } from "../../../custom/eqInput/EqInput";
import EqCollapse, {
  IEqCollapse,
} from "./../../../custom/eqCollapse/EqCollapse";

import {
  SyncOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  getDefaultImage,
  imageType,
} from "../../../shared/Common"; 
import { IEqSchoolTrainingCardProps } from "./Dto/IEqSchoolTrainingCardProps";
import EqButton from "../../../custom/eqButton/EqButton";
import { Button } from "antd-v5";

const EqSchoolTrainingCard = ({
  isEdit,
  data,
  productOptions,
  selectedProduct,
  timeFromChange,
  timeToChange,
  durationChange,
  maxSpaceCountChange,
  attendeeCardPriceChange,
  attendeesChange,
  attendeeCardHorseChange,
  trainingLevelChange,
  // trainingProductChange,
  removeCard,
  removeAttendeeFromCard,
  groupKeys,
  multipleStartOn,
  startDate,
  trainingType,
  enableCardRemove,
  selectedGroups,
  studentsList,
  groupStudentsList,
  groupStudentPopulation,
  studentsByGroup,
  studentPopulation,
  searchStudentsList,
  studentHorsesList,
  isStudent,
  isPreviousTraining,
  SettleAttendeeOrder,
  OnAcceptAttendeeRequest,
  ...rest
}: IEqSchoolTrainingCardProps) => {
  //console.log(groupKeys);
  //console.log(cardkey);
  const [modalRefundPayment, contextRefundPayment] = Modal.useModal();

  const TIME_FORMAT = "HH:mm";
  const { TabPane } = Tabs;
  const {
    cardkey,
    timeFrom,
    timeTo,
    duration,
    maxSpaceCount,
    price,
    trainingLevel,
    attendees,
    attendeesKeys,
    attendeesHorse,
  } = data;
  const [selectedAttendees, setSelectedAttendees] = useState({
    // keys: [] as any,
    keys: attendeesKeys,
    data: attendees,
  });

  const [selectedHorses, setSelectedHorses] = useState(
    attendeesHorse ? attendeesHorse : ([] as any[])
  );

  const { t, i18n } = useTranslation();
  const [eventDurations, setEventDurations] = useState([]) as any;


  const [groupSelected, setGroupSelected] = useState(false);

  const [trainingLevels, setTrainingLevels] = useState([]) as any;
  const [formData, setFormData] = useState({}) as any;
  const [check, setCheck] = useState(false);

  useEffect(() => {

    
    createEventDurations();
    createTrainingLevels();
    console.log(studentPopulation);
    if (selectedGroups && selectedGroups.length > 0) {
      setGroupSelected(true);
    } else {
      setGroupSelected(false);
    }

    if (studentsByGroup && studentsByGroup.length > 0) {
      setGroupSelected(true);
    }
  }, [
    data,
    selectedGroups,
    studentPopulation,
    searchStudentsList,
    studentsByGroup,
    isPreviousTraining,
  ]);

  const createEventDurations = () => {
    const durations = [30, 45, 60, 90].map((value) => ({ key: value, value }));
    setEventDurations(durations);
  };
  const createTrainingLevels = () => {
    var levels = [];
    for (var x in EqTrainingLevels) {
      if (isNaN(Number(x)) == false) {
        levels.push({ key: x, value: EqTrainingLevels[x] });
      }
    }
    setTrainingLevels(levels);
  };
  // const createTrainingLevels = () => {
  //   const levels = Object.entries(EqTrainingLevels).map(([key, value]) => ({ key, value }));
  //   setTrainingLevels(levels);
  //
  // };
  const updateEndTime = (
    fullYear: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ) => {
    var calculatedDate = new Date(
      fullYear,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    setFormData({ ...formData, timeTo: moment(calculatedDate.valueOf()) });
  };

  const handleOnChangeHorse = (value: any, attendeeKey: any, index: any) => {
    var selectedHorsesData = [...selectedHorses];
    console.log(value);
    console.log(attendeeKey);
    console.log(index);

    if (selectedHorses && selectedHorses.length > 0) {
      const exist = selectedHorses?.some((h: any) => h.key == attendeeKey);
      if (exist) {
        // var modifyList=[...selectedHorses]
        const objIndex = selectedHorses.findIndex(
          (obj: any) => obj.key == attendeeKey
        );
        selectedHorsesData[objIndex].horseId = value;
      } else {
        let obj = {
          key: attendeeKey,
          horseId: value,
        };
        selectedHorsesData.push(obj);
      }
    } else {
      let obj = {
        key: attendeeKey,
        horseId: value,
      };

      selectedHorsesData.push(obj);
    }
    setSelectedHorses(selectedHorsesData);
    attendeeCardHorseChange(cardkey, selectedHorsesData);
  };

  const onSearchChange = (value: any) => {
    
    const selectedMembers = [] as any;
    var selectedPopulation = selectedAttendees.keys;
    if (attendees && attendees.length < data?.maxSpaceCount) {
      const exist = selectedPopulation?.includes(value);
      if (!exist) {
        selectedPopulation.push(value.shift());
      }
      searchStudentsList.filter((item: any) => {
        if (selectedPopulation.includes(item.key) == true) {
          selectedMembers.push(item);
        }
      });

      setSelectedAttendees({
        ...selectedAttendees,
        keys: selectedPopulation,
        data: selectedMembers,
      });
      attendeesChange(cardkey, selectedMembers, selectedPopulation);
    }
  };

  const HorseOptions = (key: any): [] => {
		let value = [];

		// Check if studentHorsesList is defined
		if (studentHorsesList) {
			// Filter the studentHorsesList to find the entry with the matching key
			value = studentHorsesList.filter((x: any) => {
				if (x.key === key) {
					// Extract the horseList from the matching entry
					var result = x.horseList;
					// Return the horseList
					return [...result];
				}
			});
		}
		// If a matching entry was found and has horseList
		if (value.length > 0) {
			// Get the horseList from the first matching entry
			let horses = value[0].horseList;
			// Filter out horses that are already selected by other keys (users)
			horses = horses.filter((horse: any) => {
				return !selectedHorses.some(
					(selected) =>
						selected.horseId === horse.key && selected.key !== key
				);
			});
			// Return the filtered horse list

			return horses;
		} else {
			return value;
		}
  };


  const HorseSelectedOptions = (key: any) => {
    let value = [];

    if (selectedHorses && selectedHorses.length > 0) {
      value = selectedHorses.filter((x: any) => {
        if (x.key === key) {
          var result = x.horseId;
          return result;
        }
      });
    }

    if (value.length > 0) {
      return value[0].horseId;
    } else {
      return value;
    }
  };
  const getStatusStyle = (value: any) => {
    switch (value) {
      case "Pending":
        return { background: "#dce0e6" }; // Adjust color
      case "Requested":
        return { background: "#FF8838" }; // Adjust color
      case "Paid":
        return { background: "#dbffe4" }; // Adjust color
      case "paymentSettled":
        return { background: "#dbffe4" }; // Adjust color
      case "Accepted":
        return { background: "#dbffe4" }; // Adjust color
      // default:
      //   return { background: "#FF8838" }; // Adjust color
    }
  };
  const attendeeStatus = (item: any) => {
    debugger
    if (item.userStatus === EqEventAttendeeStatus.Pending) {
      return "Pending";
    }
     else if (item.userStatus === EqEventAttendeeStatus.Requested) {
      return "Requested";
    } 
    else if (item.userStatus === EqEventAttendeeStatus.Accept) {
      if (item.slotStatus === EqSlotStatusEnum.Booked) {
        return "Paid";
      } else if (item.slotStatus === EqSlotStatusEnum.Settled) {
        return "paymentSettled";
      } else {
        return "Accepted";
      }
    } else {
      return "";
    }
  };
  const handleOnSettleAttendee = (item: any) => {
    // check if its edit
    if (isEdit) {
      if (SettleAttendeeOrder) SettleAttendeeOrder(item);
    }
  };

  const handleOnAcceptAttendeeRequest = (item: any) => {
    // check if its edit
    if (isEdit) {
      if (OnAcceptAttendeeRequest) OnAcceptAttendeeRequest(item);
    }
  };


  const spaceChangeFunc = (value: any) => {
    let spaces: number = +value;
    setFormData({ ...formData, maxSpaceCount: spaces });
    maxSpaceCountChange(cardkey, spaces);
  };
  const handleOnChangePrice = (e: any) => {
    let priceValue: number = +e?.target?.value;
    setFormData({ ...formData, price: priceValue });
    attendeeCardPriceChange(cardkey, priceValue);
  };

  const durationSelectProps: ISelectProps = {
    isTag: false,
    classNames: `eq-addSelect extra-height ${isStudent ? "eq-card" : ""}`,
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: eventDurations,
    defaultSelectedValue: duration,
    changeFunc: (value: string) => {
      let minutes: number = +value;
      console.log(minutes);
      setFormData({ ...formData, duration: minutes });
      durationChange(cardkey, minutes);
    },
  };


  const searchStudentProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: true,
    isDisabled: isStudent
      ? true
      : trainingType == EqDisciplineType.Open
      ? true
      : false,
    changeFunc: onSearchChange,
    classNames: `eq-multiSelect eq-searchSelect extra-height `,
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: groupSelected ? studentsByGroup : studentPopulation.allStudents,
    isSeperator: groupSelected ? true : false,
    seperatorList: groupSelected ?
     trainingType == EqDisciplineType.RidingCourse? 
     isEdit ? studentPopulation.filteredStudents:[]:
     studentPopulation.filteredStudents : [],
    defaultSelectedValue: [],
  };

  // trainingType == EqDisciplineType.Open && isEdit

  const horseSelectProps: ISelectProps = {
    isTag: false,
    placeholder: t("selectHorse"),
    classNames: `eq-addSelect extra-height ${isStudent ? "eq-card" : ""}`,
  };
  const levelSelectProps: ISelectProps = {
    isTag: false,
    classNames: `eq-addSelect extra-height ${isStudent ? "eq-card" : ""}`,
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: trainingLevels,
    defaultSelectedValue: data?.trainingLevel ? data.trainingLevel + "" : "",
    changeFunc: (value: string) => {
      let levels: number = +value;
      setFormData({ ...formData, trainingLevel: levels });
      trainingLevelChange(cardkey, levels);
    },
  };

  const timeFromProps: ITimePickerOptions = {
    isSuffixIcon: false,
    isBordered: true,
    classNames: isStudent ? "eq-card" : "eq-event",
    defaultValue: timeFrom,
    minuteInterval: 15,
    placeHolder: t("selectDuration"),
    onChangeFunc: (time: any) => {
      
      setFormData({ ...formData, timeFrom: time });
      timeFromChange(cardkey, time);
    },
  };

  const timeToProps: ITimePickerOptions = {
    isSuffixIcon: false,
    isBordered: true,
    classNames: "eq-event",
    minuteInterval: 15,
    defaultValue: timeTo.valueOf(),
    placeHolder: t("selectDuration"),
    onChangeFunc: (time: any) => {
      setFormData({ ...formData, timeTo: moment(time) });
      timeToChange(cardkey, time);

      var selectedtime = new Date(time);
      var selectedDate = formData?.startOn
        ? new Date(formData?.startOn)
        : new Date(time);

      if (selectedtime != undefined && selectedDate != undefined) {
        updateEndTime(
          selectedDate.getFullYear(),
          selectedDate.getMonth() + 1,
          selectedDate.getDate(),
          selectedtime.getHours(),
          selectedtime.getMinutes(),
          0,
          0
        );
      }
    },
  };

  const removeAttendeeCard = () => {
    removeCard(cardkey);
  };

  const collapsableProps: IEqCollapse = {
    display: data.display,
    classNames:
      maxSpaceCount && attendees && attendees.length == maxSpaceCount
        ? "eq-green-header"
        : "",
    header: [
      <div className="at-card-header">
        <Row className="at-header-row" align="middle">
          <Col className="content-col">
            <Row>
              <Col className="eq-sel-date">
                <div className="header-label">
                  {trainingType != EqDisciplineType.RidingCourse && startDate && moment(startDate).format("dddd DD.MMMM YYYY, ")}
                  {moment(timeFrom).format(TIME_FORMAT)}
                </div>
              </Col>
              <Col>
                {multipleStartOn && multipleStartOn?.length > 1 ? (
                  <SyncOutlined />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <div className="header-label">
                  {EqTrainingLevels[trainingLevel]}
                </div>
              </Col>

              {maxSpaceCount && (
                <Col xs={2} sm={2} md={1}>
                  <div className="header-label">
                    {attendees ? attendees.length : 0}/{maxSpaceCount}
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          <Col className="action-col">
            <Row>
              <Col>
                {enableCardRemove && attendees.length == 0 ? (
                  <div className="trash-bin">
                    <span>
                      <img
                        alt=""
                        src="/trash-alt-red.svg"
                        onClick={removeAttendeeCard}
                      />
                    </span>
                  </div>
                ) : null}
              </Col>
              <Col>
                <MoreOutlined />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>,
    ],
  };
  function onChange() {
    setCheck(!check);
  }

  const handleOnclickRemoveAttendee = (cardkey: number, attendee: any) => {
    // check if its edit
    if (isEdit) {
      //check if attendee is Paid then show Refund popup
      if (attendee.slotStatus === EqSlotStatusEnum.Booked) {
        confirmPaymentRefund(cardkey, attendee.key);
      } else {
        removeAttendeeFromCard(cardkey, attendee.key, false);
      }
    } else {
      removeAttendeeFromCard(cardkey, attendee.key, false);
    }
  };

  const confirmPaymentRefund = (cardkey: number, attendeeKey: any) => {
    modalRefundPayment.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Would you like to Refund Attendee",
      okText: "Refund",
      cancelText: "Pay Later",
      onOk: () => removeAttendeeFromCard(cardkey, attendeeKey, true),
      onCancel: () => removeAttendeeFromCard(cardkey, attendeeKey, false),
    });
  };

  // }, [selectedGroups,studentPopulation,studentPopulation,searchStudentsList]);
  return (
    <>
      <div className="eq-create-attendee-card">
        <EqCollapse {...collapsableProps}>
          <div className="card-body">
            <Row>
              <Col span={24}>
                <Tabs
                  defaultActiveKey={
                    isEdit ? "1" : data?.price != "" ? "1" : "2"
                  }
                >
                  <TabPane tab={t("students")} key="1">
                    <div className="tab-container">
                      <Row>
                        <Col span={24}>
                          {!isStudent && (
                            <EqSelect {...searchStudentProps}></EqSelect>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {attendees && attendees.length > 0 ? (
                            <div>
                              <Row className="eq-std-row">
                                {/* <Col xs={2} sm={2} md={1} className="eq-std-th">
                                  <Checkbox
                                    onChange={onChange}
                                    checked={check}
                                  ></Checkbox>
                                </Col> */}
                                <Col xs={6} sm={7} md={6} className="eq-std-th">
                                  Name
                                </Col>
                                <Col xs={7} sm={7} md={7} className="eq-std-th">
                                  Horse
                                </Col>
                                <Col xs={7} sm={7} md={7} className="eq-std-th">
                                  Contact Info
                                </Col>
                                {!isStudent && (
                                  <Col
                                    xs={3}
                                    sm={3}
                                    md={2}
                                    className="eq-std-th"
                                  >
                                    Status
                                  </Col>
                                )}
                              </Row>
                              <>
                                {attendees?.map((item: any, index: number) => {
                                  return (
                                    <Draggable
                                      isDragDisabled={isStudent}
                                      key={item?.key}
                                      draggableId={item?.key}
                                      index={index}
                                    >
                                      {(provided: any) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Row className="eq-std-row">
                                            {/* <Col xs={2} sm={2} md={1}>
                                              <Checkbox></Checkbox>
                                            </Col> */}
                                            <Col xs={6} sm={7} md={6}>
                                              <Row>
                                                <Space>
                                                  <Col
                                                    flex="auto"
                                                    className="eq-std-td"
                                                    // xs={5}
                                                    // sm={4}
                                                    // md={3}
                                                  >
                                                    <span className="eq-up-img">
                                                      <img
                                                        src={
                                                          item?.user?.userImage
                                                            ?.url ||
                                                          getDefaultImage(
                                                            imageType.partner
                                                          )
                                                        }
                                                        className="user"
                                                        alt="user"
                                                      />
                                                    </span>
                                                  </Col>
                                                  <Col
                                                    className="eq-std-td"
                                                    // xs={19}
                                                    // sm={20}
                                                    // md={21}
                                                  >
                                                    <div className="eq-up-name">
                                                      {item?.user?.firstName +
                                                        " " +
                                                        item?.user?.lastName ||
                                                        item?.user?.userName +
                                                          " " +
                                                          item?.user?.lastName}
                                                    </div>
                                                    <div>
                                                      {item?.group?.name}
                                                    </div>
                                                  </Col>
                                                </Space>
                                              </Row>
                                            </Col>
                                            <Col
                                              xs={7}
                                              sm={7}
                                              md={7}
                                              className="eq-std-td"
                                            >
                                              <EqSelect
                                                {...horseSelectProps}
                                                isDisabled={isStudent}
                                                // defaultSelectedValue={HorseSelectedOptions()}
                                                changeFunc={(e: any) => {
                                                  handleOnChangeHorse(
                                                    e,
                                                    item.key,
                                                    index
                                                  );
                                                }}
                                                options={HorseOptions(item.key)}
                                                defaultSelectedValue={
                                                  !isStudent
                                                    ? HorseSelectedOptions(
                                                        item.key
                                                      )
                                                    : item.horse
                                                    ? item.horse.name
                                                    : ""
                                                }
                                              ></EqSelect>
                                            </Col>
                                            <Col
                                              xs={7}
                                              sm={7}
                                              md={7}
                                              className="eq-std-td"
                                            >
                                              <div>{item?.user?.email}</div>
                                              <div>
                                                {item?.group?.phoneNumber}
                                              </div>
                                            </Col>
                                            {!isStudent && (
                                              <Col xs={2} sm={2} md={3}>
                                                {isPreviousTraining ? (
                                                  <>
                                                    {attendeeStatus(item) ===
                                                      "Paid" ||
                                                    attendeeStatus(item) ===
                                                      "paymentSettled" ||
                                                    attendeeStatus(item) ===
                                                      "" ? (
                                                      <div
                                                        className="attendeeStatus"
                                                        style={getStatusStyle(
                                                          attendeeStatus(item)
                                                        )}
                                                      >
                                                        {t(
                                                          attendeeStatus(item)
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <Row gutter={[1, 4]}>
                                                        <Col span={24}>
                                                          <div
                                                            className="attendeeStatus"
                                                            style={getStatusStyle(
                                                              attendeeStatus(
                                                                item
                                                              )
                                                            )}
                                                          >
                                                            {t(
                                                              attendeeStatus(
                                                                item
                                                              )
                                                            )}
                                                          </div>
                                                        </Col>
                                                        <Col span={24}>
                                                          <Tooltip
                                                            placement="bottomRight"
                                                            title={t(
                                                              "settlePaymentInfo"
                                                            )}
                                                          >
                                                            <Switch
                                                              className="switch-input"
                                                              checkedChildren={t(
                                                                "paymentSettled"
                                                              )}
                                                              unCheckedChildren={t(
                                                                "settlePayment"
                                                              )}
                                                              // onChange={(checked: any) => handleOnSettleAttendee(item)}
                                                              onClick={() =>
                                                                handleOnSettleAttendee(
                                                                  item
                                                                )
                                                              }
                                                            />
                                                          </Tooltip>
                                                        </Col>
                                                      </Row>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                  {attendeeStatus(item) !==
                                                  "" ? (
                                                    <div
                                                      className="attendeeStatus"
                                                      style={getStatusStyle(
                                                        attendeeStatus(item)
                                                      )}
                                                    >
                                                      {t(
                                                        attendeeStatus(item)
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                                )}
                                              </Col>
                                            )}
                                            <Col xs={2} sm={2} md={1}>
                                              {!isStudent && (
                                                <div className="action-col">
                                                  <Row className="actions-btn">
                                                  {!isPreviousTraining && attendeeStatus(item) ==="Requested"&&
                                                  <Col className="eq-event-actions-trash">
<Tooltip
placement="bottomRight"
title={t("accept")}
// arrow={mergedArrow}
>

<Button
              onClick={() =>
                handleOnAcceptAttendeeRequest(
                  item
                )}
              className="invite-accept-btn"
              size="small"
              shape="circle"
              icon={<CheckOutlined />}
            />
  </Tooltip>
                                                 
                                                    </Col>
                                                  }

                                                    <Col className="eq-event-actions-trash">
                                                      <span>
                                                        <img
                                                          alt=""
                                                          // src="/trash-alt.svg"
                                                          src="/trash-alt-red.svg"
                                                          onClick={() =>
                                                            handleOnclickRemoveAttendee(
                                                              cardkey,
                                                              item
                                                            )
                                                          }
                                                        />
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </>
                            </div>
                          ) : isStudent ? (
                            <div className="eq-std-row">{t("noStudents")}</div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>

                  </TabPane>
                  <TabPane
                    tab={isStudent ? t("information") : t("settings")}
                    key="2"
                  >
                    <Row>
                      <Col flex="auto">
                        <div className="label-h1">{t("duration")}</div>
                      </Col>
                    </Row>
                    <Row className="eq-input-group">
                      <Col xs={24} sm={5} md={5}>
                        <Row className="label-h2">{t("from")}</Row>
                        <Row>
                          <EqTimePicker
                            isDisabled={isStudent}
                            {...timeFromProps}
                          ></EqTimePicker>
                        </Row>
                      </Col>
                      {trainingType != EqDisciplineType.Open ? (
                        <Col xs={24} sm={5} md={5}>
                          <Row className="label-h2">{t("duration")}</Row>
                          <Row>
                            <EqSelect
                              isDisabled={isStudent}
                              {...durationSelectProps}
                            />
                          </Row>
                        </Col>
                      ) : (
                        <Col xs={24} sm={5} md={5}>
                          <>
                            <Row className="label-h2">{t("to")}</Row>
                            <Row>
                              <EqTimePicker
                                isDisabled={isStudent}
                                {...timeToProps}
                              ></EqTimePicker>
                            </Row>
                          </>
                        </Col>
                      )}

                      {trainingType == EqDisciplineType.RidingCourse ? (
                        <>
                          <Col xs={24} sm={5} md={5}>
                            <Row className="label-h2">{t("capacity")}</Row>
                            <Row className="name-number-input">
                              <EqInputNumber
                                disabled={true}
                                classNames={`${
                                  isStudent ? "eq-card-input" : ""
                                }`}
                                // defaultValue={4}
                                defaultValue={
                                  selectedProduct?.limit
                                    ? selectedProduct.limit
                                    : 1
                                }
                                value={selectedProduct?.limit}
                                onChange={spaceChangeFunc}
                              />
                            </Row>
                          </Col>
                          <Col xs={24} sm={5} md={5}>
                            <Row className="label-h2">{t("price")}</Row>
                            <Row className="name-number-input">
                              <EqInput
                                className="name-input"
                                disabled={true}
                                defaultValue={
                                  selectedProduct?.individualPrice
                                    ? selectedProduct?.individualPrice
                                    : null
                                }
                                value={selectedProduct?.individualPrice}
                                onChange={handleOnChangePrice}
                              />
                            </Row>
                          </Col>
                        </>
                      ) : (
                        <>
                          {trainingType == EqDisciplineType.Jumping && (
                            <Col xs={24} sm={5} md={5}>
                              <Row className="label-h2">{t("level")}</Row>
                              <Row>
                                <EqSelect
                                  isDisabled={isStudent}
                                  {...levelSelectProps}
                                />
                              </Row>
                            </Col>
                          )}

                          {trainingType != EqDisciplineType.Open && (
                            <Col xs={24} sm={5} md={5}>
                              <Row className="label-h2">{t("capacity")}</Row>
                              <Row className="name-number-input">
                                <EqInputNumber
                                  disabled={isStudent}
                                  classNames={`${
                                    isStudent ? "eq-card-input" : ""
                                  }`}
                                  // defaultValue={4}
                                  defaultValue={
                                    data?.maxSpaceCount ? data.maxSpaceCount : 1
                                  }
                                  // defaultValue={
                                  //   data?.maxSpaceCount!== null ? data.maxSpaceCount : trainingType == EqDisciplineType.Jumping ? 4:1
                                  // }
                                  min={1}
                                  max={Number.MAX_SAFE_INTEGER}
                                  // max={
                                  //   trainingType != EqDisciplineType.Dressage
                                  //     ? Number.MAX_SAFE_INTEGER
                                  //     : 1
                                  // }
                                  value={formData.maxSpaceCount}
                                  onChange={spaceChangeFunc}
                                />
                              </Row>
                            </Col>
                          )}
                          <Col xs={24} sm={4} md={4}>
                            <Row className="label-h2">{t("price")}</Row>
                            <Row className="name-number-input">
                              {/* <EqSelect isDisabled={isStudent} {...levelSelectProps} /> */}
                              {!isStudent ? (
                                <EqInput
                                  className="name-input"
                                  defaultValue={data?.price ? data.price : null}
                                  value={formData.price}
                                  onChange={handleOnChangePrice}
                                />
                              ) : (
                                <EqInputNumber
                                  classNames={`${
                                    isStudent ? "eq-card-input" : ""
                                  }`}
                                  disabled={isStudent}
                                  defaultValue={data?.price ? data.price : null}
                                  value={formData.price}
                                  onChange={handleOnChangePrice}
                                />
                              )}
                            </Row>
                          </Col>
                        </>
                      )}
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </div>
        </EqCollapse>
        {/* modal Context */}
        {contextRefundPayment}
      </div>
    </>
  );
};
export default EqSchoolTrainingCard;
