import React, { useState, useEffect } from "react";
import { Table, Button, Input, Typography, message, Form } from "antd";
import axios from "axios";
import "./EmailManagement.scss"; // Import your SCSS file

const { Title } = Typography;

interface EmailStatus {
  id: string;
  email: string;
  status: string;
  subject: string;
  timestamp: string;
  date: string;
  messageId: string;
}

const EmailManagement: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [emails, setEmails] = useState<EmailStatus[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<EmailStatus[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFailedEmails();
  }, []);

  const fetchFailedEmails = async () => {
    setLoading(true);
    try {
      const response = await axios.get<EmailStatus[]>("/api/SendGrid/GetUndeliveredEmails");
      setEmails(response.data);
      setFilteredEmails(response.data);
    } catch (error) {
      message.error("Failed to fetch emails");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value) {
      const filtered = emails.filter((email) =>
        email.email.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmails(filtered);
    } else {
      setFilteredEmails(emails);
    }
  };

  const resendEmail = async (emailData: EmailStatus) => {
    try {
      await axios.post("/api/SendGrid/ResendEmail", { messageId: emailData.messageId, newEmail: email });
      message.success(`Email sent to ${email}`);
    } catch (error) {
      message.error("Failed to send email");
    }
  };

  return (
    <div className="email-management">
      <div className="header">
        <Title level={3}>Resend Emails</Title>
        <div className="filters">
          <div className="input-group">
            <Form.Item label="Send Email To">
              <Input
                placeholder="Enter email to resend"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="input-group">
            <Form.Item label="Search Emails">
              <Input
                placeholder="Search email list"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <Table
        dataSource={filteredEmails}
        rowKey="id"
        loading={loading}
        columns={[
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
          },
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (date: string | undefined) => {
              return date ? new Date(date).toLocaleDateString() : "-";
            },
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Button type="link" className="resend-button" onClick={() => resendEmail(record)}>
                Resend
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default EmailManagement;
