import React from "react";
import { Modal, Input, Row } from "antd-v5";
import { useTranslation } from "react-i18next";
import EqButton from "../../../components/custom/eqButton/EqButton";
import "./RefundConfirmModal.scss";
type RefundConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  refundAmount: string;
  setRefundAmount: (value: string) => void;
  onRefund: () => void;
  onPayLater?: () => void;
};

const RefundConfirmModal: React.FC<RefundConfirmModalProps> = ({
  isOpen,
  onClose,
  refundAmount,
  setRefundAmount,
  onRefund,
  onPayLater,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("confirm")}
      className="refund-confirm-modal"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      closable={true}
    >
      <div>
        <p>{t("refundAttendee")}</p>
        <p>{t("refundAttendeeNote")}</p>
        <Input
          type="number"
          placeholder={t("amount")}
          min={0}
          value={refundAmount}
          onChange={(e) => setRefundAmount((e.target.value))}
          style={{ marginBottom: "10px" }}
        />
      </div>

      <Row style={{ padding: "2px" }} justify="end">
        <EqButton style={{ marginRight: "2px" }} bordered onClick={onRefund}>
          {t("refund")}
        </EqButton>
       { onPayLater && <EqButton onClick={onPayLater}>{t("payLater")}</EqButton>}
      </Row>
    </Modal>
  );
};

export default RefundConfirmModal;
