import * as React from "react";
import "./EqPublicEventBoard.scss";
import { Row, Col, Skeleton } from "antd";
import EqButton from "../../../components/custom/eqButton/EqButton";
import {
    ApiEndpoints,
    GetEventMessage,
    ValidateIsTrainer,
    ValidateIsSchool,
    openNotificationWithIcon,
} from "../../../components/shared/Common";
import Api from "../../../components/shared/Api";
import { useTranslation } from "react-i18next";
import { IEqEventData } from "../../../components/shared/interfaces/IEqEventDto";
import { useAuthState } from "../../../components/context/Context";
import { Drawer, Spin } from "antd-v5";
import { eqNotificationTypes } from "../../../components/shared/enums";
import EqPublicEventCard from "../../eqPublicEventCard/EqPublicEventCard";
import { useNavigate } from "react-router-dom";
import EqCreateOrEditEvent from "../eqCreateorEditEvent/EqCreateOrEditEvent";
export interface IEqPublicEventBoard {
    updateCount?: (count: number) => void;
    activeEvent?: any;
}
const EqPublicEventBoard = ({ updateCount, activeEvent }: IEqPublicEventBoard) => {
    const userDetails: any = useAuthState();
    const isTrainer: boolean = ValidateIsTrainer(userDetails);
    const isSchool: boolean = ValidateIsSchool(userDetails);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [creatorUser, setCreatorUser] = React.useState([]) as any;
    const [stripeAccountInfo, setStripeAccountInfo] = React.useState() as any;
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);

    const [message, setMessage] = React.useState("");
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [areas, setAreas] = React.useState([]) as any;


    const [imagesData, setImagesData] = React.useState([]) as any;

    const [CreatePublicEventShow, setCreatePublicEventShow] = React.useState(false);

    const [eventsList, setEventsList] = React.useState(null) as any;
    // const [eventsList, setEventsList] = React.useState([] as Array<IEqEventData>);
    const [editEvent, setEditEvent] = React.useState<any | null>(null); // State to track selected event ID for editing
    const [showEditDrawer, setShowEditDrawer] = React.useState(false); // State to manage drawer visibility


    React.useEffect(() => {
        
        getImages();
        getAreas();
        GetUserPublicEvents();
        GetStripeAccount();

        // window.scrollTo(0, 0);
    }, []);

    const GetStripeAccount = () => {
        Api.get(ApiEndpoints.StripeConnectAccount.Get).then(
            (response) => {
                if (response.status === 200) {
                    setStripeAccountInfo(response.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const GetUserPublicEvents = async () => {
        let path = ApiEndpoints.EqOwnerPublicEvent.GetAll;
        Api.get(path).then(
            (response) => {
                if (response && response.status === 200) {
                    var data = response.data ? [...response.data] : new Array<any>();

                    // Sort events: First, those with isDraft = true, then by createdDate
                    const sortedEvents = data.sort((a, b) => {
                        // Check if either event has isDraft as true
                        if (a.isDraft && !b.isDraft) return -1; // a should come first
                        if (!a.isDraft && b.isDraft) return 1; // b should come first

                        // If neither or both have isDraft as true, sort by createdDate

                        return b.createdDate - a.createdDate; // Sort by descending order (most recent first)
                    });

                    setEventsList(sortedEvents);
                    console.log("EventListObject", data);
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };

    const handleCreateClick = () => {
        setCreatePublicEventShow(true);

        // Check if there's an existing draft event
        // const draftEvent = eventsList?.find((event: any) => event.isDraft);

        // if (draftEvent) {
        //   // Open the drawer with the draft event for editing
        //   setEditEvent(draftEvent); // Save the event to state for editing
        //   setShowEditDrawer(true);  // Open the drawer
        // } else {
        //   // No draft exists, proceed to create a new event
        //   setCreatePublicEventShow(true);
        // }
    };

    const GetUserProfile = () => {
        Api.get(ApiEndpoints.GetTrainerProfile).then(
            (response) => {
                if (response && response.status == 200) {
                    setIsError(false);
                    setMessage("");
                    setCreatorUser(response.data);
                } else {
                    setIsError(true);
                    setMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
            }
        );
    };

    const removeItem = (eventId: any) => {
        if (eventsList !== undefined) {
            var updatedList =
                eventsList !== undefined
                    ? eventsList.filter((item: any) => item.id !== eventId)
                    : new Array<any>();
            setEventsList(updatedList);
        }
    };
    const handleOnCreateEvent = async (data: any) => {
        try {
            setSpinning(true);
            // Call the API to create the event
            const response = await Api.post(ApiEndpoints.EqOwnerPublicEvent.Create, data);

            if (response && response.status === 200) {
                const newEvent = response.data;

                // Clone the events list
                const events = [...eventsList];

                // If the new event is a draft, replace any existing draft in the list
                if (newEvent.isDraft) {
                    // Display notification based on creation
                    openNotificationWithIcon(
                        t("saved"),
                        t("draftSaved"),
                        eqNotificationTypes.success
                    );

                    const draftIndex = events.findIndex((event) => event.isDraft);

                    if (draftIndex !== -1) {
                        // Replace existing draft
                        events[draftIndex] = newEvent;
                    } else {
                        // If no draft exists, just add the new event
                        events.push(newEvent);
                    }
                } else {
                    // If it's not a draft, simply add the new event to the list
                    events.push(newEvent);

                    // Display notification based on creation
                    // openNotificationWithIcon(
                    //     t("created"),
                    //     t("eventCreated"),
                    //     eqNotificationTypes.success
                    // );
                }

                // Update the event list state
                setEventsList(events);

                if (!newEvent.isDraft) {
                    // Navigate to /eventSuccess with event ID and title as query params
                    navigate(`/eventSuccess`, {
                        state: {
                            eventId: newEvent.id,
                            eventTitle: newEvent.title,
                        },
                    });
                }

                // Optionally update the event count
                if (updateCount) {
                    updateCount(events.length);
                }

                // Close the modal or form for creating a public event
                setCreatePublicEventShow(false);
            } else {
                // Show error notification if response is not successful
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            }
        } catch (error) {
            // Handle errors
            openNotificationWithIcon(t("serverError"), t("generalServerError"));
            console.error("Error creating or updating event:", error);
        } finally {
            setSpinning(false);
        }
    };

    const handleOnUpdateEvent = async (data: any) => {
        try {
            setSpinning(true);
            // Replace with your API endpoint and method
            const response = await Api.put(ApiEndpoints.EqOwnerPublicEvent.Update, data);
            if (response && response.status === 200) {
                const updatedEvent = response.data;

                // Find the event in the list
                const events = [...eventsList];
                const foundIndex = events.findIndex((x) => x.id === updatedEvent.id);

                if (foundIndex !== -1) {
                    const prevEvent = events[foundIndex];

                    // Check draft status before and after update to show appropriate notification
                    if (prevEvent.isDraft && !updatedEvent.isDraft) {
                        openNotificationWithIcon(
                            t("created"),
                            t("eventCreated"),
                            eqNotificationTypes.success
                        );
                    } else if (updatedEvent.isDraft) {
                        openNotificationWithIcon(
                            t("updated"),
                            t("draftUpdated"),
                            eqNotificationTypes.success
                        );
                    } else {
                        openNotificationWithIcon(
                            t("updated"),
                            t("eventUpdated"),
                            eqNotificationTypes.success
                        );
                    }

                    // Update the event in the list
                    UpdateEventList(updatedEvent);

                    if (prevEvent.isDraft && !updatedEvent.isDraft) {
                        // Navigate to /eventSuccess with event ID and title as query params
                        navigate(`/eventSuccess`, {
                            state: {
                                eventId: updatedEvent.id,
                                eventTitle: updatedEvent.title,
                            },
                        });
                    }
                }
            } else {
                openNotificationWithIcon(
                    t("serverError"),
                    t("generalServerError"),
                    eqNotificationTypes.error
                );
            }
        } catch (error) {
            console.error("Error creating or updating event:", error);
            openNotificationWithIcon(
                t("serverError"),
                t("generalServerError"),
                eqNotificationTypes.error
            );
        } finally {
            setSpinning(false);
        }
    };

    const UpdateEventList = (updatedEvent: any) => {
        // Only update if eventsList is defined
        if (eventsList) {
            const updatedEvents = eventsList.map((event: any) =>
                event.id === updatedEvent.id ? updatedEvent : event
            );
            setEventsList(updatedEvents);
        }

        // If editing the event, update the edit state
        if (editEvent?.id === updatedEvent.id) {
            setEditEvent(updatedEvent);
        }

        // Update count if the function is provided
        if (updateCount) {
            updateCount(eventsList.length);
        }
    };

    const DeleteEvent = (eventId: any) => {
        
        Api.delete(`${ApiEndpoints.EqOwnerPublicEvent.Delete}/${eventId}`).then(
            (response) => {
                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("deleted"),
                        t("eventDeleted"),
                        eqNotificationTypes.success
                    );
                    removeItem(eventId);
                } else {
                    setIsError(true);
                    // setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                if (error?.response?.status === 412) {
                    openNotificationWithIcon(
                        t("Conflict"),
                        t("Cannot delete the event as it has associated class orders.")
                    );
                } else {
                    openNotificationWithIcon(t("serverError"), t("generalServerError"));
                }
                setIsError(true);
                // setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };

    // Remove without refundPrice
    const RemoveAttendeeClassOrder = (orderId: any, refund: boolean) => {
        Api.delete(
            `${ApiEndpoints.EqOwnerPublicEvent.RemoveAttendeeClassOrder}/${orderId}?refund=${refund}`
        )
            .then((response) => {
                

                if (response && response.status === 200) {
                    openNotificationWithIcon(
                        t("removed"),
                        t("attendeeRemoved"),
                        eqNotificationTypes.success
                    );

                    UpdateEventList(response.data);
                } else {
                    setIsError(true);
                    // Optionally handle error messages here
                    // setErrorMessage(GetEventMessage(response.status));
                }
            })
            .catch((error) => {
                setIsError(true);
                // Optionally handle error messages here
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
            });
    };
    // Remove with refundPrice

    const RemoveAttendeeClassOrderWithAmount = (
        orderId: any,
        refund: boolean,
        refundAmount?: number
    ) => {
    setSpinning(true)
        Api.delete(
            `${ApiEndpoints.EqOwnerPublicEvent.RemoveAttendeeClassOrderWithAmount}/${orderId}?refund=${refund}&refundAmount=${refundAmount}`
        )
            .then((response) => {
                

                if (response && response.status === 200) {
                    const message = refund
                    ? t("attendeeRemovedAndRefunded")
                    : t("attendeeRemoved");

                openNotificationWithIcon(
                    t("removed"),
                    message,
                    eqNotificationTypes.success
                );


                    UpdateEventList(response.data);
                } else {
                    setIsError(true);
                    // Optionally handle error messages here
                    // setErrorMessage(GetEventMessage(response.status));
                }
    setSpinning(false)

            })
            .catch((error) => {
                setIsError(true);
                // Optionally handle error messages here
                openNotificationWithIcon(t("serverError"), t("generalServerError"));
    setSpinning(false)

            });
    };

    const getAreas = () => {
        Api.get(ApiEndpoints.Location.GetUserLocationsOptions).then(
            (response) => {
                if (response && response.status === 200) {
                    setIsError(false);
                    setMessage("");
                    setAreas(response.data);
                } else {
                    setIsError(true);
                    setMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
            }
        );
    };

    const updateImageLibrary = (newImage: any) => {
        setImagesData((prevImages: any) => [...prevImages, newImage]);
    };

    const removeFromImageLibrary = (imageId: any) => {
        setImagesData((prevImages: any) => prevImages.filter((image: any) => image.id !== imageId));
    };

    const getImages = () => {
        Api.get(ApiEndpoints.Image.GetUserImages).then(
            (response) => {
                if (response.data && response.data.length) {
                    setImagesData(response.data);
                }
            },
            () => {
                setIsError(true);
                setMessage("Error Occurred while fetching images from server");
            }
        );
    };

 

    const GetTrainerPublicEvents = async () => {
        // let path = ApiEndpoints.GetTrainerPublicEvents;

        let path = ApiEndpoints.GetTrainerEventsByMonths;
        // let path = ApiEndpoints.GetTrainerEvents;
        Api.get(path).then(
            (response) => {
                if (response && response.status == 200) {
                    var data = response.data ? [...response.data] : new Array<any>();

                    if (activeEvent) {
                        var foundIndex = data.findIndex(
                            (x) => x.referenceKey == activeEvent.referenceKey
                        );
                        if (foundIndex !== -1) {
                            data[foundIndex].collapseOpen = true;
                        }
                        setEventsList(data);
                        console.log("EventListObject", data);
                    } else {
                        setEventsList(data);
                        console.log("EventListObject", data);
                    }
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
            },
            (error) => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
            }
        );
    };
    const removeEvent = (data: IEqEventData, refund?: boolean) => {
        setLoading(true);
        // let path=ApiEndpoints.DeleteTrainerEvent + `/${data.key}`;
        let path = ApiEndpoints.DeleteTrainerEvent + `?key=${data.key}&refund=${refund}`;

        Api.delete(path).then(
            (response) => {
                if (response && response.status == 200) {
                    removeItem(response.data);
                    GetTrainerPublicEvents();
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
                setLoading(false);
            },
            (error) => {
                setIsError(true);
                setErrorMessage(GetEventMessage(error.response.status));
                setLoading(false);
            }
        );

        if (updateCount) {
            updateCount(eventsList.length); // Replace 'newCount' with the actual count
        }
    };

    // Function to handle the editing of an event
    const handleEditEvent = (eventId: string) => {
        
        const selectedEvent = eventsList.find((event: any) => event.id === eventId);
        if (selectedEvent) {
            setEditEvent(selectedEvent); // Set the selected event ID
            setShowEditDrawer(true); // Open the drawer
        } else {
            console.error("Event not found");
        }
    };

    // Function to close the edit drawer
    const handleCloseEditDrawer = () => {
        setEditEvent(null);
        setShowEditDrawer(false);
    };

    return (
        <div className={`eq-public-event-board`}>
            <div className="">
                <Spin spinning={spinning} size="large">
                    <Row align="middle" justify="end" style={{ margin: "10px 0" }}>
                        {!CreatePublicEventShow && (
                            <>
                                {isTrainer || isSchool ? (
                                    <Row align="middle" justify="end">
                                        <Col className="eq-event-buttons">
                                            <EqButton
                                                type="button"
                                                classNames="btn-background-orange"
                                                onClick={handleCreateClick}
                                            >
                                                {t("createEvent")}
                                            </EqButton>
                                        </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </Row>

                    <Row className="eq-event-heading-line"></Row>

                    <Row>
                        {[...Array(5)].map((_, index) => (
                            <Skeleton
                                key={index}
                                loading={!eventsList}
                                paragraph={{ rows: 2 }}
                                active
                            />
                        ))}
                    </Row>
                    {eventsList && eventsList.length > 0 && (
                        <Row gutter={[16, 16]} className="event-card-list">
                            {eventsList.map((eventData: any) => (
                                <Col xs={24} sm={12} xl={8} xxl={6} key={eventData.id}>
                                    <EqPublicEventCard
                                        eventData={eventData}
                                        onEdit={() => handleEditEvent(eventData.id)}
                                        onDelete={() => DeleteEvent(eventData.id)}
                                        onCardClick={() => navigate(`/publicEventSingle?id=${eventData.id}`)}

                                    />
                                </Col>
                            ))}
                        </Row>

                      // Table View of Events keep is commented
                        // <EqOwnerPublicEventList
                        // data={[...eventsList.filter((event:any) => !event.isDraft)]}
                        // onEdit={handleEditEvent}
                        // onDelete={DeleteEvent}
                        // onDeleteMultiple={function (inviteIds: string[]): void {
                        //   throw new Error("Function not implemented.");
                        // } }
                        // />
                    )}

                    {CreatePublicEventShow && (
                        <Drawer
                            closable={false}
                            width={1000}
                            open={CreatePublicEventShow}
                            children={
                                <Spin spinning={spinning} size="large">
                                <EqCreateOrEditEvent
                                    imagesData={imagesData}
                                    creatorUser={creatorUser}
                                    stripeAccountInfo={stripeAccountInfo}
                                    areas={areas}
                                    onCreateEvent={handleOnCreateEvent}
                                    onCloseEvent={() => setCreatePublicEventShow(false)}
                                    onImageUploadSuccess={updateImageLibrary}
                                    onImageDeleteSuccess={removeFromImageLibrary}
                                />
                            </Spin>
                            }
                        />
                    )}

                    {/* Edit event */}
                    <Drawer
                        closable={false}
                        width={1000}
                        open={showEditDrawer}
                        children={
                            <Spin spinning={spinning} size="large">
                            <EqCreateOrEditEvent
                                imagesData={imagesData}
                                creatorUser={creatorUser}
                                stripeAccountInfo={stripeAccountInfo}
                                areas={areas}
                                eventData={editEvent}
                                // onCreateEvent={handleOnCreateEvent}
                                onEditEvent={handleOnUpdateEvent}
                                onRemoveClassOrder={RemoveAttendeeClassOrderWithAmount}
                                // onRemoveClassOrder={RemoveAttendeeClassOrder}
                                onCloseEvent={handleCloseEditDrawer}
                                onImageUploadSuccess={updateImageLibrary}
                                onImageDeleteSuccess={removeFromImageLibrary}
                            />
                            </Spin>
                        }
                    />
                </Spin>
            </div>

            {/* <EqPublicEventSucess></EqPublicEventSucess> */}
        </div>
    );
};
export default EqPublicEventBoard;
