import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Image, Row, Upload, notification } from 'antd'
import React, { useState } from 'react'
import { EqInput } from '../eqInput/EqInput'
import EqDatePicker, { IDatePickerOptions } from '../eqDatePicker/EqDatePicker'
import EqToggle, { IEqToggle } from '../eqToggle/EqToggle'
import TextArea from 'antd/lib/input/TextArea'
import EqButton from '../eqButton/EqButton'
import moment from 'moment'
import { useTranslation, Trans } from "react-i18next";
import { eqNotificationTypes, genderType } from '../../shared/enums'
import Api from '../../shared/AApi'
import { ApiEndpoints, GetEventMessage, dateFormat } from '../../shared/Common'
import { AxiosResponse } from 'axios'
import { IEqHorseList } from '../../eqTrainer/HorsesList/HorsesList'
import "./EditorCreateHorse.scss"
import Compressor from 'compressorjs'



const EditorCreateHorse = ({
    onCancel,
    onCreate,
    isEdit,
    horse,
    onEdit,
}: any) => {
    const { t, i18n } = useTranslation();

    const [addHorse, setAddHorse] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [ImageUrl, setImageUrl] = React.useState("") as any;
    const [toggleFilters, setToggleFilters] = React.useState([] as any);
    const [selectedToggle, setSelectedToggle] = React.useState() as any;
    const [isError, setIsError] = React.useState(false);

    const horseInitial = {
        name: "",
        about: "",
        dateOfBirth: 0,
        imageUrl: "",
        type: 1,
        // userId:0
    };



    const [horseFormData, setHorseFormData] = React.useState({
        ...horseInitial,
    } as any);
    const [horsesList, setHorsesList] = React.useState([] as any);

    const handleChangeHorseUpload = (info: any) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.originFileObj) {
            getBase64Horse(info.file.originFileObj);
        } else {
            getBase64Horse(info.file);
        }
    };
    const getBase64Horse = (img: any) => {
        setLoading(true);
        new Compressor(img, {
            quality: 0.7,
            success: (compressedResult) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    var srcStr = reader.result as any;
                    var apiImgSrc = srcStr.substr(srcStr.indexOf(",") + 1);

                    var form = new FormData();
                    form.append("imageFile", img);
                    form.append("imageSrc", apiImgSrc);
                    form.append("imageName", img.name);

                    setIsError(false);

                    Api.post("image/UploadImage", form).then(
                        (response) => {
                            setImageUrl(response?.data?.url);
                            setHorseFormData({
                                ...horseFormData,
                                imageUrl: response?.data?.url,
                            });
                            setLoading(false);
                        },
                        (error) => {
                            setLoading(false);
                            setIsError(true);
                        }
                    );
                    //updateImage(dispatch, reader.result);
                });
                reader.readAsDataURL(compressedResult);
            },
        });
    };


    const handleCancelClick = () => {
        onCancel(); 
    };



    const createToggleFilters = () => {
        var filters = [];
        for (var x in genderType) {
            if (isNaN(Number(x)) == false) {
                filters.push({ key: Number(x), value: genderType[x] });
            }
        }
        setToggleFilters(filters);
        setSelectedToggle(filters[0]);
    };

    const togglerProps: IEqToggle = {
        items: toggleFilters.map((filter: any) => ({
            key: t(filter.key),
            value: t(filter.value), // Translate the filter label
          })),
        defaultSelectedValue: horseFormData.type,
        classNames: "eq-toggle-orange-border eq-toggle-create-event",
        changeFunc: (value: string) => {
            // console.log(filteredTrainingsList);
            let filter: number = +value;
            setSelectedToggle(filter);

            if (filter === genderType.Stallion) {
                setHorseFormData({ ...horseFormData, type: filter });
                // setSelectedTrainingsList(filteredTrainingsList.available);
            } else if (filter === genderType.Gelding) {
                setHorseFormData({ ...horseFormData, type: filter });

                // setSelectedTrainingsList(filteredTrainingsList.joined);
            } else if (filter === genderType.Mare) {
                setHorseFormData({ ...horseFormData, type: filter });

                // setSelectedTrainingsList(filteredTrainingsList.previous);
            }
        },
    };

    const openNotificationWithIcon = (
        msg: any,
        desc: any,
        type = eqNotificationTypes.error
    ) => {
        if (type == eqNotificationTypes.success) {
            notification["success"]({
                message: msg,
                description: desc,
            });
        } else if (type == eqNotificationTypes.info) {
            notification["info"]({
                message: msg,
                description: desc,
            });
        } else {
            notification["error"]({
                message: msg,
                description: desc,
            });
        }
    };
    const validateData = (data: any): boolean => {
        let isComplete = true;
        const msg = "Incomplete data";
        const desc = " is empty.";
        if (data.name == "") {
            openNotificationWithIcon(msg, "Name" + desc);
            isComplete = false;
        } else if (!data.dateOfBirth) {
            openNotificationWithIcon(msg, "Date Of Birth" + desc);
            isComplete = false;
        } else if (ImageUrl == "") {
            openNotificationWithIcon(msg, "Image" + desc);
            isComplete = false;
            // } else if (uiImage == null || Object.keys(uiImage).length === 0) {
            //   openNotificationWithIcon(msg, "Image" + desc);
            //   isComplete = false;
        }

        return isComplete;
    };

    


    const EditFunc = (e: any) => {
        

        let validate = validateData(horseFormData);
        
        const horseDate = {
            ...horseFormData,
            dateOfBirth: horseFormData.dateOfBirth?.valueOf(),
        };

        if (validate == true) {
            Api.put(ApiEndpoints.EditHorse, horseDate).then(
                (response) => {
                    if (response && response.status == 200) {
                        openNotificationWithIcon(
                            "Update!",
                            "Horse have been Updated",
                            eqNotificationTypes.success
                        );
                        setIsError(false);

                        const updatedHorse = response.data;
                        const updatedHorsesList = horsesList.map((item: any) => {
                            if (item.id === updatedHorse.id) {
                                return updatedHorse;
                            }
                            return item;
                        });

                        setHorsesList(updatedHorsesList); // Update the list in the component state
                        setHorseFormData({ ...horseInitial }); // Clear the form or reset as needed
                        setImageUrl(""); // Clear the image URL as needed
                        onEdit(updatedHorse); // Notify the parent component if needed
                    } else {
                        openNotificationWithIcon(
                            "Server error",
                            "Error occurred while Updating Horse. Please try again or contact administrator."
                        );
                        setIsError(true);
                    }
                },
                (error) => {
                    if (error?.response?.status === 403) {
                        openNotificationWithIcon(
                            "Server error",
                            "Not Authorized Kindly contact administrator."
                        );
                        setIsError(true);
                    } else {
                        openNotificationWithIcon(
                            "Server error",
                            "Error occurred while Updating the Horse. Please try again or contact administrator."
                        );
                        setIsError(true);
                    }
                }
            );
        }
    };



    const horseDobProps: IDatePickerOptions = {
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: horseFormData?.dateOfBirth,
        placeHolder: t("dateofBirth"),
        classNames: "eq-event",
        onChangeFunc: (date: any, dateString: string) => {
            setHorseFormData({ ...horseFormData, dateOfBirth: moment(date) });
        },
    };

    React.useEffect(() => {
        
        createToggleFilters();

        if (horse) {
            
            setHorseFormData(horse);
            setImageUrl(horse.imageUrl);
        }
        // getProductList()
    }, [horse]);

    return (
        <div className='edit-horse'>
            <div className="horse-details">
                <Row>
                    <Col span={24}>
                        <div className="horse-section">
                            <div className="label">{t("selectImage")}</div>
                            <Row>
                                <Col>
                                    {ImageUrl != "" && (
                                        <Image
                                            className="eq-image"
                                            preview={true}
                                            src={ImageUrl}
                                        />
                                    )}
                                </Col>
                                <Col>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        onChange={handleChangeHorseUpload}
                                    >
                                        <div>
                                            {loading ? (
                                                <LoadingOutlined />
                                            ) : (
                                                <PlusOutlined />
                                            )}
                                            <div style={{ marginTop: 0 }}>{t("picture")}</div>
                                        </div>
                                    </Upload>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row align="middle" className="input-row">
                    <Col md={12} xs={24} sm={24} className="col-left mb-16">
                        <EqInput
                            label={t("name")}
                            className="profile-input"
                            placeholder=""
                            value={horseFormData.name}
                            onChange={(event: any) =>
                                setHorseFormData({
                                    ...horseFormData,
                                    name: event?.target?.value,
                                })
                            }
                        ></EqInput>
                    </Col>
                    <Col md={12} xs={24} sm={24} className="mb-16"></Col>

                    <Col md={12} xs={24} sm={24} className="col-left mb-16">
                        <label>{t("dateofBirth")}</label>
                        <EqDatePicker {...horseDobProps}></EqDatePicker>

                        {/* <EqInput
                            label="Date of Birth"
                            className="profile-input"
                            type="date"
                            placeholder=""
                          ></EqInput> */}
                    </Col>

                    <Col md={12} xs={24} sm={24} className="mb-16"></Col>

                    {/*Gender Type*/}
                    <Col span={24} className="">
                        <div className="about-my-self-heading">{t("gender")}</div>
                    </Col>
                    <Col span={24}>
                        <EqToggle {...togglerProps} />
                    </Col>
                    <Col span={24} className="">
                        <div className="about-my-self-heading">{t("about")}</div>
                    </Col>
                    <Col span={24}>
                        <TextArea
                            rows={4}
                            onChange={(e: any) =>
                                setHorseFormData({
                                    ...horseFormData,
                                    about: e.target.value,
                                })
                            }
                            value={horseFormData.about}
                            className="profile-input text-area"
                        ></TextArea>
                    </Col>

                </Row>
                <div className="eq-add-horse-btn">
                    <EqButton
                        type="button"
                        onClick={EditFunc}
                        classNames="btn-background-orange"
                    >
                        {t("saveChanges")}
                    </EqButton>
                    <EqButton
                        type="button"
                        classNames="btn-background-white"
                        onClick={handleCancelClick}
                    >
                        {t("cancel")}
                    </EqButton>
                </div>
            </div>
        </div>
    )
}

export default EditorCreateHorse