import React, { useState } from "react";
import axios from "axios";
import "./UnSubscribePage.scss"; // Import the SCSS file
import Api from "../../components/shared/Api";
import EqButton from "../../components/custom/eqButton/EqButton";

const UnsubscribePage: React.FC = () => {
  const [statusMessage, setStatusMessage] = useState<string>("Click the button to unsubscribe.");
  const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");

  const handleUnsubscribe = async () => {
    if (!email) {
      setStatusMessage("Invalid email or missing email parameter.");
      return;
    }

    try {
      const response = await Api.post(
        `/api/EmailSubscription/unsubscribe?email=${email}`
      );

      if (response.status === 200) {
        setStatusMessage("You have successfully unsubscribed from our emails.");
        setIsUnsubscribed(true);
      } else {
        setStatusMessage("Failed to unsubscribe. Please try again later.");
      }
    } catch (error) {
      console.error("Error during unsubscribe:", error);
      setStatusMessage("An error occurred while unsubscribing.");
    }
  };

  return (
    <div className="eq-unsubscribe-page">
      <div className="card">
        <h1>Unsubscribe</h1>
        <p>{statusMessage}</p>
        {/* {!isUnsubscribed && (
          <button onClick={handleUnsubscribe}>Unsubscribe</button>
        )} */}
        {!isUnsubscribed && (
          <EqButton onClick={handleUnsubscribe}>Unsubscribe</EqButton>
        )}
      </div>
    </div>
  );
};

export default UnsubscribePage;
