import React, { useEffect, useState } from "react";
import "./EqProductFinancial.scss";
import { Row, Col, Tooltip } from "antd";
import { Checkbox } from "antd";
import EqCollapse, {
    IEqCollapse,
} from "../../../../custom/eqCollapse/EqCollapse";
import {
  paymentStatusType,
  EqDisciplineType,
  EqEventAccessType,
  EqPaymentStatus,
} from "../../../../shared/enums";
import moment from "moment";
import { FrontEndPoints } from "../../../../shared/Common";
import { useNavigate } from "react-router-dom";
import { InfoCircleFilled, ProfileOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CourseStatus } from "../../../../shared/interfaces/ridingCourse/IRidingCourse";
import { Modal } from "antd-v5";
import EqPdfFinancialReceipt from "../../../../../eqComponents/PdfView/eqPdfFinancialReceipt/EqPdfFinancialReceipt";

export interface IEqProductFinancial {
    id?: string;
    key?: number | string;
    name: string;
    email: string;
    title: string;
    date: number | string;
    price: number;
    vat?: number;
    status: number;
    isDisplay?: boolean;
    isNotVAT?: boolean;
    selected?: boolean;
    entityName: string;
}
const EqProductFinancial = ({id, name, email, title, date, price, vat, status, isDisplay, selected, isNotVAT }: IEqProductFinancial) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    // const [check, setCheck] = useState(selected);
    const [check, setCheck] = useState<boolean>(selected ?? false);
      const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        checkChange(selected ?? false);
    }, [selected]);
    function checkChange(selected: boolean) {
        setCheck(selected);
    }
    function onChange() {
        setCheck(!check);
    }
    const messageClicked = () => {
        var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${email}`;
        navigate(out1);
    };

    const getClass = (param: number) => {
        switch (param) {
            case 1:
                return "purple";
            case 2:
                return "red";
            case 3:
                return "green";
            case 4:
                return "orange";
            default:
                return "orange";
        }
    };

    const calculatePercentage = (price: number, vat: number) => {
        if (vat && price) {

            const vatAmount = (vat / 100) * price;
            const roundedVatAmount = Number(vatAmount.toFixed(2));
            return roundedVatAmount;
        }
        return "-";
    };
    const CollapseProps: IEqCollapse = {
        display: isDisplay,
        header: [
            <Row justify="start" align="middle">
                <Col className="" span={2}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="" flex="auto">
                    {name}
                </Col>
            </Row>,
        ],
    };
    return (
        <>
            <Row className="eq-f-row hidden-sm">
                <Col className="eq-f-td" span={1}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="eq-f-td" span={3}>
                    {name}
                </Col>
                <Col className="eq-f-td" span={3}>
                    {title}
                </Col>
                <Col className="eq-f-td" span={4}>
                    {moment(date).format("DD MMMM, YYYY HH:mm")}
                </Col>

                <Col className="eq-f-td" span={3}>
                    {price}
                </Col>
                {!isNotVAT && <Col className="eq-f-td" span={3}>
                    {/* {calculatePercentage(price, vat ?? 0)} */}
                    {vat}
                </Col>}
                {/* <Col className="eq-f-td" span={2}>
                    {calculatePercentage(price, 1)}
                </Col> */}
                <Col className="eq-f-td" span={3}>
                    <span className={`eq-status-${status}`}>
                        {EqPaymentStatus[status]}
                    </span>
                </Col>
                <Col className="eq-f-td" span={2}>
                    <div className="">

                        <span className="eq-action-invite" onClick={() => setIsModalVisible(true)}>
                            <ProfileOutlined style={{ color: "#FF8838" }} /> {t("receipt")}
                        </span>
                    </div>
                </Col>
            </Row>

            <div className="hidden-md">
                <EqCollapse {...CollapseProps}>
                    <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {title}
                            </Col>
                            <Col className="" span={24}>
                                {title}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("paymentDate")}
                            </Col>
                            <Col className="" span={24}>
                                {moment(date).format(
                                    "DD MMMM, YYYY HH:mm"
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>

                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("price")}
                            </Col>
                            <Col className="" span={24}>
                                <span className="eq-f-currency">{price}</span>
                            </Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("vat")}
                            </Col>
                            <Col className="" span={24}>
                                <span className="eq-f-currency">
                                    {calculatePercentage(
                                        price,
                                        vat ?? 0
                                    )}
                                </span>
                            </Col>
                        </Row>

                        {/* <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("fee")}
                            </Col>
                            <Col className="" span={24}>
                                <span className="eq-f-currency"> {calculatePercentage(price, 1)}</span>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("status")}
                            </Col>
                            <Col className="" span={24}>
                                <span className={`eq-status-${getClass(status)}`}>
                                    {/* {paymentStatusType[status]} */}
                        {EqPaymentStatus[status]}

                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("actions")}
                            </Col>
                            <Col className="" span={24}>
                                <div className="">
                                <span className="eq-action-invite" onClick={() => setIsModalVisible(true)}>
                            <ProfileOutlined style={{ color: "#FF8838" }} /> {t("receipt")}
                        </span>
                                </div>
                            </Col>
                        </Row>
                    </>
                </EqCollapse>
            </div>

            
      <Modal
        title="Receipt"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null} // Hide the footer if you don't need it
        width={1050}
        >
            <EqPdfFinancialReceipt
            title="Receipt"
            bookingId={id??""}
            bookingDate={moment(date).format("DD MMMM, YYYY HH:mm")}

          purchaserName={`${name}`}
          totalExcludingVAT={price- price*((vat??0) / 100)}
          totalCost={price}
            headers={[t("items"), t("price"), t("vat")+"%"]}

            data={[{
                item: title,
                // owner: `${order.user.firstName} ${order.user.lastName}`,
                price: price,
                vat:vat??0,
            }]}
            columns={[
              { key: "item", width: "60%" },
              { key: "price", width: "20%" },
              { key: "vat", width: "20%" },
            ]}
            showVAT={true}
        />
        </Modal>
        </>
    );
};
export default EqProductFinancial;
