import * as React from "react";
import "./CreateOrEditTraining.scss";
import {
  Row,
  Col,
  Switch,
  Input,
  Divider,
  Space,
  Typography,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuthDispatch, useAuthState } from "../../../context/Context";
import {
  ApiEndpoints,
  dateFormat,
  FrontEndPoints,
  GetEventMessage,
} from "../../../shared/Common";
import { loadState } from "../../../shared/LocalStorage";

import EqButton from "../../../custom/eqButton/EqButton";
import { EqInput } from "../../../custom/eqInput/EqInput";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import EqDatePicker, {
  IDatePickerOptions,
} from "../../../custom/eqDatePicker/EqDatePicker";
import Api from "../../../shared/Api";
import {
  ICreateTrainingDto,
  ILessonAttendeeDto,
  ITrainingTimesDto,
} from "../../../shared/interfaces/ICreateTrainingDto";
import { IStudentGroupDto } from "../../../shared/interfaces/IStudentGroupDto";
import {
  EqDisciplineType,
  EqEventRepeatType,
  EqEventAccessType,
  eqNotificationTypes,
  EqUserType,
} from "../../../shared/enums";
import { ISelectProps } from "../../../shared/Interfaces";
import { notification } from "antd";
import EqAttendeeCard, {
  IEqAttendeeCardData,
} from "../../eqAttendeeCard/EqAttendeeCard";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { IEqCollapse } from "../../../custom/eqCollapse/EqCollapse";
import { updateActivityData } from "../../../context/Actions";
import { PlusOutlined, ToolFilled } from "@ant-design/icons";
import EqTags from "../../../custom/eqTags/EqTags";
import {
  IUpdateTrainingInfoDto,
  // IUpdateTrainingTimeAttendeesDto,
  IUpdateTrainingTimesDto,
} from "../../../shared/interfaces/IUpdateTrainingInfoDto";
import { IUserInfoDto } from "../../../shared/interfaces/IUserInfoDto";
import { IGroupInfoDto } from "../../../shared/interfaces/group/IGroupInfoDto";
import { Spin } from "antd-v5";

const newEmptyCardData: IEqAttendeeCardData = {
  key: 0,
  cardkey: 1,
  timeFrom: moment(),
  timeTo: moment(),
  duration: 30,
  maxSpaceCount: 1,
  price: "100",
  trainingLevel: "",
  attendees: [] as any,
  attendeesKeys: [] as any,
  display: true,
};

export interface ICreateOrEditTraining {
  creatorUser?: any;
  areas: any;
  weekDaysData: any;
  population: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  groupPopulation: any;
  eventTypes: any;
  eventKey: any;
  eventData: any;
  onCreateOrUpdateActivity?: any;
  isStudent: boolean;
  onActivityTitleChange: any;
  onCancelActivity?: any;
  onSaveSetBackButton: any;
  // trainingBackButton:any;
  isTrainingSaved: any;
  isPreviousTraining?: boolean;
}

const CreateOrEditTraining = ({
  creatorUser,
  areas,
  weekDaysData,
  population,
  trainerStudents,
  studentHorsesList,
  groupPopulation,
  eventTypes,
  eventKey,
  eventData,
  onCreateOrUpdateActivity,
  isStudent,
  onActivityTitleChange,
  onCancelActivity,
  onSaveSetBackButton,
  isTrainingSaved,
  isPreviousTraining,
}: // trainingBackButton,
ICreateOrEditTraining) => {
  const dispatch = useAuthDispatch();
  const userDetails: any = useAuthState();
  const localData = loadState();
  const navigate = useNavigate();
  const location = useLocation();
  const [spinning, setSpinning] = React.useState<boolean>(false);

  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);
  const [studentsList, setStudentsList] = React.useState([]) as any;
  const [groupStudentsList, setGroupStudentsList] = React.useState([]) as any;

  const [groupStudentPopulation, setGroupStudentPopulation] = React.useState(
    [] as any
  );
  const [studentsByGroup, setStudentsByGroup] = React.useState([] as any);
  const [studentPopulation, setStudentPopulation] = React.useState({
    allStudents: [] as any,
    filteredStudents: [] as any,
  });
  // const [areas, setAreas] = React.useState([]) as any;
  // const [population, setPopulation] = React.useState([]) as any;
  // const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [searchedAttendees, setSearchedAttendees] = React.useState([]) as any;
  const [eventAttendees, setEventAttendees] = React.useState<
    Array<IStudentGroupDto>
  >([]);
  const [searchStudentsList, setSearchStudentsList] = React.useState([] as any);

  const [eventDurations, setEventDurations] = React.useState([]) as any;
  const [eventRepeats, setEventRepeats] = React.useState([]) as any;
  // const [eventTypes, setEventTypes] = React.useState({
  //   privateDiscipline: [],
  //   publicDiscipline: [],
  //   editOpenDiscipline: [],
  //   toggleOptions: [],
  // } as any);

  const [eventAccessTypes, setEventAccessTypes] = React.useState({
    allAccessTypes: [],
    privateOnly: [],
    toggleOptions: [],
  } as any);
  const [trainingData, setTrainingData] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState({
    // allWeekDays: [...weekDaysData],
    allWeekDays: [],
    selectedWeekDays: [],
    weekDayTags: [],
  } as any);
  const [selectedGroups, setSelectedGroups] = React.useState([] as any);
  const [groupKeys, setGroupKeys] = React.useState([] as any);
  const [isMultipleDateSelected, setIsMultipleDateSelected] =
    React.useState(false);
  // const [isPublic, setIsPublic] = React.useState(false);
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  const [selectedLoc, setSelectedLoc] = React.useState("");
  const [isEventCollapse, setIsEventCollapse] = React.useState(true);

  // const [isTrainingSaved, setIsTrainingSaved] = React.useState(false);
  //for private event

  const [multipleStartOn, setMultipleStartOn] = React.useState([] as any);
  const [attendeeCardData, setAttendeeCardData] = React.useState(
    [] as Array<IEqAttendeeCardData>
  );
  const [removedAttendeesList, setRemovedAttendeesList] = React.useState(
    [] as any
  );
  const [formData, setFormData] = React.useState({}) as any;
  const [isDropdownClicked, setIsDropdownClicked] = React.useState(false);

  const openNotificationWithIcon = (
    msg: any,
    desc: any,
    type = eqNotificationTypes.error
  ) => {
    if (type == eqNotificationTypes.success) {
      notification["success"]({
        message: msg,
        description: desc,
      });
    } else if (type == eqNotificationTypes.info) {
      notification["info"]({
        message: msg,
        description: desc,
      });
    } else {
      notification["error"]({
        message: msg,
        description: desc,
      });
    }
  };

  const setEditFormData = (data: any) => {
    if (data != undefined) {
      var selectedDate = new Date(data.startDate);
      var selectedTimeFrom = new Date(data.startTime);

      var calculatedTimeFrom = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTimeFrom.getHours(),
        selectedTimeFrom.getMinutes(),
        0,
        0
      );

      var calculatedTimeTo;
      if (data.endTime != undefined) {
        var selectedTimeTo = new Date(data.endTime);
        calculatedTimeTo = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTimeTo.getHours(),
          selectedTimeTo.getMinutes(),
          0,
          0
        );
      }
      var selectedGroups = new Array<string>();
      if (data.selectedGroups != null) {
        data.selectedGroups?.map((item: string) => {
          selectedGroups.push("" + item);
        });
      }

      // console.log(areas);

      if (areas) {
        var loc = [...areas]
          ?.filter((item: any) => {
            return item.key == data.locationKey;
          })
          ?.shift();

        if (loc != null && loc != undefined) {
          setSelectedLoc(loc.value);
        }
      }

      setSelectedGroups(selectedGroups);
      getStudentPopulation([...selectedGroups]);
      //setSelectedStartDate(selectedDate);
      //setSelectedStartTime(calculatedTimeFrom);

      return {
        accessType: data.accessType as EqEventAccessType,
        referenceKey: data.referenceKey,
        trainingLessonsKey: data.trainingLessonsKey,
        id: data.id,
        key: data.key,
        title: data.title,
        startDate: moment(data.startDate).local(),
        endDate: moment(data.endDate).local(),
        repeats: data.repeatType,
        isRepeat: data.isRepeat,
        disciplineType: data.disciplineType as EqDisciplineType,
        isLatePayment: data.isLatePayment,
        locationKey: data.locationKey,
        paymentDeadline:
          data.paymentDeadline > 0
            ? moment(data.paymentDeadline).local()
            : null,

        // Remove these
        timeFrom: moment(calculatedTimeFrom.valueOf()).local(),
        timeTo:
          calculatedTimeTo != undefined
            ? moment(calculatedTimeTo.valueOf()).local()
            : calculatedTimeTo,
        duration: data.duration,
        trainingLevel: data?.trainingLevel,
        maxSpaceCount: data.maxAvailableSpace,
        isPaid: data.price > 0 ? true : false,
        price: data.price > 0 ? data.price : "",
        paymentMethod: data.paymentMethod,
        message: data.message,
        horseAvailabilityStatus: data.horseAvailabilityStatus,
      };
    } else {
      return {
        title: "",
        startDate: moment(),
        endDate: moment(),
        repeats: "",
        isRepeat: false,
        timeFrom: moment(),
        timeTo: moment(),
        duration: "",
        disciplineType: EqDisciplineType.Dressage,
        trainingLevel: "",
        locationKey: "",
        maxSpaceCount: "",
        isPaid: true,
        price: "",
        paymentMethod: 1,
        isLatePayment: false,
        paymentDeadline: null,
        message: "",
        accessType: EqEventAccessType.Private + "",
        horseAvailabilityStatus: 0,
        eqImage: {},
      };
    }
  };

  const setEditAttendeeCardData = (data: any) => {
    if (data != undefined) {
      var cardKey = 0;
      var attendeeCardDataList = [] as Array<IEqAttendeeCardData>;
      data.trainingTimes?.map((item: any, index: any) => {
        var keysList = item.attendees?.map((attendee: any) => {
          return attendee.key;
        });

        cardKey = cardKey + 1;
        var newAddedCardData: IEqAttendeeCardData = {
          key: item.key,
          cardkey: cardKey,
          timeFrom: moment(item.startTime),
          timeTo: moment(item.endTime),
          duration: item.duration,
          maxSpaceCount: item.maxAvailableSpace,
          price: item.price,
          trainingLevel: item.trainingLevel,
          attendees: item.attendees ? item.attendees : [],
          attendeesKeys: keysList ? keysList : [],
          attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
          // attendeesKeys:[],
          display: true,
        };
        attendeeCardDataList.push(newAddedCardData);
      });
      setAttendeeCardData(attendeeCardDataList);
    }
  };

  const { TextArea } = Input;
  const gutter = { xs: 20, sm: 16, md: 24, lg: 32 };

  /////////////

  const setEventDefaults = () => {
    setFormData({
      title: "",
      startDate: null,
      endDate: moment(),
      repeats: "",
      isRepeat: false,
      timeFrom: moment(),
      timeTo: moment(),
      duration: "",
      disciplineType: EqDisciplineType.Dressage,
      trainingLevel: "",
      locationKey: "",
      // maxSpaceCount:  EqDisciplineType.Jumping?4:1,
      maxSpaceCount: "",
      isPaid: true,
      price: "",
      paymentMethod: 1,
      isLatePayment: false,
      paymentDeadline: null,
      message: "",
      accessType: EqEventAccessType.Private + "",
      horseAvailabilityStatus: 0,
      eqImage: {},
    });
    setEventAttendees([] as any);
    setAttendeeCardData([] as Array<IEqAttendeeCardData>);
    setSelectedGroups([]);

    setMultipleStartOn([]);

    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
    updateActivityData(dispatch, {});
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    }
    //else if (uiImage == null || Object.keys(uiImage).length === 0) {
    //    openNotificationWithIcon(msg, "Image" + desc);
    //    isComplete = false;
    //}
    else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Location" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      data.isRepeat == true &&
      data.repeats == ""
    ) {
      openNotificationWithIcon(msg, "Repeats" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      isEdit == false &&
      data.isRepeat == true &&
      (weekDays.selectedWeekDays == null ||
        weekDays.selectedWeekDays.length == 0)
    ) {
      openNotificationWithIcon(msg, "Week days" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      (attendeeCardData == null || attendeeCardData.length == 0) &&
      isEdit == false
    ) {
      // addNewAttendeeCard();
      openNotificationWithIcon(msg, "Time and duration" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      attendeeCardData.length > 0 &&
      isEdit == false
    ) {
      attendeeCardData.map((d: any) => {
        console.log(d);
        if (data.disciplineType != EqDisciplineType.Open && d.duration == "") {
          openNotificationWithIcon(msg, "Duration" + desc);
          isComplete = false;
        } else if (
          data.disciplineType != EqDisciplineType.Open &&
          d.maxSpaceCount == ""
        ) {
          openNotificationWithIcon(msg, "Maximum available spaces" + desc);
          isComplete = false;
        } else if (
          data.disciplineType == EqDisciplineType.Jumping &&
          d.trainingLevel == ""
        ) {
          openNotificationWithIcon(msg, "Training level" + desc);
          isComplete = false;
        }
      });
    }
    return isComplete;
  };

  const validateTrainingData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";

    //same for public and private
    if (data.title == "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (data.disciplineType == "") {
      openNotificationWithIcon(msg, "Event Type" + desc);
      isComplete = false;
    } else if (data.accessType == "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.locationKey == "") {
      openNotificationWithIcon(msg, "Location" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (data.isRepeat == true && data.repeats == "") {
      openNotificationWithIcon(msg, "Repeats" + desc);
      isComplete = false;
    } else if (
      isEdit == false &&
      data.isRepeat == true &&
      (weekDays.selectedWeekDays == null ||
        weekDays.selectedWeekDays.length == 0)
    ) {
      openNotificationWithIcon(msg, "Week days" + desc);
      isComplete = false;
    }

    return isComplete;
  };

  const OnSaveTraining = () => {
    let validate = validateTrainingData(formData);

    if (validate == true) {
      // if(localData!== undefined){
      //   var data={...localData,activityData:formData}
      //   saveState(data)
      // }

      updateActivityData(dispatch, formData);
      // onSaveSetBackButton();
      // setIsTrainingSaved(true);
      // getGroupsStudentList();
      getStudentPopulation(null);
      // onSaveSetBackButton(true);
    }
  };

  const setEventObject = (data: any): ICreateTrainingDto => {
    console.log("paymentDeadline", isPaymentDeadline);
    let validate = validateData(data);

    if (validate == true) {
      let dStart = new Date(data.startDate);
      let dTimeFrom = new Date(data.timeFrom);
      let dTimeTo = new Date(data.timeTo);
      let calTimeFrom = new Date(
        dStart.getFullYear(),
        dStart.getMonth(),
        dStart.getDate(),
        dTimeFrom.getHours(),
        dTimeFrom.getMinutes(),
        0,
        0
      );
      let calTimeTo = data.timeTo
        ? new Date(
            dStart.getFullYear(),
            dStart.getMonth(),
            dStart.getDate(),
            dTimeTo.getHours(),
            dTimeTo.getMinutes(),
            0,
            0
          )
        : undefined;
      console.log(calTimeTo);
      const newDto: ICreateTrainingDto = {
        accessType: data.accessType,
        referenceKey: data.referenceKey,
        // trainingLessonsKey:data.trainingLessonsKey,
        key: eventKey,
        title: data.title,
        startDate: moment(data.startDate, dateFormat)
          .utc()
          .startOf("day")
          .valueOf(),
        endDate: moment(data.endDate, dateFormat)
          .utc()
          .startOf("day")
          .valueOf(),
        isRepeat: data.isRepeat,
        repeatType: data.repeats ? parseInt(data.repeats) : 0, //issue

        // startTime: moment(calTimeFrom.valueOf()).utc().valueOf(),
        // endTime: moment(calTimeTo?.valueOf()).utc().valueOf(),
        // duration: parseInt(data.duration ? data.duration : 0), //issue "34" 34
        disciplineType: data.disciplineType,
        locationKey: +data.locationKey,
        // maxAvailableSpace: parseInt(
        //   data.maxSpaceCount ? data.maxSpaceCount : 0
        // ), //issue
        // trainingLevel: data.trainingLevel ? parseInt(data.trainingLevel) : 0, //issue
        // isPaid: data.price != "" ? true : false,
        // price: data.price != "" ? data.price : 0,
        // paymentMethod: data.paymentMethod,
        // isLatePayment: data.isLatePayment,
        // paymentDeadline: data.paymentDeadline
        //   ? moment(data.paymentDeadline, dateFormat).utc().valueOf()
        //   : (null as any),
        // message: data.message,
        // horseAvailabilityStatus: data.horseAvailabilityStatus,
      };

      if (removedAttendeesList.length > 0) {
        newDto.removedAttendees = [...removedAttendeesList];
      }

      // if (newDto.attendees == null) {
      //   newDto.attendees = new Array<IStudentGroupDto>();
      // }
      // eventAttendees?.map((item: IStudentGroupDto) => {
      //   newDto.attendees?.push(item);
      // });

      if (newDto.WeekDayIds == null) {
        newDto.WeekDayIds = new Array<string>();
      }
      //console.log(weekDays?.selectedWeekDays);
      weekDays?.selectedWeekDays?.map((item: string) =>
        newDto.WeekDayIds?.push(item)
      );
      if (newDto.selectedGroups == null) {
        newDto.selectedGroups = new Array<number>();
      }
      //console.log(weekDays?.selectedWeekDays);
      selectedGroups?.map((item: number) => newDto.selectedGroups?.push(item));

      // if (newDto.multipleStartDates == null) {
      //   newDto.multipleStartDates = new Array<number>();
      // }
      // multipleStartOn?.map((item: any) =>
      //   newDto.multipleStartDates?.push(
      //     moment(new Date(item).valueOf()).utc().valueOf()
      //   )
      // );

      //for private events
      console.log("private event data");
      if (newDto.trainingTimes == null) {
        newDto.trainingTimes = new Array<ITrainingTimesDto>();
      }

      if (data.accessType == EqEventAccessType.Private) {
        attendeeCardData?.map((item: any) => {
          console.log(item.attendees);

          var attendees = new Array<ILessonAttendeeDto>();

          item.attendees?.map((attendee: any) => {
            let obj = convertToStudentGroupDto(attendee);
            attendees.push(obj);

            // attendees?.push(item);
          });

          let dTimeFrom = new Date(item.timeFrom);
          let dTimeTo = new Date(item.timeTo);
          let calTimeFrom = new Date(
            dStart.getFullYear(),
            dStart.getMonth(),
            dStart.getDate(),
            dTimeFrom.getHours(),
            dTimeFrom.getMinutes(),
            0,
            0
          );
          let calTimeTo = item.timeTo
            ? new Date(
                dStart.getFullYear(),
                dStart.getMonth(),
                dStart.getDate(),
                dTimeTo.getHours(),
                dTimeTo.getMinutes(),
                0,
                0
              )
            : undefined;

          newDto.trainingTimes?.push({
            key: isEdit ? item.key : 0,
            startTime: moment(calTimeFrom.valueOf()).utc().valueOf(),
            endTime: data.timeTo
              ? moment(calTimeTo?.valueOf()).utc().valueOf()
              : undefined,
            duration: parseInt(item.duration ? item.duration : 0),
            // price: item.price != "" ? item.price : 0,
            price: parseInt(item.price ? item.price : 0),
            maxAvailableSpace: parseInt(
              item.maxSpaceCount ? item.maxSpaceCount : 0
            ),
            attendees: attendees,
            attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
            trainingLevel: parseInt(
              item.trainingLevel ? item.trainingLevel : 0
            ),
          });
        });
      }
      console.log(newDto);

      return newDto;
    } else {
      return {} as ICreateTrainingDto;
    }
  };

  const setTrainingUpdateDto = (data: any): IUpdateTrainingInfoDto => {
    console.log("paymentDeadline", isPaymentDeadline);
    let validate = validateData(data);

    if (validate == true) {
      let dStart = new Date(data.startDate);
      let dTimeFrom = new Date(data.timeFrom);
      let dTimeTo = new Date(data.timeTo);
      // let calTimeFrom = new Date(
      //   dStart.getFullYear(),
      //   dStart.getMonth(),
      //   dStart.getDate(),
      //   dTimeFrom.getHours(),
      //   dTimeFrom.getMinutes(),
      //   0,
      //   0
      // );
      // let calTimeTo = data.timeTo
      //   ? new Date(
      //       dStart.getFullYear(),
      //       dStart.getMonth(),
      //       dStart.getDate(),
      //       dTimeTo.getHours(),
      //       dTimeTo.getMinutes(),
      //       0,
      //       0
      //     )
      //   : undefined;
      // console.log(calTimeTo);
      const newDto: IUpdateTrainingInfoDto = {
        referenceKey: data.referenceKey,
        trainingLessonsKey: data.trainingLessonsKey,
        accessType: data.accessType,
        id: data.id,
        key: eventKey,
        title: data.title,
        disciplineType: data.disciplineType,
        locationKey: +data.locationKey,
        startDate: moment(data.startDate, dateFormat)
          .utc()
          .startOf("day")
          .valueOf(),
        endDate: moment(data.endDate, dateFormat)
          .utc()
          .startOf("day")
          .valueOf(),

        trainingTimes: new Array<IUpdateTrainingTimesDto>(),

        isRepeat: data.isRepeat,
        repeatType: data.repeats ? parseInt(data.repeats) : 0, //issue

        isLatePayment: data.isLatePayment,
        paymentDeadline: data.paymentDeadline
          ? moment(data.paymentDeadline, dateFormat).utc().valueOf()
          : (null as any),
      };

      if (removedAttendeesList.length > 0) {
        newDto.removedAttendees = [...removedAttendeesList];
      }

      if (newDto.selectedGroups == null) {
        newDto.selectedGroups = new Array<number>();
      }
      //console.log(weekDays?.selectedWeekDays);
      selectedGroups?.map((item: number) => newDto.selectedGroups?.push(item));

      if (!newDto.trainingTimes) {
        newDto.trainingTimes = new Array<IUpdateTrainingTimesDto>();
      }

      console.log("private event data");
      //for private events

      if (data.accessType == EqEventAccessType.Private) {
        attendeeCardData?.map((item: any) => {
          console.log(item.attendees);

          var attendees = new Array<any>();
          // var newAttendees = new Array<IStudentGroupDto>();

          item.attendees?.map((attendee: any) => {
            if (attendee.id) {
              attendees?.push(attendee);
            } else {
              let obj = convertToStudentGroupDto(attendee);

              attendees.push(obj);
            }
          });
          console.log(attendees);
          // console.log(newAttendees);

          let dTimeFrom = new Date(item.timeFrom);
          let dTimeTo = new Date(item.timeTo);
          let calTimeFrom = new Date(
            dStart.getFullYear(),
            dStart.getMonth(),
            dStart.getDate(),
            dTimeFrom.getHours(),
            dTimeFrom.getMinutes(),
            0,
            0
          );
          let calTimeTo = item.timeTo
            ? new Date(
                dStart.getFullYear(),
                dStart.getMonth(),
                dStart.getDate(),
                dTimeTo.getHours(),
                dTimeTo.getMinutes(),
                0,
                0
              )
            : undefined;

          newDto.trainingTimes?.push({
            key: isEdit ? item.key : 0,
            startTime: moment(calTimeFrom.valueOf()).utc().valueOf(),
            endTime: item.timeTo
              ? moment(calTimeTo?.valueOf()).utc().valueOf()
              : undefined,
            duration: parseInt(item.duration ? item.duration : 0),
            // price: item.price != "" ? item.price : 0,
            price: parseInt(item.price ? item.price : 0),
            maxAvailableSpace: parseInt(
              item.maxSpaceCount ? item.maxSpaceCount : 0
            ),
            attendees: attendees,
            attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
            trainingLevel: parseInt(
              item.trainingLevel ? item.trainingLevel : 0
            ),
          });
        });
      }
      console.log(newDto);

      return newDto;
    } else {
      return {} as IUpdateTrainingInfoDto;
    }
  };

  // const convertToStudentGroupDto = (attendee: any): IStudentGroupDto => {

  //     let user:IStudentDto={

  //     }
  //     let group:IGroupDto{

  //     }

  //   let obj: IStudentGroupDto = {
  //     key: attendee.key,
  //     user:{...user},
  //     group:{...attendee.group},
  //     userType: attendee.userType,
  //   };

  //   return obj;
  // };

  const convertToStudentGroupDto = (attendee: any): ILessonAttendeeDto => {
    let user: IUserInfoDto = {
      key: attendee.user.key,
      email: attendee.user.email,
      firstName: attendee.user.firstName,
      lastName: attendee.user.lastName,
    };

    // Check if attendee.group is not null before accessing its properties
    let group: IGroupInfoDto | null = null;
    if (attendee.group) {
      group = {
        key: attendee.group.key,
        name: attendee.group.name,
        type: attendee.group.type,
        message: attendee.group.message,
        color: attendee.group.color,
        messages: attendee.group.messages,
      };
    }

    let obj: ILessonAttendeeDto = {
      key: attendee.key,
      user: { ...user },
      group: group,
      userType: attendee.userType,
      // selected: attendee.selected, // Include if 'selected' property exists in the 'attendee' object
    };

    return obj;
  };

  React.useEffect(() => {
    console.log(isPreviousTraining);

    if (weekDaysData) {
      setWeekDays({ ...weekDays, allWeekDays: weekDaysData });
    }
    if (population) {
      sortAscending(population);
    }
    createEventDurations();
    createEventRepeats();

    if (eventKey !== 0) {
      setIsEdit(true);
      // GetTrainingWithLessonByKey(eventKey)
      // or
      //
      // console.log(eventData);
      setEditAttendeeCardData({ ...eventData });
      var formObj = setEditFormData({ ...eventData });
      setFormData(formObj);
    } else {
      setIsEdit(false);
      getStudentPopulation(null);

      if (
        userDetails.activityData &&
        userDetails.activityData.accessType === "1"
      ) {
        setFormData(userDetails.activityData);
      } else {
        setEventDefaults();
      }

      createEventAccessTypes();
    }
  }, [
    creatorUser,
    eventData,
    eventKey,
    areas,
    population,
    groupPopulation,
    trainerStudents,
    isTrainingSaved,
    weekDaysData,
    studentHorsesList,
    isPreviousTraining,
  ]);

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays({ ...weekDays, allWeekDays: response.data });
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  // studentPopulationNew
  const getStudentPopulation = (groups: any) => {
    // 
    const selectedGroupsData = groups || selectedGroups;
    var students = new Array<IStudentGroupDto>();
    var studentsData = [] as any;

    var groupStudents = new Array<IStudentGroupDto>();
    var groupStudentsData = [] as any;

    // separate students by groups
    var studentsDataByGroup = [] as any;

    if (selectedGroupsData && selectedGroupsData.length > 0) {
      if (trainerStudents) {
        [...trainerStudents].map((item: any) => {
          [...item.user.groups].filter((gr: any) => {
            let res = selectedGroupsData.some((gs: number) => {
              if (gs == gr.key) {
                let studentGroup: IStudentGroupDto = {
                  key: item.key,
                  user: item.user,
                  userType: 2,
                  group: gr,
                };
                groupStudents.push(studentGroup);

                var name =
                  item.user != undefined
                    ? item.user.firstName + " " + item?.user?.lastName
                      ? item.user.firstName + " " + item?.user?.lastName
                      : item.user.userName
                    : item.group != undefined
                    ? item.group.name
                    : "";
                var newKey = item.key;

                groupStudentsData.push({
                  key: newKey,
                  value: name,
                  label: name,
                });
              }
            });
          });
        });
      }

      [...selectedGroupsData].map((grpItem: any) => {
        let grpName = "";
        let sortStd = [] as any;
        groupStudents.map((grpSt: any) => {
          if (grpItem == grpSt.group.key) {
            grpName = grpSt.group.name;

            var name =
              grpSt.user != undefined
                ? grpSt.user.firstName + " " + grpSt?.user?.lastName
                  ? grpSt.user.firstName + " " + grpSt?.user?.lastName
                  : grpSt.user.userName
                : grpSt.group != undefined
                ? grpSt.group.name
                : "";

            sortStd.push({
              key: grpSt.key,
              value: name,
              label: name,
            });
          }
        });

        studentsDataByGroup.push({
          label: grpName,
          options: [...sortStd],
        });
      });
    }
    // console.log(studentsDataByGroup);
    // Trainer Students
    if (trainerStudents) {
      trainerStudents?.map((d: any) => {
        if (d.userType == EqUserType.Student) {
          students.push(d);

          var name =
            d.user != undefined
              ? d.user.firstName + " " + d?.user?.lastName
                ? d.user.firstName + " " + d?.user?.lastName
                : d.user.userName
              : d.group != undefined
              ? d.group.name
              : "";
          var newKey = d.key;

          studentsData.push({
            key: newKey,
            value: name,
            label: name,
          });
        }
      });
      if (groupStudentsData && groupStudentsData.length > 0) {
        //console.log("filter data");
        //console.log(groupStudentsData);
        let filteredStudents = studentsData.filter((s: any) => {
          let res = groupStudentsData.some((gs: any) => {
            return s.key === gs.key;
          });
          return !res;
        });
        setGroupStudentPopulation([...groupStudentsData]);
        setStudentsByGroup([...studentsDataByGroup]);
        setStudentPopulation({
          ...studentPopulation,
          filteredStudents: [...filteredStudents],
        });
        groupStudentsData = [];
      } else {
        setStudentPopulation({
          ...studentPopulation,
          allStudents: [...studentsData],
        });
        setGroupStudentPopulation([]);
        setStudentsByGroup([]);
      }

      if (groupStudents && groupStudents.length > 0) {
        var list = [] as any;
        let filteredStudents = students.filter((s: any) => {
          let res = groupStudents.some((gs: any) => {
            return s.key === gs.key;
          });
          if (res == true) {
            var index = groupStudents.findIndex((x: any) => {
              return x.key === s.key;
            });
            list.push(groupStudents[index]);
          } else {
            list.push(s);
          }
          return !res;
        });
        setSearchStudentsList([...list]);
      } else {
        setSearchStudentsList([...students]);
      }
    }
  };

  const createEventDurations = () => {
    var durations = [];
    let durationVals = [30, 45, 60, 90];
    for (var x = 0; x < durationVals.length; x++) {
      durations.push({ key: durationVals[x], value: durationVals[x] });
    }
    //for (var i = 5; i < 101; i += 5) {
    //    durations.push({ key: i, value: i });
    //}
    setEventDurations(durations);
  };

  const createEventRepeats = () => {
    var repeats = [];
    for (var x in EqEventRepeatType) {
      if (isNaN(Number(x)) == false) {
        repeats.push({ key: x, value: EqEventRepeatType[x] });
      }
    }
    setEventRepeats(repeats);
  };

  const createEventAccessTypes = () => {
    var accessTypes = [];
    var pAccess = [];
    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          pAccess.push({ key: Number(x), value: label });
        }
      }
    }
    // // console.log(formData?.disciplineType);
    if (isEdit == true) {
      if (formData?.disciplineType == EqDisciplineType.Open) {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...pAccess],
        });
      } else {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...accessTypes],
        });
      }
    } else {
      setEventAccessTypes({
        allAccessTypes: [...accessTypes],
        privateOnly: [...pAccess],
        toggleOptions: [...accessTypes],
      });
    }
  };

  const updateEndTime = (
    fullYear: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ) => {
    var calculatedDate = new Date(
      fullYear,
      month,
      date,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    setFormData({ ...formData, timeTo: moment(calculatedDate.valueOf()) });
  };

  const createTraining = () => {
    if (!userDetails.isSubscribed) {
      openNotificationWithIcon(
        "Error!",
        "Kindly Subscribe to Create",
        eqNotificationTypes.error
      );
      return;
    }

    var data = setEventObject(formData);

    setSpinning(true);
    if (data.startDate != null) {
      // console.log(data);

      Api.post(ApiEndpoints.TrainerTraining.CreateTraining, data).then(
        (response) => {
          setSpinning(false);

          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Created!",
              "Training(s) have been created, you can go to trainings and look at all trainings",
              eqNotificationTypes.success
            );
            setIsError(false);
            setMessage(GetEventMessage(response.status));
            setEventDefaults();
            onSaveSetBackButton(false);
            setEventAttendees([] as any);
            onActivityTitleChange("");
            onCreateOrUpdateActivity(response.data);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        },
        (error) => {
          setSpinning(false);

          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly Add Payment or contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      setIsError(true);
    }
  };

  const UpdateTraining = () => {
    setSpinning(true);
    var data = setTrainingUpdateDto(formData);

    Api.post(ApiEndpoints.TrainerTraining.UpdateTrainingInfo, data).then(
      (response) => {
        setSpinning(false);

        if (response && response.status == 200) {
          setIsError(false);
          setIsEdit(false);
          setMessage(GetEventMessage(response.status));

          setEventDefaults();
          createEventAccessTypes();
          // createEventTypes();
          onCreateOrUpdateActivity(response.data, data);

          openNotificationWithIcon(
            "Updated!",
            "You have Successfully Updated Training",
            eqNotificationTypes.success
          );
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setSpinning(false);

        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const SettleAttendeeOrder = (attendeeOrder: any) => {
    var oldData = setTrainingUpdateDto(formData);
    setSpinning(true);

    Api.put(
      ApiEndpoints.TrainerTraining.SettleAttendeeOrder + "/" + attendeeOrder.id
    ).then(
      (response) => {
        setSpinning(false);

        if (response && response.status == 200) {
          setMessage(GetEventMessage(response.status));
          setEventDefaults();

          createEventAccessTypes();
          // createEventTypes();
          onCreateOrUpdateActivity(response.data, oldData);

          openNotificationWithIcon(
            "Updated!",
            "You have Successfully Updated Training",
            eqNotificationTypes.success
          );
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setSpinning(false);

        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const OnAcceptAttendeeRequest = (attendeeOrder: any) => {
    var oldData = setTrainingUpdateDto(formData);
    setSpinning(true);

    Api.put(
      ApiEndpoints.TrainerTraining.AcceptAttendeeOrderRequest + "/" + attendeeOrder.id
    ).then(
      (response) => {
        setSpinning(false);

        if (response && response.status == 200) {
          setMessage(GetEventMessage(response.status));
          setEventDefaults();

          createEventAccessTypes();
          // createEventTypes();
          onCreateOrUpdateActivity(response.data, oldData);

          openNotificationWithIcon(
            t("accepted"),
            t("youHaveSuccessfullyAcceptedRequest"),

            eqNotificationTypes.success
          );
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setSpinning(false);

        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const sortAscending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    //console.log(sorted);
    return sorted;
  };

  const sortDescending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      // console.log(obj1);
      // console.log(obj2);
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    //console.log(sorted);
    return sorted;
  };

  const onSortChange = (group: any): any => {
    var sortedList = [] as any;
    switch (group) {
      case "asc":
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      case "desc":
        sortedList = sortDescending(eventAttendees);
        setEventAttendees(sortedList);
        break;
      default:
        sortedList = sortAscending(eventAttendees);
        setEventAttendees(sortedList);
    }
  };

  const removeEventMember = (member: any) => {
    // console.log(eventAttendees);
    var attendees = [...eventAttendees];
    var keys = [...groupKeys];
    if (attendees != null && attendees.length > 0) {
      const index = attendees.findIndex((x: any) => x.key == member.key);
      if (index > -1) {
        attendees.splice(index, 1);
      }
    }
    if (keys != null && keys.length > 0) {
      const index = keys.findIndex((x: any) => x);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }
    setGroupKeys([...keys]);
    // console.log(keys);
    setEventAttendees([...attendees]);
    // console.log(attendees);
  };

  const onGroupChange = (value: any) => {
    const selectedMembers = [...value];
    var selectedPopulation = [] as any;
    population.filter((item: any) => {
      if (selectedMembers.includes(item.key) == true) {
        selectedPopulation.push(item);
      }
    });
    // console.log(selectedMembers);
    // console.log(selectedPopulation);
    setSearchedAttendees([...selectedPopulation]);
  };

  ////////////////

  const typeSelectProps: ISelectProps = {
    options: eventTypes.toggleOptions.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: formData?.disciplineType,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, disciplineType: type });

      if (isEdit == true) {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
      } else {
        if (type == EqDisciplineType.Open) {
          setIsOpenEvent(true);
        } else {
          setIsOpenEvent(false);
        }
        // setEventAccessAndDisciplineTypes(false, formData?.accessType, type);
      }
    },
  };

  const repeatSelectProps: ISelectProps = {
    mode: "tags",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select repeating duration",
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.repeatType
      ? formData.repeatType.toString()
      : "",
    options: eventRepeats,
    changeFunc: (value: string) => {
      let repeat: number = +value;
      setFormData({ ...formData, repeats: repeat });
    },
  };

  const areaSelectProps: ISelectProps = {
    isTag: false,
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    placeholder: "Select area",
    ddClassName: "eq-select-dropdown",

    defaultSelectedValue: formData?.locationKey
      ? formData.locationKey + ""
      : "",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: areas,
    changeFunc: (value: string) => {
      setFormData({ ...formData, locationKey: value });

      let loc = areas
        ?.filter((item: any) => {
          return item.key == value;
        })
        ?.shift();
      if (loc != null && loc != undefined) {
        setSelectedLoc(loc.value);
      }
    },
  };

  const onChangeGroupSelect = (value: any) => {
    const selectedMembers = [...value];
    setSelectedGroups(selectedMembers);
    getStudentPopulation(selectedMembers);
  };

  const GroupProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: false,
    changeFunc: onChangeGroupSelect,
    // changeFunc: (value: any) => {
    //   setFormData({ ...formData,selectedGroups: value });
    // },
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: groupPopulation,
    // defaultSelectedValue: selectedGroups,
    defaultSelectedValue: selectedGroups,
    // handleOnBlur:(e)=>{
    isDropdownRenderClicked: isDropdownClicked,
    // }
  };

  const sortProps = {
    border: false,
    allowClear: false,
    classNames: "eq-select-sort",
    ddClassName: "eq-cg-dropdown",
    defaultSelectedValue: "Sort A-Z",
    changeFunc: onSortChange,
    options: [
      { key: "asc", value: "Sort A-Z" },
      { key: "desc", value: "Sort Z-A" },
    ],
  };

  const startOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.startDate,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      if (creatorUser) {
        if (creatorUser.allowLatePayments) {
          setFormData({
            ...formData,
            startDate: moment(date),
            paymentDeadline: moment(date).add(creatorUser.paymentDays, "days"),
          });
        } else {
          setFormData({
            ...formData,
            startDate: moment(date),
            paymentDeadline: moment(date),
          });
        }
      } else {
        setFormData({
          ...formData,
          startDate: moment(date),
          paymentDeadline: moment(date),
        });
      }
    },
  };

  const endOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.endDate,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, endDate: moment(date) });
    },
  };

  const advancePaymentDateProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.paymentDeadline,

    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      // setFormData({ ...formData, isLatePayment: false });
      // setFormData({ ...formData, paymentDeadline: moment(date) });
    },
  };

  const weekDaysSelectProps: ISelectProps = {
    mode: "multiple",
    isTag: false,
    showArrow: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-addSelect",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: "Select days",
    defaultSelectedValue: weekDays.selectedWeekDays,
    options: weekDays.allWeekDays,
    changeFunc: (param: any) => {
      setWeekDays({
        ...weekDays,
        selectedWeekDays: [...param],
        weekDayTags: [...param],
      });
    },
  };
  const changeEventCollapseDisplay = (display: boolean) => {
    setIsEventCollapse(display);
  };
  const eventCollapsibleProps: IEqCollapse = {
    display: isEventCollapse,
    setDisplay: changeEventCollapseDisplay,
    showArrow: false,
    classNames: "eq-transaparent-header",
    header: [
      <Row align="middle" justify="end">
        {selectedLoc && (
          <>
            <Col span={1}>
              <span className="">
                <img alt="" src="/map-marker-alt.svg" />
              </span>
            </Col>
          </>
        )}
        <Col flex="auto">
          <div className="header-label">{selectedLoc}</div>
        </Col>
        <Col>
          <EqTags
            className=""
            text={t(EqDisciplineType[formData.disciplineType])}
            dicipline={true}
          />
        </Col>
      </Row>,
    ],
  };

  const attendeeCardTimeFromChange = (cardkey: number, timeFrom: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].timeFrom = moment(timeFrom);
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardTimeToChange = (cardkey: number, timeTo: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].timeTo = moment(timeTo);
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardDurationChange = (cardkey: number, minutes: any) => {
    // console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].duration = minutes;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardAvailableSpaceChange = (cardkey: number, spaces: any) => {
    // console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].maxSpaceCount = spaces;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardPriceChange = (cardkey: number, price: any) => {
    // console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].price = price;
    }
    setAttendeeCardData(modifyList);
  };

  const attendeeCardTrainingLevelChange = (cardkey: number, levels: any) => {
    //console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].trainingLevel = levels;
    }
    setAttendeeCardData(modifyList);
  };

  const attendeeCardHorseChange = (
    cardkey: number,
    selectedHorsesData: any
  ) => {
    //console.log(attendees);

    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].attendeesHorse = selectedHorsesData;
    }

    setAttendeeCardData(modifyList);
  };

  const attendeeCardAttendeesChange = (
    cardkey: number,
    updatedAttendees: any
  ) => {
    //console.log(attendees);

    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      // Extract existing keys from the attendees array
      let existingKeys =
        modifyList[index]?.attendees?.map((a: any) => a.key) || [];

      // Filter out new attendees whose keys already exist in the attendees array
      let filteredAttendees = updatedAttendees.filter(
        (newAttendee: any) => !existingKeys.includes(newAttendee.key)
      );

      // Concatenate the filtered attendees with the existing attendees
      modifyList[index].attendees = [
        ...modifyList[index].attendees,
        ...filteredAttendees,
      ];

      // modifyList[index].attendees = attendees;
      let keys = modifyList[index]?.attendees?.map((a: any) => a.key);
      modifyList[index].attendeesKeys = keys;
    }

    setAttendeeCardData(modifyList);
  };

  const removeAttendeeFromCard = (
    cardkey: number,
    attendeeKey: any,
    refund: boolean
  ) => {
    //console.log(attendees);
    var removedAttendees = [];

    const cardIndex = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });

    var modifyList = [...attendeeCardData];

    if (cardIndex > -1) {
      var attendeesList = modifyList[cardIndex].attendees;
      var indexAttendee = attendeesList.findIndex((x) => x.key === attendeeKey);
      if (indexAttendee !== -1) {
        removedAttendees = attendeesList.splice(indexAttendee, 1);
      }
      modifyList[cardIndex].attendees = attendeesList;

      var keysList = modifyList[cardIndex].attendeesKeys;
      var indexKey = keysList.indexOf(attendeeKey);

      if (indexKey !== -1) {
        keysList.splice(indexKey, 1);
      }
      modifyList[cardIndex].attendeesKeys = keysList;
      // let keys = modifyList[index]?.attendees?.map((a: any) => a.key);
    }

    setAttendeeCardData(modifyList);
    // if attendee is not exist already is Data
    if (removedAttendees[0].id !== undefined) {
      const removedAttendee = {
        refund: refund,
        removedAttendee: removedAttendees[0],
      };
      setRemovedAttendeesList([...removedAttendeesList, removedAttendee]);
    }
  };

  const removeAttendeeCard = (cardkey: number) => {
    var newDataArr = [...attendeeCardData];
    if (attendeeCardData != null && attendeeCardData.length > 0) {
      newDataArr = newDataArr.filter((x: any) => x.cardkey != cardkey);
    }
    if (newDataArr.length > 0) {
      newDataArr[newDataArr.length - 1].display = true;
    }
    setAttendeeCardData(newDataArr);
  };
  //console.log("Data", attendeeCardData);

  const InitializeAttendeeCard = () => {
    var newDataArr = [] as Array<IEqAttendeeCardData>;
    var newAddedCardData = newEmptyCardData;

    newDataArr.push({ ...newAddedCardData });

    setAttendeeCardData(newDataArr);
  };

  const addNewAttendeeCard = () => {
    // console.log("length", attendeeCardData.length);

    var newDataArr = [...attendeeCardData];
    var newAddedCardData = newEmptyCardData;
       newAddedCardData.attendeesKeys=[];

    if (attendeeCardData.length !== 0) {
      let lastCard = attendeeCardData.reduce((max, card) =>
        max.cardkey > card.cardkey ? max : card
      );
      newAddedCardData.price = lastCard.price;

      if (formData.disciplineType === EqDisciplineType.Jumping) {
        // newAddedCardData.maxSpaceCount = 4;
        newAddedCardData.maxSpaceCount = lastCard.maxSpaceCount;
        newAddedCardData.duration=lastCard.duration
        newAddedCardData.trainingLevel = lastCard.trainingLevel;

      } else if(formData.disciplineType === EqDisciplineType.Dressage){
        newAddedCardData.maxSpaceCount = lastCard.maxSpaceCount;
        newAddedCardData.duration=lastCard.duration
        newAddedCardData.trainingLevel = "";

      }
      else {
        newAddedCardData.maxSpaceCount = lastCard.maxSpaceCount;
        newAddedCardData.trainingLevel = "";

      }

      // newAddedCardData = attendeeCardData.reduce((max, card) => max.cardkey > card.cardkey ? max : card);
      console.log(newAddedCardData);
      // newAddedCardData=lastCard;
    } else {
      if (formData.disciplineType === EqDisciplineType.Jumping) {
        newAddedCardData.maxSpaceCount = 4;
        newAddedCardData.trainingLevel = 1;
      } else {
        newAddedCardData.maxSpaceCount = 1;
        newAddedCardData.trainingLevel = "";
      }
    }

    if (attendeeCardData.length > 0) {
      var lastAddedCardData = attendeeCardData[attendeeCardData.length - 1];
      newDataArr[attendeeCardData.length - 1].display = false;
      newAddedCardData.timeFrom = moment(lastAddedCardData.timeFrom).add(
        lastAddedCardData.duration,
        "minutes"
      );
      newAddedCardData.cardkey = lastAddedCardData.cardkey + 1;
      newAddedCardData.attendeesKeys = [];
    } else {
      setMultipleStartOn([]);
    }
    //console.log(newAddedCardData);
    newDataArr.push({ ...newAddedCardData });

    setAttendeeCardData(newDataArr);
  };
  const onDragAttendee = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    //console.log(source);
    //console.log(destination);
    var modifyList = [...attendeeCardData];
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    const sourceCard = attendeeCardData[sInd - 1];
    const destCard = attendeeCardData[dInd - 1];

    const srcKey = sourceCard.attendeesKeys[source.index];
    if (srcKey) {
      let srcKeys = [...sourceCard.attendeesKeys];
      let srcAttendees = sourceCard.attendees.filter((item: any) => {
        return item.key != srcKey;
      });
      let destKeys = [...destCard.attendeesKeys];
      let destAttendees = [...destCard.attendees];
      if (sInd === dInd) {
        const [reorderedKeys] = srcKeys.splice(result.source.index, 1);
        srcKeys.splice(result.destination.index, 0, reorderedKeys);
        sourceCard.attendeesKeys = srcKeys;

        modifyList[sInd - 1] = sourceCard;
      } else {
        const itemExists = destKeys.indexOf(srcKey);
        if (itemExists === -1) {
          destKeys.push(srcKey);
          destCard.attendeesKeys = destKeys;

          let srcAttendee = sourceCard.attendees
            .filter((item: any) => {
              return item.key == srcKey;
            })
            .shift();
          destAttendees.push(srcAttendee);
          destCard.attendees = destAttendees;

          modifyList[dInd - 1] = destCard;

          srcKeys.splice(source.index, 1);
          sourceCard.attendeesKeys = srcKeys;

          sourceCard.attendees = srcAttendees;

          modifyList[sInd - 1] = sourceCard;
        }
      }
    }
    //console.log(modifyList);
    setAttendeeCardData(modifyList);
  };

  const handleDropdownRenderClick = (e: any) => {
    if (!isDropdownClicked) {
      // Perform your desired logic here when the dropdownRender button is clicked
      // console.log("DropdownRender button clicked");
      navigate(FrontEndPoints.trainer.groupsBoard, {
        state: {
          prevPath: location.pathname,
        },
      });
    } else {
      setIsDropdownClicked(false);
    }
  };
  return (
    <div className="create-edit-training">
      <Spin spinning={spinning} size="large">
        <div className="eq-create-training-container">
          <div className="">
            <Row gutter={gutter} className="event-info-input">
              <Col className="gutter-row" span={24}>
                {/* <EqCollapse {...eventCollapsibleProps}> */}
                {/* {(isEdit || !isTrainingSaved) && ( */}
                {true && (
                  <>
                    <Row>
                      <Col className="gutter-row" span={24}>
                        <Row justify="space-between">
                          <Col className="col-1" xs={24} sm={24} md={12}>
                            <div className="eq-input-group">
                              <div className="input-label">{t("title")}</div>
                              <EqInput
                                disabled={isStudent}
                                className="name-input"
                                value={formData.title}
                                onBlur={(e: any) =>
                                  onActivityTitleChange(e.currentTarget.value)
                                }
                                onChange={(event: any) =>
                                  setFormData({
                                    ...formData,
                                    title: event?.target?.value,
                                  })
                                }
                              />
                            </div>
                            <div className="eq-input-group">
                              <div className="event-type">
                                <div className="input-label">{t("type")}</div>
                                <div className="toggler">
                                  <EqSelect
                                    isDisabled={isStudent}
                                    {...typeSelectProps}
                                  />
                                </div>
                              </div>
                            </div>
                            <Row className="eq-input-group">
                              <Col className="input-label">{t("location")}</Col>
                              <Col span={24}>
                                <EqSelect
                                  isDisabled={isStudent}
                                  extraHeight={true}
                                  {...areaSelectProps}
                                  dropdownRender={(menu: any) => (
                                    <>
                                      {menu}
                                      <Divider style={{ margin: "8px 0" }} />
                                      <Space
                                        align="center"
                                        style={{ padding: "0 8px 4px" }}
                                      >
                                        <Typography.Link
                                          onClick={() =>
                                            navigate(
                                              FrontEndPoints.trainer
                                                .editTrainer+"?tab=4",
                                              {
                                                state: {
                                                  prevPath: location.pathname,
                                                },
                                              }
                                            )
                                          }
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          <PlusOutlined /> {t("createNew")}
                                        </Typography.Link>
                                      </Space>
                                    </>
                                  )}
                                />
                              </Col>
                            </Row>
                            {/* Show Data on 1nd Column on Create */}
                            {!isEdit && (
                              <div className="date-time-inputs">
                                <Row className="eq-input-group">
                                  <Col className="input-label">{t("date")}</Col>
                                  <Col span={24}>
                                    <EqDatePicker
                                      isDisabled={isStudent}
                                      {...startOnProps}
                                    ></EqDatePicker>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Col>
                          <Col className="col-2" xs={24} sm={24} md={12}>
                            {/*{isPaymentDeadline &&*/}
                            <Row className="eq-input-group">
                              <Col span={24} className="input-label">
                                {t("paymentDeadline")}
                              </Col>
                              <Col span={24}>
                                <EqDatePicker
                                  isDisabled={true}
                                  {...advancePaymentDateProps}
                                ></EqDatePicker>
                              </Col>
                            </Row>

                            {isEdit == false && (
                              <>
                                {isMultipleDateSelected == false && (
                                  <div className="eq-input-group">
                                    <label className="input-label">
                                      {t("repeats")}
                                    </label>
                                    <div className="repeat-time">
                                      <Switch
                                        checked={formData.isRepeat}
                                        onChange={(checked: any) =>
                                          setFormData({
                                            ...formData,
                                            isRepeat: checked,
                                          })
                                        }
                                      />
                                      {formData.isRepeat && (
                                        <EqSelect
                                          isDisabled={isStudent}
                                          {...repeatSelectProps}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                                {formData.isRepeat == true &&
                                  isMultipleDateSelected == false && (
                                    <Row className="eq-input-group">
                                      <Col className="input-label">
                                        {t("endsOnDate")}
                                      </Col>
                                      <Col span={24}>
                                        <EqDatePicker
                                          isDisabled={isStudent}
                                          {...endOnProps}
                                        ></EqDatePicker>
                                      </Col>
                                    </Row>
                                  )}
                                {isMultipleDateSelected == false && (
                                  <Row className="eq-input-group">
                                    {formData.isRepeat &&
                                      (formData.repeats ==
                                        EqEventRepeatType.Weekly ||
                                        formData.repeats ==
                                          EqEventRepeatType.Fortnightly) && (
                                        <>
                                          <Col className="input-label">
                                            {t("week")} {t("days")}
                                          </Col>
                                          <Col span={24}>
                                            <Row>
                                              {weekDays?.weekDayTags?.length >
                                                0 && (
                                                <>
                                                  {weekDays?.weekDayTags?.map(
                                                    (item: any) => (
                                                      <Col
                                                        className="eq-pl-discipline-list"
                                                        key={item.key}
                                                      >
                                                        <span className="eq-pl-discipline-list-active">
                                                          {item.description}
                                                        </span>
                                                      </Col>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </Row>

                                            <Row>
                                              <Col flex="auto">
                                                <EqSelect
                                                  isDisabled={isStudent}
                                                  extraHeight={true}
                                                  {...weekDaysSelectProps}
                                                ></EqSelect>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </>
                                      )}
                                  </Row>
                                )}
                              </>
                            )}

                            {
                              // isEdit == false &&
                              formData.disciplineType ==
                                EqDisciplineType.Jumping && (
                                <>
                                  <Row
                                    gutter={gutter}
                                    className="eq-cg-members-header eq-input-group"
                                  >
                                    <Col className="gutter-row" span={24}>
                                      <Row align="middle" justify="start">
                                        <Col span={24} className="input-label">
                                          <Row
                                            className="eq-cg-right"
                                            justify="end"
                                            align="middle"
                                          >
                                            <Col flex="auto">{t("groups")}</Col>
                                          </Row>
                                        </Col>
                                        <Col span={24} className="eq-cg-search">
                                          <EqSelect
                                            isDisabled={isStudent}
                                            {...GroupProps}
                                            dropdownRender={(menu: any) => (
                                              <>
                                                {menu}
                                                <Divider
                                                  style={{ margin: "8px 0" }}
                                                />
                                                <Space
                                                  align="center"
                                                  style={{
                                                    padding: "0 8px 4px",
                                                  }}
                                                  onClick={() =>
                                                    setIsDropdownClicked(true)
                                                  }
                                                >
                                                  <Typography.Link
                                                    onClick={
                                                      handleDropdownRenderClick
                                                    }
                                                    style={{
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    <PlusOutlined />{" "}
                                                    {t("createNew")}
                                                  </Typography.Link>
                                                </Space>
                                              </>
                                            )}
                                          ></EqSelect>
                                        </Col>
                                      </Row>
                                      {/* <Row>
                                    <Col span={24}>
                                      <EqGroupMembersList
                                        {...{
                                          groupMembersList: eventAttendees,
                                          delFunc: removeEventMember,
                                          hideGroup: true,
                                        }}
                                      ></EqGroupMembersList>
                                    </Col>
                                  </Row> */}
                                    </Col>
                                  </Row>
                                </>
                              )
                            }

                            {/* Show Data on 2nd Column on Edit */}
                            {isEdit && (
                              <div className="date-time-inputs">
                                <Row className="eq-input-group">
                                  <Col className="input-label">{t("date")}</Col>
                                  <Col span={24}>
                                    <EqDatePicker
                                      isDisabled={isStudent}
                                      {...startOnProps}
                                    ></EqDatePicker>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* {!isStudent && (
                    <Row justify="center" align="bottom" className="eq-cg-btn">
                      {!isEdit && (
                        <>
                          <Col className="eq-cg-buttons">
                            <EqButton
                              disabled={isStudent}
                              type="submit"
                              classNames="btn-background-orange eq-save-btn"
                              onClick={() => OnSaveTraining()}
                              // onClick={() => setIsEventCollapse(false)}
                            >
                              Save
                            </EqButton>
                          </Col>
                          <Col className="eq-cg-buttons">
                            <EqButton
                              type="button"
                              bordered="true"
                              classNames="btn-light-gray"
                              onClick={onCancelActivity}
                            >
                              {t("cancel")}
                            </EqButton>
                          </Col>
                        </>
                      )}
                    </Row>
                  )} */}
                  </>
                )}

                {/* {isEdit || isTrainingSaved ? ( */}
                {true ? (
                  <>
                    <Row
                      className="attendee-card-section"
                      align="middle"
                      justify="space-around"
                    >
                      <Col xs={24} sm={24} md={24}>
                        <div className="attendee-card-box">
                          {/* <div className={`attendee-card-box ${isStudent ? "attendee-card-box-student" : ""}`}> */}
                          <DragDropContext onDragEnd={onDragAttendee}>
                            <>
                              {attendeeCardData &&
                              attendeeCardData.length > 0 ? (
                                attendeeCardData?.sort((a:any,b:any)=>a.timeFrom-b.timeFrom).map(
                                  (
                                    item: IEqAttendeeCardData,
                                    index: number
                                  ) => (
                                    <Droppable
                                      // isDropDisabled={ isStudent}
                                      key={item.cardkey}
                                      droppableId={`${item.cardkey.toString()}`}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {/* {item.cardkey} */}
                                          <EqAttendeeCard
                                            key={item.cardkey}
                                            // key={index}
                                            {...{
                                              data: item,
                                              timeFromChange:
                                                attendeeCardTimeFromChange,
                                              timeToChange:
                                                attendeeCardTimeToChange,
                                              durationChange:
                                                attendeeCardDurationChange,
                                              maxSpaceCountChange:
                                                attendeeCardAvailableSpaceChange,
                                              attendeeCardPriceChange:
                                                attendeeCardPriceChange,
                                              trainingLevelChange:
                                                attendeeCardTrainingLevelChange,
                                              attendeesChange:
                                                attendeeCardAttendeesChange,
                                              attendeeCardHorseChange:
                                                attendeeCardHorseChange,
                                              removeCard: removeAttendeeCard,
                                              removeAttendeeFromCard:
                                                removeAttendeeFromCard,
                                              groupKeys: [...groupKeys],
                                              selectedGroups: [
                                                ...selectedGroups,
                                              ],
                                              groupStudentPopulation: [
                                                ...groupStudentPopulation,
                                              ],
                                              studentsByGroup: [
                                                ...studentsByGroup,
                                              ],
                                              studentPopulation:
                                                studentPopulation,
                                              searchStudentsList: [
                                                ...searchStudentsList,
                                              ],
                                              studentHorsesList: [
                                                ...studentHorsesList,
                                              ],
                                              multipleStartOn: [
                                                ...multipleStartOn,
                                              ],
                                              startDate: formData.startDate,
                                              trainingType:
                                                formData.disciplineType,
                                              isStudent: isStudent,
                                              isEdit: isEdit,
                                              isPreviousTraining:
                                                isPreviousTraining,
                                              SettleAttendeeOrder:
                                                SettleAttendeeOrder,
                                                OnAcceptAttendeeRequest:OnAcceptAttendeeRequest,
                                              enableCardRemove:
                                                attendeeCardData?.length > 1
                                                  ? true
                                                  : false,
                                            }}
                                          ></EqAttendeeCard>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  )
                                )
                              ) : (
                                <Row className="empty-card">
                                  <Col span={24}>
                                    <ToolFilled style={{ fontSize: "2rem" }} />
                                  </Col>

                                  <Col>{t("noActivityYet")}...</Col>
                                </Row>
                              )}
                              {/* {!isEdit && ( */}
                              <Row
                                className="add-btn-icon"
                                onClick={addNewAttendeeCard}
                              >
                                <img
                                  alt=""
                                  className="add"
                                  src="/plus-black.svg"
                                />
                              </Row>
                              {/* )} */}
                            </>
                          </DragDropContext>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {/* </EqCollapse> */}
                {/* {(isEdit || isTrainingSaved) && ( */}
                {true && (
                  <Row justify="center" align="bottom" className="eq-cg-btn">
                    <Col className="eq-cg-buttons">
                      {isEdit ? (
                        !isStudent && (
                          <EqButton
                            block
                            disabled={isStudent}
                            type="submit"
                            classNames="btn-background-orange"
                            onClick={UpdateTraining}
                            // onClick={editTraining}
                          >
                            {t("saveChanges")}
                          </EqButton>
                        )
                      ) : attendeeCardData && attendeeCardData.length > 0 ? (
                        <EqButton
                          disabled={isStudent}
                          type="submit"
                          classNames="btn-background-orange"
                          onClick={createTraining}
                        >
                          {t("create")}
                        </EqButton>
                      ) : (
                        ""
                      )}
                    </Col>

                    {!isEdit && (
                      <Col className="eq-cg-buttons">
                        <EqButton
                          type="button"
                          bordered="true"
                          classNames="btn-light-gray"
                          onClick={onCancelActivity}
                        >
                          {t("cancel")}
                        </EqButton>
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </div>
  );
};
export default CreateOrEditTraining;
