import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import EqDatePicker, { IDatePickerOptions } from "../../custom/eqDatePicker/EqDatePicker";
import { useTranslation } from "react-i18next";
import EqButton from "../../custom/eqButton/EqButton";
import moment from "moment";
import "./LogsViewer.scss";

const LogsViewer = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [searchText, setSearchText] = useState(""); // State for search text
  const { t } = useTranslation();

  const fetchLogs = async () => {
    if (!selectedDate) {
      alert("Please select a date first!");
      return;
    }

    setLoading(true);
    try {
      const formattedDate = selectedDate.format("YYYYMMDD");
      const response = await fetch(`/api/logs/GetLogsByDate?date=${formattedDate}`);
      const data = await response.json();

      if (data.data) {
        // const logsArray = data.data.split("\n");
        const logsArray = data.data.split(/(?=\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/);

        setLogs(logsArray);
        setFilteredLogs(logsArray);
      } else {
        setLogs([]);
        setFilteredLogs([]);
        alert("No logs found for the selected date.");
      }
    } catch (err) {
      console.error("Failed to fetch logs", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    const today = moment();
    setSelectedDate(today);
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);

    const filtered = logs.filter((log:string) => log.toLowerCase().includes(text.toLowerCase()));
    setFilteredLogs(filtered);
  };

  const dateProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: selectedDate,
    placeHolder: t("selectDate"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setSelectedDate(date);
    },
  };

  return (
    <div className="eq-logs">
      <div className="logs-header">
        <div className="logs-controls">
          <EqDatePicker {...dateProps} />
          <EqButton type="primary" onClick={fetchLogs} loading={loading}>
            {t("fetchLogs")}
          </EqButton>
        </div>
        <Input
          className="logs-search"
          placeholder="Search logs"
          value={searchText}
          onChange={handleSearch}
        />
      </div>

      <Table
        dataSource={filteredLogs.map((log, index) => ({ key: index, log }))}
        columns={[{ title: "Log Entry", dataIndex: "log", key: "log" }]}
        pagination={{ defaultPageSize: 10 }}
      />
    </div>
  );
};

export default LogsViewer;
