import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "./TrainingCourseCreate.scss";
import { Row, Col, Tabs, Empty } from "antd-v5";
import EqSelect from "../../../custom/eqSelect/EqSelect";
import { useTranslation } from "react-i18next";
import { ISelectProps } from "../../../shared/Interfaces";
import { EqInput } from "../../../custom/eqInput/EqInput";
import moment from "moment";
import EqButton from "../../../custom/eqButton/EqButton";
import { CloseOutlined } from "@ant-design/icons";
import RidingCourseInformation from "../RidingCourseInformation/RidingCourseInformation";
import { EqEventRepeatType, EqCurrency, EqJoinPermissions } from "../../../shared/enums";
import EqDatePicker, { IDatePickerOptions } from "../../../custom/eqDatePicker/EqDatePicker";
import EqTimePicker, { ITimePickerOptions } from "../../../custom/eqTimePicker/EqTimePicker";
import { Modal, Tooltip } from "antd-v5";
import EqCourseStudentsSelect from "../EqCourseStudentsSelect/EqCourseStudentsSelect";
import useCourseValidation from "./useCourseValidation";
import { ApiEndpoints, getUserIdFromToken, ticksToMilliSeconds } from "../../../shared/Common";
import LessonsRidingCourse from "../RidingCourseLesson/LessonRidingCourse/LessonsRidingCourse";
import {
    TrainingCourseCreateProp,
    EqImageInfoDto,
    CourseUI,
    CourseCreateForm,
    CourseStatus,
} from "../../../shared/interfaces/ridingCourse/IRidingCourse";
import EqImageSelector from "../../../../eqComponents/eqImageSelector/EqImageSelector";
import TrainingCourseView from "../trainingCourseView/TrainingCourseView";
import Api from "../../../shared/Api";

const TrainingCourseCreate: React.FC<TrainingCourseCreateProp> = ({
    imagesData,
    onImageUploadSuccess,
    onImageDeleteSuccess,
    onClose,
    trainersOptions,
    schoolStudents,
    studentHorsesList,
    editCourse,
    onCreateCourse,
    onEditCourseDetails,
    onEditCourseLesson,
    onRemoveLessonOrder,
    onRemoveCourseOrder,
    isStudent,
    onAcceptCourseOrderRequest,
    onRejectCourseOrderRequest,
    onAcceptCourseLessonOrderRequest,
    onRejectCourseLessonOrderRequest,
    onDeleteCourseInvite,
    onBuy,
    onPayNow,
    onPayNowLessonOrder,
    onCourseLessonJoinRequest,
}) => {
    const { t } = useTranslation();
    const validateData = useCourseValidation();
    const [isEditMode, setIsEditMode] = useState(false); // Track whether we are in edit mode
    const [isEditDraft, setIsEditDraft] = useState(false); // Track whether we are in edit mode
    const [canSaveDraft, setCanSaveDraft] = useState(true); // Track whether we are in edit mode

    const [courseFormData, setCourseFormData] = useState({} as CourseCreateForm);
    const [stripeAccountInfo, setStripeAccountInfo] = React.useState() as any;

    const weekDaysData = [
        { key: "Sunday", value: "Sunday", label: "Sunday" },
        { key: "Monday", value: "Monday", label: "Monday" },
        { key: "Tuesday", value: "Tuesday", label: "Tuesday" },
        { key: "Wednesday", value: "Wednesday", label: "Wednesday" },
        { key: "Thursday", value: "Thursday", label: "Thursday" },
        { key: "Friday", value: "Friday", label: "Friday" },
        { key: "Saturday", value: "Saturday", label: "Saturday" },
    ];

    const [wordCount, setWordCount] = useState(0);
    const wordLimit = 1000;

    const handleInputChange = (field: string, value: any) => {
        setCourseFormData((prev: any) => ({ ...prev, [field]: value }));
    };

    const handleonClose = () => {
        onClose(); // Call onClose without passing false
        console.log("The Form Data that is being passed is", courseFormData);
    };

    useEffect(() => {
        GetStripeAccount();
        

        if (editCourse && editCourse.id!==undefined) {
        setIsEditMode(true); // Set mode to edit
        if(editCourse.status===CourseStatus.Drafted){
        setIsEditDraft(true); // Set mode to edit Draft  
        }
        setCourseFormData(editCourse);
       
        }else if(editCourse){
        //     var dateToday= moment();
        // setCourseFormData({...editCourse,startDate:dateToday});
        
        setCourseFormData(editCourse);
        setCanSaveDraft(false);

            
        }
    }, [editCourse]);

    const GetStripeAccount = () => {
        Api.get(ApiEndpoints.StripeConnectAccount.Get).then(
            (response) => {
                if (response.status === 200) {
                    setStripeAccountInfo(response.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const currencySelectProps: ISelectProps = {
        label: `${t("currency")} *`,
        classNames: "extra-height",
        placeholder: `${t("selectCurrency")}`,
        options: Object.entries(EqCurrency)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        // options: currencyOptions,
        defaultSelectedValue: courseFormData?.currency,

        changeFunc: (value: string) => {
            let type: number = +value;
            setCourseFormData({ ...courseFormData, currency: type });
        },
    };

    const joinPermissionsProps: ISelectProps = {
        label: `${t("joinPermission")}`,
        classNames: "extra-height",
        placeholder: `${t("select")}`,
        options: Object.entries(EqJoinPermissions)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        // options: currencyOptions,
        defaultSelectedValue: courseFormData?.joinPermission,

        changeFunc: (value: string) => {
            let type: number = +value;
            setCourseFormData({ ...courseFormData, joinPermission: type });
        },
    };

    const trainerSelectProps: ISelectProps = {
        options: trainersOptions,
        label: t("trainer"),
        defaultSelectedValue: courseFormData?.courseTrainerId,
        classNames: "extra-height",
        placeholder: t("selectTrainer"),
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,

        changeFunc: (value: string) => {
            setCourseFormData({ ...courseFormData, courseTrainerId: value });
        },
    };

    const handleCreateCourse = (status: CourseStatus) => {
        const isValid = validateData(courseFormData);
        if (!isValid) {
            return; // Stop execution if validation fails
        }

        const preparedData = {
            ...courseFormData,
            status,
            imageId: courseFormData?.image?.id,
            startDate: courseFormData.startDate?.valueOf(),
            endDate: courseFormData.endDate?.valueOf(),
            schoolId: getUserIdFromToken(),
        };

        if (onCreateCourse) {
            onCreateCourse(preparedData,false);

        }
    };

    const handlePublishCourse = () => {
        const isValid = validateData(courseFormData);
        if (!isValid) {
            return; // Stop execution if validation fails
        }

        const preparedData = {
            ...courseFormData,
            imageId: courseFormData?.image?.id,
            startDate: courseFormData.startDate?.valueOf(),
            endDate: courseFormData.endDate?.valueOf(),
            schoolId: getUserIdFromToken(),
        };

        if (onCreateCourse) {
            onCreateCourse(preparedData,true,courseFormData.id);

        }
    };


    const handleOnEditCourseDetails = (status: CourseStatus) => {
        
        // Prepare the data to be sent to the API
        const dataObject = {
            ...courseFormData,
            status,
            startDate: courseFormData.startDate?.valueOf(),
            endDate: courseFormData.endDate?.valueOf(),
            imageId: courseFormData?.image?.id,
        };

        if(status===CourseStatus.Drafted){
            setIsEditDraft(true);
        }
        onEditCourseDetails && onEditCourseDetails(dataObject);
    };

    const confirmSaveAsDraft = () => {
        Modal.confirm({
            title: t("draftConfirm"),
            footer: (
                <Row justify={"end"}>
                    {/* Discard Button */}
                    <EqButton
                        style={{ marginRight: "2px" }}
                        bordered
                        onClick={() => {
                            Modal.destroyAll(); // Close the modal on discard
                            onClose();
                            console.log("Discarded");
                        }}
                    >
                        {t("discard")}
                    </EqButton>

                    {/* Save as Draft Button */}
                    <EqButton
                        onClick={() => {
                            handleCreateCourse(CourseStatus.Drafted); // Call your save as draft function
                            Modal.destroyAll(); // Close the modal after saving
                        }}
                    >
                        {t("saveAsDraft")}
                    </EqButton>
                </Row>
            ),
            // Hide the default OK and Cancel buttons
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
        });
    };

    const countWords = (htmlString: any) => {
        // Strip HTML tags
        const text = htmlString.replace(/<[^>]*>/g, " ");

        // Split by spaces or newlines and count the words
        const words = text
            .trim()
            .split(/\s+/)
            .filter((word: any) => word.length > 0);
        return words.length;
    };
    const handleDescriptionChange = (value: string) => {
        const currentWordCount = countWords(value);

        if (currentWordCount <= wordLimit) {
            setCourseFormData({ ...courseFormData, description: value });
            setWordCount(currentWordCount);
        } else {
            // If capacity is reached, do not allow new input but allow deletion
            const limitedText = courseFormData.description; // Preserve the last valid state
            setCourseFormData({ ...courseFormData, description: limitedText });
        }
    };

    const handleImageSelect = (image: EqImageInfoDto) => {
        // Handle the selected image
        console.log("Selected Image :", image);
        setCourseFormData({ ...courseFormData, image: image });
    };

    const handleLessonInputChange = (field: string, value: any) => {
        setCourseFormData((prev: any) => {
            const updatedFormData = { ...prev, [field]: value };
            const endDate = handleEndDateChangeOnLessonChange(value, updatedFormData);
            return { ...updatedFormData, endDate };
        });
    };

    const handleEndDateChangeOnLessonChange = (lessonsValue: any, formData: any) => {
        let weekCount = lessonsValue;
        if (formData.repeatType === EqEventRepeatType.Fortnightly) {
            weekCount = weekCount * 2;
        }
        return calculateEndDate(formData.startDate, weekCount); // Return calculated end date
    };

    // start Data

    const startOnProps: IDatePickerOptions = {
        label: `${t("startDate")} *`,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: courseFormData?.startDate,
        placeHolder: t("startDate"),
        classNames: "eq-event",
        onChangeFunc: (date: any) => {
            
            handleStartDateChange(date);
        },
    };
    const handleStartDateChange = (date: any) => {
        // const dayOfWeek = date.day().toString();
        const dayOfWeek = date.format("dddd"); // Convert to day name

        console.log(dayOfWeek);
        // change weekDays Component on Date Change on Riding course
        // setWeekDays([dayOfWeek + ""]);

        // setCourseFormData((prevState: any) => ({
        //     ...prevState,
        //     weekDayIds: [dayOfWeek + ""],
        // }));
        let weekCount = courseFormData?.lessons ? Number(courseFormData?.lessons) : 0;
        if (courseFormData.repeatType === EqEventRepeatType.Fortnightly) {
            weekCount = weekCount * 2;
        }
        const endDate = calculateEndDate(date, weekCount);
        setCourseFormData({
            ...courseFormData,
            startDate: moment(date).toDate(),
            endDate,
            weekDayIds: [dayOfWeek],
        });
    };
    const calculateEndDate = (startDate: any, weeks: any) => {
        if (!startDate) return null;
        return moment(startDate).add(weeks, "weeks").toDate();
    };

    const endOnProps: IDatePickerOptions = {
        label: `${t("endDate")} *`,
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: courseFormData?.endDate,
        placeHolder: t("endDate"),
        classNames: "eq-event",
        onChangeFunc: (date: any) => {
            setCourseFormData({ ...courseFormData, endDate: moment(date) });
            //   calculateTrainingsCreated(null, moment(date), null);
        },
    };
    const calculateTrainingsCreated = (start?: any, end?: any, weekdays?: any) => {
        const startDate = start || courseFormData.startDate;
        const endDate = end || courseFormData.endDate;
        const selectedKeys = weekdays || courseFormData.weekDayIds;

        // Create a mapping object to map keys to day names
        const keyToDayName: any = {
            "0": "Sunday",
            "1": "Monday",
            "2": "Tuesday",
            "3": "Wednesday",
            "4": "Thursday",
            "5": "Friday",
            "6": "Saturday",
        };

        if (startDate && endDate && selectedKeys.length > 0) {
            const start = moment(startDate);
            const end = moment(endDate);
            let count = 0;

            while (start.isSameOrBefore(end)) {
                const dayName = start.format("dddd");
                // const dayKey = Object.keys(keyToDayName).find(
                //     (key: any) => keyToDayName[key] === dayName
                // );

                if (selectedKeys.includes(dayName)) {
                    count++;
                }
                start.add(1, "days");
            }

            console.log(`Number of training sessions: ${count}`);
            //setCreatedLessonCount(count);
        }
    };

    const weekDaysSelectProps: ISelectProps = {
        mode: "multiple",
        label: `${t("repeatType")} *`,
        // showArrow: true,
        optionFilterProp: "label",
        isTag: false,
        allowClear: true,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        placeholder: t("selectDays"),
        defaultSelectedValue: courseFormData?.weekDayIds,
        options: weekDaysData,
        changeFunc: (param: any) => {
            // setWeekDays(param);

            setCourseFormData((prevState: any) => ({
                ...prevState,
                weekDayIds: [...param],
            }));

            calculateTrainingsCreated(null, null, [...param]);
        },
    };

    const repeatSelectProps: ISelectProps = {
        label: `${t("repeatType")} *`,

        mode: "tags",
        placeholder: t("selectRepeatingDuration"),
        classNames: "eq-addSelect extra-height",
        defaultSelectedValue: courseFormData?.repeatType,
        //   ? courseFormData.repeatType.toString()
        //   : "",
        options: Object.entries(EqEventRepeatType)
            .filter((e) => !isNaN(e[0] as any))
            .map((e) => ({ key: parseInt(e[0]), value: `${t(e[1] as any)}` })),
        changeFunc: (value: string) => {
            let repeat: number = +value;
            handleEndDateChangeOnRepeat(repeat);
        },
    };

    const timeFromProps: ITimePickerOptions = {
        isSuffixIcon: false,
        isBordered: true,
        classNames: "eq-event",
        defaultValue: courseFormData?.lessonStartTime,
        // ? moment(courseFormData?.startDate, "HH:mm")
        // : moment(),
        minuteInterval: 15,
        placeHolder: "Select duration..",
        onChangeFunc: (value: any) => {
            const formattedTime = value.format("HH:mm");

            setCourseFormData({ ...courseFormData, lessonStartTime: formattedTime });
        },
    };

    const handleEndDateChangeOnRepeat = (repeat: any) => {
        let weekCount = courseFormData?.lessons;
        if (repeat === EqEventRepeatType.Fortnightly) {
            weekCount = weekCount * 2;
        }
        const endDate = calculateEndDate(courseFormData.startDate, weekCount);
        setCourseFormData({ ...courseFormData, repeatType: repeat, endDate });
    };
    const trainings = [
        {
            id: 1,
            title: "Training 1",
            trainer: "Trainer 1",
            product: { name: "Product 1", lessons: 5 },
            location: "Pakistan",
            startDate: new Date(),
            startTime: new Date(),
            endTime: new Date(Date.now() + 3600000),
            duration: 60,
            disciplineType: "RidingCourse",
            lessonNumber: 1,
            capacity: 10,
            lessonPrice: 100,
        }
    ];

    const renderButtons=() => (
        <Row justify="end">
        <Col>
            {isEditMode ? (
                isEditDraft ? (
                    <Row>
                        <Col>
                            <Tooltip
                                title={
                                    stripeAccountInfo
                                        ? ""
                                        : "Connect Stripe Account"
                                }
                            >
                                <EqButton
                                    style={{ marginRight: "10px" }}
                                    type="submit"
                                    disabled={
                                        stripeAccountInfo
                                            ? false
                                            : true
                                    }
                                    classNames={
                                        stripeAccountInfo
                                            ? "btn-background-orange"
                                            : "btn-gray"
                                    }
                                    onClick={() =>
                                        handlePublishCourse()
                                    } // false for publish
                                >
                                    {t("publish")}
                                </EqButton>
                            </Tooltip>
                        </Col>
                        <Col>
                            <EqButton
                                // classNames="btn-light-gray"
                                type="button"
                                bordered="true"
                                onClick={() =>
                                    handleOnEditCourseDetails(CourseStatus.Drafted)
                                } // false for publish
                            >
                                {" "}
                                {t("updateDraft")}
                            </EqButton>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <EqButton
                            classNames="btn-background-orange"
                            onClick={() =>
                                handleOnEditCourseDetails(CourseStatus.Active)
                            }
                        >
                            {t("update")}
                        </EqButton>
                    </Row>
                ) // for create Event
            ) : (
                <Row>
                    <Col>
                        <Tooltip
                            title={
                                stripeAccountInfo
                                    ? ""
                                    : "Connect Stripe Account"
                            }
                        >
                            <EqButton
                                style={{ marginRight: "10px" }}
                                type="submit"
                                disabled={
                                    stripeAccountInfo ? false : true
                                }
                                classNames={
                                    stripeAccountInfo
                                        ? "btn-background-orange"
                                        : "btn-gray"
                                }
                                onClick={() =>
                                    handleCreateCourse(CourseStatus.Active)
                                } // false for publish
                            >
                                {t("publish")}
                            </EqButton>
                        </Tooltip>
                    </Col>
                    {canSaveDraft && (
                        <Col>
                            <EqButton
                                // classNames="btn-light-gray"
                                type="button"
                                bordered="true"
                                onClick={confirmSaveAsDraft} // false for publish
                            >
                                {" "}
                                {t("saveAsDraft")}
                            </EqButton>
                        </Col>
                    )}
                </Row>
            )}
        </Col>
    </Row>
    );
        
    

    return (
        <div className="riding-course-module">
            <EqButton
                shape="circle"
                style={{ margin: "-16px" }}
                classNames="btn-background-orange"
                onClick={handleonClose}
            >
                <CloseOutlined
                    style={{
                        fontSize: "19px",
                        fontWeight: "900",
                        verticalAlign: "-webkit-baseline-middle",
                        marginTop: "-2px",
                    }}
                />
            </EqButton>
            <Tabs>
                {isStudent ? (
                    <>
                        <Tabs.TabPane tab={t("details")} key="tab0">
                            <TrainingCourseView
                                course={editCourse}
                                onBuy={onBuy}
                                onPayNow={onPayNow}
                                isStudent
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Lessons" key="tab3">
                            <LessonsRidingCourse
                                isStudent={isStudent}
                                schoolStudentsData={schoolStudents}
                                studentHorsesList={studentHorsesList}
                                trainings={trainings}
                                trainersOptions={trainersOptions}
                                weekDays={weekDaysData}
                                editCourse={editCourse}
                                onEditLesson={onEditCourseLesson}
                                onRemoveLessonOrder={onRemoveLessonOrder}
                                onCourseLessonJoinRequest={onCourseLessonJoinRequest}
                                onPayNowLessonOrder={onPayNowLessonOrder}
                            />
                        </Tabs.TabPane>
                    </>
                ) : (
                    <>
                        <Tabs.TabPane tab={isEditMode ? t("update") : t("create")} key="tab1">
                            <div className="create-course-tab">
                                <div className="eq-create-course-container">
                                 
                              {renderButtons()}
                                    <h2>{t("createCourseTitle")}</h2>
                                    <Row gutter={[16, 1]}>
                                        <Col span={24} className="col-left mb-16 ">
                                            <EqImageSelector
                                                className="selector-3d"
                                                defaultSelectedImage={courseFormData?.image}
                                                imagesData={imagesData}
                                                onImageSelect={handleImageSelect}
                                                onImageUploadSuccess={onImageUploadSuccess}
                                                onImageDeleteSuccess={onImageDeleteSuccess}
                                            />
                                        </Col>

                                        <Col xs={24} sm={24} md={14}>
                                            <div className="eq-create-course-input">
                                                <EqSelect {...trainerSelectProps} />
                                            </div>
                                        </Col>

                                        <Col xs={24} sm={24} md={14}>
                                            <div className="eq-create-course-input">
                                                <EqInput
                                                    label={
                                                        <span>
                                                            {t("title")}
                                                            <span className="required">*</span>
                                                        </span>
                                                    }
                                                    placeHolder={t("enterTitle")}
                                                    className="name-input"
                                                    required="required"
                                                    value={courseFormData?.title}
                                                    onChange={(event: any) =>
                                                        handleInputChange(
                                                            "title",
                                                            event?.target?.value
                                                        )
                                                    }
                                                ></EqInput>
                                            </div>
                                        </Col>

                                        {(!isEditMode || (isEditMode && isEditDraft)) && (
                                            <>
                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <EqInput
                                                            label={
                                                                <span>
                                                                    {/* {t("numberOfCourses")} <span className="required">*</span> */}
                                                                    {t("numberOfLessonWeeks")}{" "}
                                                                    <span className="required">
                                                                        *
                                                                    </span>
                                                                </span>
                                                            }
                                                            className="name-input"
                                                            placeholder=""
                                                            type="number"
                                                            value={courseFormData?.lessons}
                                                            min={1}
                                                            onChange={(event: any) =>
                                                                handleLessonInputChange(
                                                                    "lessons",
                                                                    event?.target?.value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Col>

                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <EqInput
                                                            label={
                                                                <span>
                                                                    {t("courseLimit")}{" "}
                                                                    <span className="required">
                                                                        *
                                                                    </span>
                                                                </span>
                                                            }
                                                            className="name-input"
                                                            placeholder=""
                                                            type="number"
                                                            value={courseFormData?.capacity}
                                                            min={0}
                                                            onChange={(event: any) =>
                                                                handleInputChange(
                                                                    "capacity",
                                                                    event?.target?.value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        <Col xs={24} sm={24} md={14}>
                                            <div className="eq-create-course-input">
                                                <EqSelect {...currencySelectProps} />
                                            </div>
                                        </Col>

                                        <Col md={14} xs={24} sm={24}>
                                            <div className="eq-create-course-input">
                                                <EqInput
                                                    label={
                                                        <span>
                                                            {t("totalPrice")}
                                                            <span className="required">*</span>
                                                        </span>
                                                    }
                                                    className="name-input"
                                                    placeholder=""
                                                    required="required"
                                                    type="number"
                                                    min={0}
                                                    value={courseFormData?.coursePrice}
                                                    onChange={(event: any) => {
                                                        handleInputChange(
                                                            "coursePrice",
                                                            event?.target?.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        {(!isEditMode || (isEditMode && isEditDraft)) && (
                                            <>
                                                <Col md={14} xs={24} sm={24}>
                                                    <div className="eq-create-course-input">
                                                        <EqInput
                                                            label={
                                                                <span>
                                                                    {t("individualPrice")}
                                                                    <span className="required">
                                                                        *
                                                                    </span>
                                                                </span>
                                                            }
                                                            className="name-input"
                                                            placeholder=""
                                                            required="required"
                                                            type="number"
                                                            min={0}
                                                            value={courseFormData?.lessonPrice}
                                                            onChange={(event: any) =>
                                                                setCourseFormData({
                                                                    ...courseFormData,
                                                                    lessonPrice:
                                                                        event?.target?.value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                                
                                <Col xs={24} sm={24} md={14}>
                                    <div className="eq-create-course-input">
                                        <EqInput
                                            label={
                                                <span>
                                                    {t("vat")}
                                                    <span className="required">*</span>
                                                </span>
                                            }
                                            value={courseFormData?.vat}
                                            type="number"
                                            min={0}
                                            max={100}
                                            onChange={(e: any) =>
                                                handleInputChange("vat", e.target.value)
                                            }
                                            placeholder={t("vat")}
                                        />
                                    </div>
                                </Col>

                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <EqDatePicker
                                                            {...startOnProps}
                                                        ></EqDatePicker>
                                                    </div>
                                                </Col>

                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <EqSelect {...repeatSelectProps} />
                                                    </div>
                                                </Col>

                                                {(courseFormData?.repeatType ===
                                                    EqEventRepeatType.Weekly ||
                                                    courseFormData?.repeatType ===
                                                        EqEventRepeatType.Fortnightly) && (
                                                    <Col xs={24} sm={24} md={14}>
                                                        <div className="eq-create-course-input">
                                                            <EqSelect {...weekDaysSelectProps} />
                                                        </div>
                                                    </Col>
                                                )}

                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <EqDatePicker
                                                            isDisabled={true}
                                                            {...endOnProps}
                                                        ></EqDatePicker>
                                                    </div>
                                                </Col>

                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <Row className="label-h2">{t("from")}</Row>
                                                        <EqTimePicker
                                                            {...timeFromProps}
                                                        ></EqTimePicker>
                                                    </div>
                                                </Col>

                                                <Col xs={24} sm={24} md={14}>
                                                    <div className="eq-create-course-input">
                                                        <EqSelect
                                                            label={t("duration")}
                                                            classNames="extra-height"
                                                            isDisabled={false}
                                                            defaultSelectedValue={
                                                                courseFormData?.lessonDurationMinutes
                                                            }
                                                            changeFunc={(value: string) =>
                                                                handleInputChange(
                                                                    "lessonDurationMinutes",
                                                                    value
                                                                )
                                                            }
                                                            options={[
                                                                {
                                                                    key: 30,
                                                                    value: 30,
                                                                    label: "30",
                                                                },
                                                                {
                                                                    key: 45,
                                                                    value: 45,
                                                                    label: "45",
                                                                },
                                                                { key: 60, value: 60, label: "60" },
                                                                { key: 90, value: 90, label: "90" },
                                                            ]}
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        <Col xs={24} sm={24} md={14}>
                                            <div className="eq-create-course-input">
                                                <EqSelect {...joinPermissionsProps} />
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={14}>
                                            <div className="eq-create-course-input">
                                                <div className="input-label label-h2">
                                                    {t("description")}
                                                </div>

                                                <ReactQuill
                                                    theme="snow"
                                                    value={courseFormData?.description}
                                                    placeholder={t("addDescription")}
                                                    onChange={handleDescriptionChange}
                                                />

                                                <div className="eq-create-course-input">
                                                    {wordCount}/{wordLimit} {t("words")}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* {!isEditMode && ( */}
                                    <EqCourseStudentsSelect
                                        noBorder
                                        schoolStudentsData={schoolStudents}
                                        onSelectionChange={(data: any) => {
                                            setCourseFormData({
                                                ...courseFormData,
                                                selectedStudents: data,
                                            });
                                        }}
                                    ></EqCourseStudentsSelect>
                                    {/* )} */}
                              {renderButtons()}

                                </div>
                            </div>
                        </Tabs.TabPane>
                        {isStudent === false && (
                            <Tabs.TabPane tab={t("information")} key="tab2">
                                <div>
                                    {editCourse != null ? (
                                        <RidingCourseInformation
                                            editCourse={editCourse}
                                            schoolStudentsData={schoolStudents}
                                            onAcceptCourseOrderRequest={onAcceptCourseOrderRequest}
                                            onRejectCourseOrderRequest={onRejectCourseOrderRequest}
                                            onAcceptCourseLessonOrderRequest={
                                                onAcceptCourseLessonOrderRequest
                                            }
                                            onRejectCourseLessonOrderRequest={
                                                onRejectCourseLessonOrderRequest
                                            }
                                            onDeleteCourseInvite={onDeleteCourseInvite}
                                            onRemoveCourseOrder={onRemoveCourseOrder}
                                        />
                                    ) : (
                                        <div className="eq-el-container">
                                            <Empty description={t("noTrainingsAvailable")} />
                                        </div>
                                    )}
                                </div>
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane tab={t("lessons")} key="tab3">
                            <LessonsRidingCourse
                                schoolStudentsData={schoolStudents}
                                studentHorsesList={studentHorsesList}
                                trainings={trainings}
                                trainersOptions={trainersOptions}
                                weekDays={weekDaysData}
                                editCourse={editCourse}
                                onEditLesson={onEditCourseLesson}
                                onRemoveLessonOrder={onRemoveLessonOrder}
                            />
                        </Tabs.TabPane>
                    </>
                )}
            </Tabs>
        </div>
    );
};

export default TrainingCourseCreate;
