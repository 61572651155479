export interface IUnpaidOrdersDto{
    orderId: string; // Guid
    orderType: UnpaidOrdersType;
    userId: string; //  Guid
    userName: string;
    userEmail: string;
    title: string;

}


export enum UnpaidOrdersType {
    Training ,
    Event,
    Product,
    Course,
    CourseLesson,
}