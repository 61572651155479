import React from "react";
import "./CreateOrEditSchoolTraining.scss";
import { Row, Col, Switch, Input, Divider, Space, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../../context/Context";
import {
  ApiEndpoints,
  dateFormat,
  FrontEndPoints,
  GetEventMessage,
  ValidateIsTrainer,
} from "../../shared/Common";
import { loadState } from "../../shared/LocalStorage";

import EqButton from "../../custom/eqButton/EqButton";
import { EqInput } from "../../custom/eqInput/EqInput";
import EqSelect from "../../custom/eqSelect/EqSelect";

import EqDatePicker, {
  IDatePickerOptions,
} from "../../custom/eqDatePicker/EqDatePicker";
import Api from "../../shared/Api";
import { ITrainingTimesDto } from "../../shared/interfaces/ICreateTrainingDto";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import {
  EqDisciplineType,
  EqEventRepeatType,
  EqEventAccessType,
  eqNotificationTypes,
  EqUserType,
  ProductOrderStatus,
} from "../../shared/enums";
import { ISelectProps } from "../../shared/Interfaces";
import { notification } from "antd";

import { PlusOutlined, ToolFilled } from "@ant-design/icons";

import EqSchoolTrainingCard from "./eqSchoolTrainingCard/EqSchoolTrainingCard";
import { ICreateSchoolTrainingDto } from "../../shared/interfaces/ICreateSchoolTrainingDto";
import { IEqSchoolTrainingCardData } from "./eqSchoolTrainingCard/Dto/IEqSchoolTrainingCardData";
import { Spin } from "antd-v5";

const newEmptyCardData: IEqSchoolTrainingCardData = {
  key: 0,
  cardkey: 1,
  productId: "",
  timeFrom: moment(),
  timeTo: moment(),
  duration: 30,
  maxSpaceCount: 1,
  price: "100",
  trainingLevel: "",
  attendees: [] as any,
  attendeesKeys: [] as any,
  display: true,
};

export interface ICreateOrEditSchoolTraining {
  trainersOptions: any;
  products: any;
  productOptions: any;
  areas: any;
  weekDaysData: any;
  studentHorsesList?: any;
  schoolStudents?: any;
  groupPopulation: any;
  eventTypes: any;
  eventKey?: any;
  eventData?: any;
  onCreateOrUpdateSchoolTraining?: any;
  isStudent: boolean;
  onActivityTitleChange?: any;
  onCancelSchoolTraining?: any;
  // trainingBackButton:any;
  isTrainingSaved?: any;
  isPreviousTraining?: boolean;

}

const CreateOrEditSchoolTraining = ({
  trainersOptions,
  productOptions,
  products,
  areas,
  weekDaysData,
  schoolStudents,
  studentHorsesList,
  groupPopulation,
  eventTypes,
  eventKey,
  eventData,
  onCreateOrUpdateSchoolTraining,
  isStudent,
  onActivityTitleChange,
  onCancelSchoolTraining,
  isTrainingSaved,
  isPreviousTraining
}: // trainingBackButton,
ICreateOrEditSchoolTraining) => {
  const userDetails: any = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);

  const { t } = useTranslation();
  // const dispatch = useAuthDispatch();
  const localData = loadState();

  const [weekDays, setWeekDays] = React.useState({
    allWeekDays: [...weekDaysData],
    selectedWeekDays: [],
    weekDayTags: [],
  } as any);



  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");


  const [groupStudentPopulation, setGroupStudentPopulation] = React.useState(
    [] as any
  );
  const [studentsByGroup, setStudentsByGroup] = React.useState([] as any);
  const [studentPopulation, setStudentPopulation] = React.useState({
    allStudents: [] as any,
    filteredStudents: [] as any,
  });
  const [eventAttendees, setEventAttendees] = React.useState<
    Array<IStudentGroupDto>
  >([]);
  const [searchStudentsList, setSearchStudentsList] = React.useState([] as any);

  const [eventRepeats, setEventRepeats] = React.useState([]) as any;


  const [createdLessonCount, setCreatedLessonCount] = React.useState(0) as any;

  // const [eventKey, setEventKey] = React.useState(0);
  const [selectedGroups, setSelectedGroups] = React.useState([] as any);
  const [groupKeys, setGroupKeys] = React.useState([] as any);
  const [isMultipleDateSelected, setIsMultipleDateSelected] =
    React.useState(false);
  // const [isPublic, setIsPublic] = React.useState(false);
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  const [selectedLoc, setSelectedLoc] = React.useState("");
  //const [isEventCollapse, setIsEventCollapse] = React.useState(true);

  // const [isTrainingSaved, setIsTrainingSaved] = React.useState(false);
  //for private event

  const [multipleStartOn, setMultipleStartOn] = React.useState([] as any);
    const [attendeeCardData, setAttendeeCardData

    ] = React.useState(
    [] as Array<IEqSchoolTrainingCardData>
  );
  const [removedAttendeesList, setRemovedAttendeesList] = React.useState(
    [] as any
  );
  const [formData, setFormData] = React.useState({}) as any;
  const [selectedProduct, setSelectedProduct] = React.useState() as any;
  const [isDropdownClicked, setIsDropdownClicked] = React.useState(false);
  const [spinning, setSpinning] = React.useState<boolean>(false);

  const openNotificationWithIcon = (
    msg: any,
    desc: any,
    type = eqNotificationTypes.error
  ) => {
    if (type == eqNotificationTypes.success) {
      notification["success"]({
        message: msg,
        description: desc,
      });
    } else if (type == eqNotificationTypes.info) {
      notification["info"]({
        message: msg,
        description: desc,
      });
    } else {
      notification["error"]({
        message: msg,
        description: desc,
      });
    }
    };


  const setEditFormData = (data: any) => {
    if (data != undefined) {
      var selectedDate = new Date(data.startDate);
      var selectedTimeFrom = new Date(data.startTime);

      var calculatedTimeFrom = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTimeFrom.getHours(),
        selectedTimeFrom.getMinutes(),
        0,
        0
      );

      var calculatedTimeTo;
      if (data.endTime != undefined) {
        var selectedTimeTo = new Date(data.endTime);
        calculatedTimeTo = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTimeTo.getHours(),
          selectedTimeTo.getMinutes(),
          0,
          0
        );
      }
      var selectedGroups = new Array<string>();
      if (data.selectedGroups != null) {
        data.selectedGroups?.map((item: string) => {
          selectedGroups.push("" + item);
        });
      }

      // console.log(areas);

      if (areas) {
        var loc = [...areas]
          ?.filter((item: any) => {
            return item.key == data.locationKey;
          })
          ?.shift();

        if (loc != null && loc != undefined) {
          setSelectedLoc(loc.value);
        }
      }

      setSelectedGroups(selectedGroups);
      getStudentPopulation([...selectedGroups]);
      //setSelectedStartDate(selectedDate);
      //setSelectedStartTime(calculatedTimeFrom);

      var product = products?.find((item: any) => item.id == data?.productId);
      if (product) {
        setSelectedProduct(product);
        GetStudentListForSelectedProduct(product);
      }
      return {
        id: data.id,
        key: data.key,
        title: data.title,
        trainerKey: data.trainerKey,
        disciplineType: data.disciplineType as EqDisciplineType,
        productId: data.productId,
        startDate: moment(data.startDate).local(),
        endDate: moment(data.endDate).local(),
        repeats: data.repeatType,
        isRepeat: data.isRepeat,

        timeFrom: moment(calculatedTimeFrom.valueOf()).local(),
        timeTo:
          calculatedTimeTo != undefined
            ? moment(calculatedTimeTo.valueOf()).local()
            : calculatedTimeTo,

        duration: data.duration,
        trainingLevel: data?.trainingLevel,
        locationKey: data.locationKey,
        maxSpaceCount: data.maxAvailableSpace,
        isPaid: data.price > 0 ? true : false,
        price: data.price > 0 ? data.price : "",

        paymentDeadline:
          data.paymentDeadline > 0
            ? moment(data.paymentDeadline).local()
            : null,
        message: data.message,
        accessType: data.accessType as EqEventAccessType,
        horseAvailabilityStatus: data.horseAvailabilityStatus,
      };
    } else {
      return {
        title: "",
        startDate: moment(),
        endDate: moment(),
        repeats: "",
        isRepeat: false,
        timeFrom: moment(),
        timeTo: moment(),
        duration: "",
        disciplineType: EqDisciplineType.Dressage,
        trainingLevel: "",
        locationKey: "",
        maxSpaceCount: "",
        isPaid: true,
        price: "",
        paymentMethod: 1,
        isLatePayment: false,
        paymentDeadline: null,
        message: "",
        accessType: EqEventAccessType.Private + "",
        horseAvailabilityStatus: 0,
        eqImage: {},
      };
    }
  };

  const setEditAttendeeCardData = (data: any) => {
    if (data != undefined) {
      var cardKey = 0;
      var attendeeCardDataList = [] as Array<IEqSchoolTrainingCardData>;
      data.trainingTimes?.map((item: any, index: any) => {
        var keysList = item.attendees?.map((attendee: any) => {
          return attendee.key;
        });

        cardKey = cardKey + 1;
        var newAddedCardData: IEqSchoolTrainingCardData = {
          key: item.key,
          cardkey: cardKey,
          timeFrom: moment(item.startTime),
          timeTo: moment(item.endTime),
          duration: item.duration,
          maxSpaceCount: item.maxAvailableSpace,
          price: item.price,
          trainingLevel: item.trainingLevel,
          productId: item.productId,
          attendees: item.attendees ? item.attendees : [],
          attendeesKeys: keysList ? keysList : [],
          attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
          // attendeesKeys:[],
          display: true,
        };
        attendeeCardDataList.push(newAddedCardData);
      });
      setAttendeeCardData(attendeeCardDataList);
    }
  };

  const { TextArea } = Input;
  const gutter = { xs: 20, sm: 16, md: 24, lg: 32 };

  /////////////

  const setEventDefaults = () => {
    setFormData({
      title: "",
      startDate:null,
      endDate: moment(),
      repeats: "",
      isRepeat: false,
      timeFrom: moment(),
      timeTo: moment(),
      disciplineType:
        eventTypes.length > 1
          ? EqDisciplineType.Dressage
          : EqDisciplineType.RidingCourse,
      locationKey: "",
      trainerKey: "",

      // maxSpaceCount:  EqDisciplineType.Jumping?4:1,
      maxSpaceCount: "",
      accessType: EqEventAccessType.Private + "",
      eqImage: {},
    });
    setEventAttendees([] as any);
    setAttendeeCardData([] as Array<IEqSchoolTrainingCardData>);
    setSelectedGroups([]);

    setMultipleStartOn([]);

    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
    // updateActivityData(dispatch, {});
  };

  const validateData = (data: any): boolean => {
    let isComplete = true;
    const msg = "Incomplete data";
    const desc = " is empty.";
    //same for public and private
    if (data.title === "") {
      openNotificationWithIcon(msg, "Title" + desc);
      isComplete = false;
    } else if (data.disciplineType === "") {
      openNotificationWithIcon(msg, "Training Type" + desc);
      isComplete = false;
    } else if (
      data.disciplineType === EqDisciplineType.RidingCourse &&
      data.productId === ""
    ) {
      openNotificationWithIcon(msg, "Product" + desc);
      isComplete = false;
    } else if (data.accessType === "") {
      openNotificationWithIcon(msg, "Access type" + desc);
      isComplete = false;
    } else if (data.trainerKey === "") {
      openNotificationWithIcon(msg, "Trainer" + desc);
      isComplete = false;
    } else if (!data.startDate) {
      openNotificationWithIcon(msg, "Start On" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      data.isRepeat == true &&
      data.repeats == ""
    ) {
      openNotificationWithIcon(msg, "Repeats" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      isEdit == false &&
      data.isRepeat == true &&
      (weekDays.selectedWeekDays == null ||
        weekDays.selectedWeekDays.length == 0)
    ) {
      openNotificationWithIcon(msg, "Week days" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      (attendeeCardData == null || attendeeCardData.length == 0) &&
      isEdit == false
    ) {
      // addNewAttendeeCard();
      openNotificationWithIcon(msg, "Time and duration" + desc);
      isComplete = false;
    } else if (
      data.accessType == EqEventAccessType.Private &&
      attendeeCardData.length > 0 &&
      isEdit == false
    ) {
      attendeeCardData.map((d: any, cardIdx: any) => {
        if (data.disciplineType != EqDisciplineType.Open && d.duration == "") {
          openNotificationWithIcon(msg, "Duration" + desc);
          isComplete = false;
        } else if (
          data.disciplineType != EqDisciplineType.Open &&
          d.maxSpaceCount == ""
        ) {
          openNotificationWithIcon(msg, "Maximum available spaces" + desc);
          isComplete = false;
        } else if (
          data.disciplineType == EqDisciplineType.Jumping &&
          d.trainingLevel == ""
        ) {
          openNotificationWithIcon(msg, "Training level" + desc);
          isComplete = false;
        }
        // else if (
        //   data.disciplineType == EqDisciplineType.Product &&
        //   d.productId == ""
        // ) {
        //   openNotificationWithIcon(msg, `Product at card ${cardIdx}` + desc);
        //   isComplete = false;
        // }
        else if (
          data.disciplineType != EqDisciplineType.RidingCourse &&
          d.price == ""
        ) {
          openNotificationWithIcon(msg, `Price at card ${cardIdx}` + desc);
          isComplete = false;
        }
      });
    }
    return isComplete;
  };

  const setEventObject = (data: any): ICreateSchoolTrainingDto => {
    console.log("paymentDeadline", isPaymentDeadline);
    let validate = validateData(data);

    if (validate == true) {
      
      let dStart = new Date(data.startDate);
      // dStart.setHours(0, 0, 0, 0);

      let dTimeTo = new Date(data.timeTo);

      if (data.disciplineType === EqDisciplineType.RidingCourse) {
        let repeatType = data.repeats ? parseInt(data.repeats) : 0;
        if (repeatType === EqEventRepeatType.Fortnightly) {
          data.endDate = calculateEndDate(
            data.startDate,
            selectedProduct.lessons * 2
          );
        } else if (repeatType === EqEventRepeatType.Weekly) {
          data.endDate = calculateEndDate(
            data.startDate,
            selectedProduct.lessons
          );
        }
      }

      let calTimeTo = data.timeTo
        ? new Date(
            dStart.getFullYear(),
            dStart.getMonth(),
            dStart.getDate(),
            dTimeTo.getHours(),
            dTimeTo.getMinutes(),
            0,
            0
          )
        : undefined;
      console.log(calTimeTo);
      const newDto: ICreateSchoolTrainingDto = {
        key: eventKey,
        referenceKey: data.referenceKey,
        title: data.title,
        trainerKey: +data.trainerKey,
        startDate: moment(data.startDate, dateFormat)
          .utc()
          .startOf("day")
          .valueOf(),
        endDate: moment(data.endDate, dateFormat)
          .utc()
          .startOf("day")
          .valueOf(),
        isRepeat: data.isRepeat,
        repeatType: data.repeats ? parseInt(data.repeats) : 0, //issue
        disciplineType: data.disciplineType,
        locationKey: +data.locationKey,
        accessType: data.accessType,
        productId: data.productId,
      };

      if (removedAttendeesList.length > 0) {
        newDto.removedAttendees = [...removedAttendeesList];
      }
      if (newDto.weekDayIds == null) {
        newDto.weekDayIds = new Array<string>();
      }
      weekDays?.selectedWeekDays?.map((item: string) =>
        newDto.weekDayIds?.push(item)
      );
      if (newDto.selectedGroups == null) {
        newDto.selectedGroups = new Array<number>();
      }
      selectedGroups?.map((item: number) => newDto.selectedGroups?.push(item));

      //for private events
      console.log("private event data");
      if (newDto.trainingTimes == null) {
        newDto.trainingTimes = new Array<ITrainingTimesDto>();
      }

      if (data.accessType == EqEventAccessType.Private) {
        attendeeCardData?.map((item: any) => {
          let dTimeFrom = new Date(item.timeFrom);
          let dTimeTo = new Date(item.timeTo);
          let calTimeFrom = new Date(
            dStart.getFullYear(),
            dStart.getMonth(),
            dStart.getDate(),
            dTimeFrom.getHours(),
            dTimeFrom.getMinutes(),
            0,
            0
          );
          let calTimeTo = item.timeTo
            ? new Date(
                dStart.getFullYear(),
                dStart.getMonth(),
                dStart.getDate(),
                dTimeTo.getHours(),
                dTimeTo.getMinutes(),
                0,
                0
              )
            : undefined;

          newDto.trainingTimes?.push({
            key: isEdit ? item.key : 0,
            startTime: moment(calTimeFrom.valueOf()).utc().valueOf(),
            endTime: data.timeTo
              ? moment(calTimeTo?.valueOf()).utc().valueOf()
              : undefined,
            duration: parseInt(item.duration ? item.duration : 0),
            // price: item.price != "" ? item.price : 0,
            price: parseInt(item.price ? item.price : 0),
            maxAvailableSpace: parseInt(
              item.maxSpaceCount ? item.maxSpaceCount : 0
            ),
            attendees: item.attendees,
            attendeesHorse: item.attendeesHorse ? item.attendeesHorse : [],
            productId: item.productId,

            trainingLevel: parseInt(
              item.trainingLevel ? item.trainingLevel : 0
            ),
          });
        });
      }
      return newDto;
    } else {
      return {} as ICreateSchoolTrainingDto;
    }
  };

  React.useEffect(() => {
    
    if (weekDaysData) {
      setWeekDays({ ...weekDays, allWeekDays: weekDaysData });
    }
    createEventRepeats();

    if (eventKey !== 0) {
      setIsEdit(true);
      setEditAttendeeCardData({ ...eventData });
      var formObj = setEditFormData({ ...eventData });
      setFormData(formObj);
    } else {
      setIsEdit(false);
      getStudentPopulation(null);

      if (
        userDetails.activityData &&
        userDetails.activityData.accessType === "1"
      ) {
        setFormData(userDetails.activityData);
      } else {
        setEventDefaults();
      }
    }
  }, [
    eventData,
    eventKey,
    areas,
    // population,
    groupPopulation,
    schoolStudents,
    isTrainingSaved,
    weekDaysData,
    trainersOptions,
  ]);

  //   React.useEffect(() => {
  //     if(selectedProduct){
  //
  //       GetStudentListForSelectedProduct(selectedProduct.id)
  //     }
  //   }, [
  //     selectedProduct
  //   ]);
  // Student List for Products

  const GetStudentListForSelectedProduct = (selectedProduct: any) => {
    // 
    // let selectedProduct = products.find((item: any) => item.id == productId);
    let alreadyJoinedStudents = [] as any;
    let studentsNotJoined = [] as any;

    // Get students included in product order

    if (selectedProduct.productOrders.length > 0) {
      selectedProduct.productOrders.forEach((item: any) => {
        const student = item.student;
        if (student && (item.status === ProductOrderStatus.Accepted || item.status === ProductOrderStatus.Paid)) {
          alreadyJoinedStudents.push(student);
        }
      });
    }

    // Filter Students that are not Joined
    if (alreadyJoinedStudents.length > 0 && schoolStudents.length > 0) {
      const alreadyJoinedStudentsEmails = alreadyJoinedStudents.map(
        (student: any) => student.email
      );
      let filteredData = schoolStudents.filter((item: any) => {
        const user = item.user;
        if (user && user.email) {
          return !alreadyJoinedStudentsEmails.includes(user.email);
        }
        return true; // Include items with no user or email
      });

      studentsNotJoined = filteredData.map((item: any) => item.user);
    } else {
      studentsNotJoined = schoolStudents.map((item: any) => item.user);
    }

    // make student Lists

    let JoinedOptions = [] as any;
    let notJoinedOptions = [] as any;

    alreadyJoinedStudents.forEach((user: any) => {
      if (user) {
        const email = user.email || user.userName || ""; // Use email or userName if available
        const name =
          user.firstName + " " + user?.lastName || user.userName || ""; // Use firstName or userName if available

        JoinedOptions.push({
          key: email,
          value: name,
          label: name,
        });
      }
    });

    if(true){

      studentsNotJoined.forEach((user: any) => {
        if (user) {
          const email = user.email || user.userName || ""; // Use email or userName if available
        const name =
          user.firstName + " " + user?.lastName || user.userName || ""; // Use firstName or userName if available

        notJoinedOptions.push({
          key: email,
          value: name,
          label: name,
        });
      }
    });
    
  }
    // console.log(JoinedOptions);
    // console.log(notJoinedOptions);

    setStudentPopulation({
      ...studentPopulation,
      filteredStudents: [...notJoinedOptions],
    });

    let studentsDataByGroup = [] as any;
    studentsDataByGroup.push({
      label: "Paid",
      options: [...JoinedOptions],
    });

    setStudentsByGroup(studentsDataByGroup);

    // transforming to follow previous convention
    const transformedJoinedStudents = alreadyJoinedStudents.map(
      (student: any) => ({
        key: student.email,
        user: student,
      })
    );
    const transformedNotJoinedStudents = studentsNotJoined.map(
      (student: any) => ({
        key: student.email,
        user: student,
      })
    );

    setSearchStudentsList([
      ...transformedJoinedStudents,
      ...transformedNotJoinedStudents,
    ]);
  };

  // studentPopulationNew
  const getStudentPopulation = (groups: any) => {
    const selectedGroupsData = groups || selectedGroups;
    var students = new Array<IStudentGroupDto>();
    var studentsData = [] as any;

    var groupStudents = new Array<IStudentGroupDto>();
    var groupStudentsData = [] as any;

    // separate students by groups
    var studentsDataByGroup = [] as any;

    if (selectedGroupsData && selectedGroupsData.length > 0) {
      if (schoolStudents) {
        [...schoolStudents].map((item: any) => {
          [...item.user.groups].filter((gr: any) => {
            let res = selectedGroupsData.some((gs: number) => {
              if (gs == gr.key) {
                let studentGroup: IStudentGroupDto = {
                  key: item.key,
                  user: item.user,
                  userType: 2,
                  group: gr,
                };
                groupStudents.push(studentGroup);

                var name =
                  item.user != undefined
                    ? item.user.firstName + " " + item?.user?.lastName
                      ? item.user.firstName + " " + item?.user?.lastName
                      : item.user.userName
                    : item.group != undefined
                    ? item.group.name
                    : "";
                var newKey = item.key;

                groupStudentsData.push({
                  key: newKey,
                  value: name,
                  label: name,
                });
              }
            });
          });
        });

        // const groups = groupStudents.reduce((groups: any, item: any) => {
        //   const group = groups[item.group.key] || [];
        //   group.push(item);
        //   groups[item.group.key] = group;
        //   return groups;
        // }, {});
        // console.log(groups);
      }

      [...selectedGroupsData].map((grpItem: any) => {
        let grpName = "";
        let sortStd = [] as any;
        groupStudents.map((grpSt: any) => {
          if (grpItem == grpSt.group.key) {
            grpName = grpSt.group.name;

            var name =
              grpSt.user != undefined
                ? grpSt.user.firstName + " " + grpSt?.user?.lastName
                  ? grpSt.user.firstName + " " + grpSt?.user?.lastName
                  : grpSt.user.userName
                : grpSt.group != undefined
                ? grpSt.group.name
                : "";

            sortStd.push({
              key: grpSt.key,
              value: name,
              label: name,
            });
          }
        });

        studentsDataByGroup.push({
          label: grpName,
          options: [...sortStd],
        });
      });

      // groups.map((g:any)=>{

      // })
      // console.log("groupStudent 2:", groupStudents);
      // console.log("groupStudentData 2:", groupStudentsData);
    }
    // console.log(studentsDataByGroup);
    // Trainer Students
    if (schoolStudents) {
      schoolStudents?.map((d: any) => {
        if (d.userType == EqUserType.Student) {
          students.push(d);

          var name =
            d.user != undefined
              ? d.user.firstName + " " + d?.user?.lastName
                ? d.user.firstName + " " + d?.user?.lastName
                : d.user.userName
              : d.group != undefined
              ? d.group.name
              : "";
          var newKey = d.key;

          studentsData.push({
            key: newKey,
            value: name,
            label: name,
          });
        }
      });
      if (groupStudentsData && groupStudentsData.length > 0) {
        let filteredStudents = studentsData.filter((s: any) => {
          let res = groupStudentsData.some((gs: any) => {
            return s.key === gs.key;
          });
          return !res;
        });
        setGroupStudentPopulation([...groupStudentsData]);
        setStudentsByGroup([...studentsDataByGroup]);
        setStudentPopulation({
          ...studentPopulation,
          filteredStudents: [...filteredStudents],
        });
        groupStudentsData = [];
      } else {
        setStudentPopulation({
          ...studentPopulation,
          allStudents: [...studentsData],
        });
        setGroupStudentPopulation([]);
        setStudentsByGroup([]);
      }

      if (groupStudents && groupStudents.length > 0) {
        var list = [] as any;
        let filteredStudents = students.filter((s: any) => {
          let res = groupStudents.some((gs: any) => {
            return s.key === gs.key;
          });
          if (res == true) {
            var index = groupStudents.findIndex((x: any) => {
              return x.key === s.key;
            });
            list.push(groupStudents[index]);
          } else {
            list.push(s);
          }
          return !res;
        });
        setSearchStudentsList([...list]);
      } else {
        setSearchStudentsList([...students]);
      }
    }
  };

  const createEventRepeats = () => {
    var repeats = [];
    for (var x in EqEventRepeatType) {
      if (isNaN(Number(x)) == false) {
        repeats.push({ key: x, value: EqEventRepeatType[x] });
      }
    }
    setEventRepeats(repeats);
  };

  const createTraining = () => {

    if(!userDetails.isSubscribed){
      openNotificationWithIcon(
        "Error!",
        "Kindly Subscribe to Create",
        eqNotificationTypes.error
      );
      return;
    }
    var data = setEventObject(formData);

    if (data.startDate != null) {
      setSpinning(true);

      Api.post(ApiEndpoints.CreateSchoolTraining, data).then(
        (response) => {
          if (response && response.status == 200) {
            openNotificationWithIcon(
              "Created!",
              "Training(s) have been created, you can go to trainings and look at all trainings",
              eqNotificationTypes.success
            );
            setIsError(false);
            setMessage(GetEventMessage(response.status));
            setEventDefaults();
            setAttendeeCardData([] as Array<IEqSchoolTrainingCardData>)
            // setEventAttendees([] as any);
            onActivityTitleChange("");
            onCreateOrUpdateSchoolTraining(response.data);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        setSpinning(false);

        },
        (error) => {
        setSpinning(false);

          if (error?.response?.status === 403) {
            openNotificationWithIcon(
              "Server error",
              "Not Authorized Kindly Add Payment or contact administrator."
            );
            setIsError(true);
          } else {
            openNotificationWithIcon(
              "Server error",
              "Error occurred while creating the event(s). Please try again or contact administrator."
            );
            setIsError(true);
          }
        }
      );
    } else {
      setIsError(true);
    }
  };

  const UpdateTrainingLesson = () => {
    var data = setEventObject(formData);
    setSpinning(true);

    // Api.post(ApiEndpoints.eventEdit, data).then(
    // Api.post(ApiEndpoints.UpdateTrainingLesson, data).then(
    Api.post(ApiEndpoints.UpdateSchoolTrainingWithAllLessons, data).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setIsEdit(false);
          setMessage(GetEventMessage(response.status));

          setEventDefaults();
          // createEventTypes();
          onCreateOrUpdateSchoolTraining(response.data, data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
        setSpinning(false);

      },
      (error) => {
        setSpinning(false);

        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const SettleAttendeeOrder = (attendeeOrder: any) => {
    var oldData = setEventObject(formData);
    setSpinning(true);

    Api.put(
      ApiEndpoints.SchoolTraining.SettleAttendeeOrder + "/" + attendeeOrder.id
    ).then(
      (response) => {
        setSpinning(false);

        if (response && response.status === 200) {
          setMessage(GetEventMessage(response.status));
          setEventDefaults();

          onCreateOrUpdateSchoolTraining(response.data, oldData);

          openNotificationWithIcon(
            "Updated!",
            "You have Successfully Updated Training",
            eqNotificationTypes.success
          );
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setSpinning(false);

        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const OnAcceptAttendeeRequest = (attendeeOrder: any) => {
    var oldData = setEventObject(formData);
    setSpinning(true);

    Api.put(
      ApiEndpoints.SchoolTraining.AcceptAttendeeOrderRequest + "/" + attendeeOrder.id
    ).then(
      (response) => {
        setSpinning(false);

        if (response && response.status === 200) {
          setMessage(GetEventMessage(response.status));
          setEventDefaults();

          onCreateOrUpdateSchoolTraining(response.data, oldData);

          openNotificationWithIcon(
            t("accepted"),
            t("youHaveSuccessfullyAcceptedRequest"),
            eqNotificationTypes.success
          );
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setSpinning(false);

        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  ////////////////

  const typeSelectProps: ISelectProps = {
    options: eventTypes.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: formData?.disciplineType,
    classNames: "extra-height",
    changeFunc: (value: string) => {
      let type: number = +value;

      if (type === EqDisciplineType.RidingCourse) {
        setFormData({ ...formData, disciplineType: type });
      } else {
        setFormData({ ...formData, disciplineType: type });
      }

      if (type == EqDisciplineType.Open) {
        setIsOpenEvent(true);
      } else {
        setIsOpenEvent(false);
      }
    },
  };

  const productSelectProps: ISelectProps = {
    isTag: false,
    classNames: `eq-addSelect extra-height ${isStudent ? "eq-card" : ""}`,
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    options: productOptions,
    defaultSelectedValue: formData?.productId ? formData.productId + "" : "",
    changeFunc: (value: string) => {
      // let product: number = +value;
      var product = products.find((item: any) => item.id == value);

      setFormData({ ...formData, productId: value, isRepeat: true });

      if (product) {
        setSelectedProduct(product);
        GetStudentListForSelectedProduct(product);
        UpdateAttendeeCardOnProductChange(product);
      }
      // if (attendeeCardData.length === 0) {
      //   addNewAttendeeCard();
      // }

      // maxSpaceCount: product.limit

      // spaceChangeFunc(product.limit);
    },
  };

  const repeatSelectProps: ISelectProps = {
    mode: "tags",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: t("selectRepeatingDuration"),
    classNames: "eq-addSelect extra-height",
    defaultSelectedValue: formData?.repeatType
      ? formData.repeatType.toString()
      : "",
    options: eventRepeats,
    changeFunc: (value: string) => {
      let repeat: number = +value;
      if (formData.disciplineType == EqDisciplineType.RidingCourse) {
        handleEndDateChangeOnRepeat(repeat);
      } else {
        setFormData({ ...formData, repeats: repeat });
      }
    },
  };

  // Function to handle changes in the start date
  const handleEndDateChangeOnRepeat = (repeat: any) => {
    let weekCount = selectedProduct?.lessons;
    if (repeat == EqEventRepeatType.Fortnightly) {
      weekCount = weekCount * 2;
    }
    const endDate = calculateEndDate(formData.startDate, weekCount);
    setFormData({ ...formData, repeats: repeat, endDate });
  };

  const trainerSelectProps: ISelectProps = {
    options: trainersOptions,
    defaultSelectedValue: formData?.trainerKey,
    classNames: "extra-height",
    placeholder: t("selectTrainer"),

    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, trainerKey: type });
    },
  };

  const onChangeGroupSelect = (value: any) => {
    const selectedMembers = [...value];
    setSelectedGroups(selectedMembers);
    getStudentPopulation(selectedMembers);
  };

  const GroupProps: ISelectProps = {
    mode: "multiple",
    showSearch: true,
    optionFilterProp: "label",
    filterOption: true,
    isTag: false,
    showArrow: false,
    allowClear: false,
    changeFunc: onChangeGroupSelect,
    // changeFunc: (value: any) => {
    //   setFormData({ ...formData,selectedGroups: value });
    // },
    classNames: "eq-multiSelect eq-searchSelect extra-height",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="eq-search-icon">
        <img alt="" src="/search-icon.svg" />
      </span>,
    ],
    selectedIcon: [
      <span className="">
        <img alt="" src="/check-square.svg" />
      </span>,
    ],
    options: groupPopulation,
    // defaultSelectedValue: selectedGroups,
    defaultSelectedValue: selectedGroups,
    // handleOnBlur:(e)=>{
    isDropdownRenderClicked: isDropdownClicked,
    // }
  };

  const startOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.startDate,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      if (formData.disciplineType == EqDisciplineType.RidingCourse) {
        handleStartDateChange(date);
      } else {
        setFormData({ ...formData, startDate: moment(date) });
        calculateTrainingsCreated(moment(date), null, null);
      }
    },
  };

  // Function to handle changes in the start date
  const handleStartDateChange = (date: any) => {

    const dayOfWeek = date.day()+1;
    console.log(dayOfWeek);

    // change weekDays Component on Date Change on Riding course

    setWeekDays({
      ...weekDays,
      selectedWeekDays: [dayOfWeek+""],
      weekDayTags: [dayOfWeek+""],
    });
    
    let weekCount = selectedProduct?.lessons;
    if (formData.repeats == EqEventRepeatType.Fortnightly) {
      weekCount = weekCount * 2;
    }
    const endDate = calculateEndDate(date, weekCount);
    setFormData({ ...formData, startDate: moment(date).toDate(), endDate });
  };

  const calculateEndDate = (startDate: any, weeks: any) => {
    if (!startDate) return null;
    return moment(startDate).add(weeks, "weeks").toDate();
  };

  const endOnProps: IDatePickerOptions = {
    isSuffixIcon: true,
    isBordered: true,
    defaultValue: formData?.endDate,
    placeHolder: t("date"),
    classNames: "eq-event",
    onChangeFunc: (date: any) => {
      setFormData({ ...formData, endDate: moment(date) });
      calculateTrainingsCreated(null, moment(date), null);
    },
  };

  const weekDaysSelectProps: ISelectProps = {
    mode: "multiple",
    isTag: false,
    showArrow: true,
    allowClear: true,
    classNames: "eq-multiSelect eq-addSelect",
    ddClassName: "eq-select-dropdown",
    icon: [
      <span className="">
        <img alt="" src="/caret-down.svg" />
      </span>,
    ],
    placeholder: t("selectDays"),
    defaultSelectedValue: weekDays.selectedWeekDays,
    options: weekDays.allWeekDays,
    changeFunc: (param: any) => {
      
      setWeekDays({
        ...weekDays,
        selectedWeekDays: [...param],
        weekDayTags: [...param],
      });
      calculateTrainingsCreated(null, null, [...param]);
    },
  };

  const calculateTrainingsCreated = (
    start?: any,
    end?: any,
    weekdays?: any
  ) => {
    const startDate = start || formData.startDate;
    const endDate = end || formData.endDate;
    const selectedKeys = weekdays || weekDays.selectedWeekDays; // Assuming it contains keys like ["1", "2", "3"]

    // Create a mapping object to map keys to day names
    const keyToDayName: any = {
      "1": "Sunday",
      "2": "Monday",
      "3": "Tuesday",
      "4": "Wednesday",
      "5": "Thursday",
      "6": "Friday",
      "7": "Saturday",
    };

    if (startDate && endDate && selectedKeys.length > 0) {
      const start = moment(startDate);
      const end = moment(endDate);
      let count = 0;

      while (start.isSameOrBefore(end)) {
        const dayName = start.format("dddd");
        const dayKey = Object.keys(keyToDayName).find(
          (key: any) => keyToDayName[key] === dayName
        );

        if (selectedKeys.includes(dayKey)) {
          count++;
        }
        start.add(1, "days");
      }

      console.log(`Number of training sessions: ${count}`);
      setCreatedLessonCount(count);
    }
  };

  const attendeeCardTimeFromChange = (cardkey: number, timeFrom: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].timeFrom = moment(timeFrom);
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardTimeToChange = (cardkey: number, timeTo: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].timeTo = moment(timeTo);
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardDurationChange = (cardkey: number, minutes: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].duration = minutes;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardAvailableSpaceChange = (cardkey: number, spaces: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].maxSpaceCount = spaces;
    }
    setAttendeeCardData(modifyList);
  };
  const attendeeCardPriceChange = (cardkey: number, price: any) => {
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].price = price;
    }
    setAttendeeCardData(modifyList);
  };

  const attendeeCardTrainingLevelChange = (cardkey: number, levels: any) => {
    //console.log(attendeeCardData);
    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].trainingLevel = levels;
    }
    setAttendeeCardData(modifyList);
  };

  // const attendeeCardTrainingProductChange = (
  //   cardkey: number,
  //   productKey: any
  // ) => {

  //   var productSelected = productOptions.find(
  //     (item: any) => item.key == productKey
  //   );

  //   const index = attendeeCardData.findIndex((item: any) => {
  //     return item.cardkey == cardkey;
  //   });
  //   var modifyList = [...attendeeCardData];
  //   if (index > -1) {
  //     modifyList[index].productId = productKey;
  //     modifyList[index].maxSpaceCount = productSelected.limit;
  //   }
  //   setAttendeeCardData(modifyList);
  // };

  const attendeeCardHorseChange = (
    cardkey: number,
    selectedHorsesData: any
  ) => {
    //console.log(attendees);

    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      modifyList[index].attendeesHorse = selectedHorsesData;
    }

    setAttendeeCardData(modifyList);
  };

  const attendeeCardAttendeesChange = (cardkey: number, updatedAttendees: any) => {
    //console.log(attendees);
    

    const index = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];
    if (index > -1) {
      
          // Extract existing keys from the attendees array
          let existingKeys = modifyList[index]?.attendees?.map((a: any) => a.key) || [];
      
          // Filter out new attendees whose keys already exist in the attendees array
          let filteredAttendees = updatedAttendees.filter(
            (newAttendee: any) => !existingKeys.includes(newAttendee.key)
          );
    
          // Concatenate the filtered attendees with the existing attendees
          modifyList[index].attendees = [
            ...modifyList[index].attendees,
            ...filteredAttendees,
          ];
      let keys = modifyList[index]?.attendees?.map((a: any) => a.key);
      modifyList[index].attendeesKeys = keys;
    }

    setAttendeeCardData(modifyList);
  };

  const removeAttendeeFromCard = (
    cardkey: number,
    attendeeKey: any,
    refund: boolean
  ) => {
    //console.log(attendees);
    var removedAttendees = [];

    const cardIndex = attendeeCardData.findIndex((item: any) => {
      return item.cardkey == cardkey;
    });
    var modifyList = [...attendeeCardData];

    if (cardIndex > -1) {
      var attendeesList = modifyList[cardIndex].attendees;
      var indexAttendee = attendeesList.findIndex((x) => x.key === attendeeKey);
      if (indexAttendee !== -1) {
        removedAttendees = attendeesList.splice(indexAttendee, 1);
      }
      modifyList[cardIndex].attendees = attendeesList;

      var keysList = modifyList[cardIndex].attendeesKeys;
      var indexKey = keysList.indexOf(attendeeKey);

      if (indexKey !== -1) {
        keysList.splice(indexKey, 1);
      }
      modifyList[cardIndex].attendeesKeys = keysList;
      // let keys = modifyList[index]?.attendees?.map((a: any) => a.key);
    }

    setAttendeeCardData(modifyList);
    // if attendee is not exist already is Data
    if (removedAttendees[0].id !== undefined) {
      const removedAttendee = {
        refund: refund,
        removedAttendee: removedAttendees[0],
      };
      setRemovedAttendeesList([...removedAttendeesList, removedAttendee]);
    }
  };

  const removeAttendeeCard = (cardkey: number) => {
    var newDataArr = [...attendeeCardData];
    if (attendeeCardData != null && attendeeCardData.length > 0) {
      newDataArr = newDataArr.filter((x: any) => x.cardkey != cardkey);
    }
    if (newDataArr.length > 0) {
      newDataArr[newDataArr.length - 1].display = true;
    }
    setAttendeeCardData(newDataArr);
  };
  //console.log("Data", attendeeCardData);

  const UpdateAttendeeCardOnProductChange = (selectedProduct: any) => {
    var newDataArr = [];
    var newAddedCardData = newEmptyCardData;
    
    // Extracting the required information

    const attendees = selectedProduct.productOrders
  .filter((entry: any) =>entry.status ===ProductOrderStatus.Accepted || entry.status === ProductOrderStatus.Paid)
  .map((entry: any) => ({
    key: entry.student.email,
    user: entry.student,
  }));
  
const emailList = selectedProduct.productOrders
  .filter((entry: any) => entry.status === ProductOrderStatus.Accepted || entry.status === ProductOrderStatus.Paid)
  .map((entry: any) => entry.student.email);

    // const attendees = selectedProduct.productOrders.map((entry: any) => ({
    //   key: entry.student.email,
    //   user: entry.student,
    // }));

    // const emailList = selectedProduct.productOrders.map(
    //   (entry: any) => entry.student.email
    // );
  
    newAddedCardData.maxSpaceCount = selectedProduct?.limit;
    newAddedCardData.attendees = attendees;
    newAddedCardData.attendeesKeys = emailList;

    newDataArr.push({ ...newAddedCardData });
    setAttendeeCardData(newDataArr);
  };

  const addNewAttendeeCard = () => {
    
    console.log("length", attendeeCardData.length);
    
    var newDataArr = [...attendeeCardData];
    var newAddedCardData = newEmptyCardData;
    newAddedCardData.attendeesKeys=[];
    if (attendeeCardData.length !== 0) {
      let lastCard = attendeeCardData.reduce((max, card) =>
        max.cardkey > card.cardkey ? max : card
      );
      newAddedCardData.price = lastCard.price;

      if (formData.disciplineType === EqDisciplineType.Jumping) {
        // newAddedCardData.maxSpaceCount = 4;
        newAddedCardData.maxSpaceCount = lastCard.maxSpaceCount;
        newAddedCardData.trainingLevel = lastCard.trainingLevel;
        newAddedCardData.duration=lastCard.duration

      } 
      else if(formData.disciplineType === EqDisciplineType.Dressage){
        newAddedCardData.maxSpaceCount = lastCard.maxSpaceCount;
        newAddedCardData.duration=lastCard.duration
        newAddedCardData.trainingLevel = "";

      }
      else if (formData.disciplineType === EqDisciplineType.RidingCourse) {
        newAddedCardData.maxSpaceCount = selectedProduct?.limit;
      } else {
        newAddedCardData.maxSpaceCount = lastCard.maxSpaceCount;
        newAddedCardData.trainingLevel = "";
      }

      // newAddedCardData = attendeeCardData.reduce((max, card) => max.cardkey > card.cardkey ? max : card);
      console.log(newAddedCardData);
      // newAddedCardData=lastCard;
    } else {

      // newAddedCardData.attendeesKeys=[];
      if (formData.disciplineType == EqDisciplineType.Jumping) {
        newAddedCardData.maxSpaceCount = 4;
        newAddedCardData.trainingLevel = 1;
      } else if (formData.disciplineType == EqDisciplineType.RidingCourse) {
        newAddedCardData.maxSpaceCount = selectedProduct?.limit;
      } else {
        newAddedCardData.maxSpaceCount = 1;
        newAddedCardData.trainingLevel = "";
      }
    }

    if (attendeeCardData.length > 0) {
      var lastAddedCardData = attendeeCardData[attendeeCardData.length - 1];
      newDataArr[attendeeCardData.length - 1].display = false;
      newAddedCardData.timeFrom = moment(lastAddedCardData.timeFrom).add(
        lastAddedCardData.duration,
        "minutes"
      );
      newAddedCardData.cardkey = lastAddedCardData.cardkey + 1;
      newAddedCardData.attendeesKeys = [];
    } else {
      setMultipleStartOn([]);
    }
    //console.log(newAddedCardData);
    newDataArr.push({ ...newAddedCardData });

    setAttendeeCardData(newDataArr);
  };
  const onDragAttendee = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    //console.log(source);
    //console.log(destination);
    var modifyList = [...attendeeCardData];
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    const sourceCard = attendeeCardData[sInd - 1];
    const destCard = attendeeCardData[dInd - 1];

    const srcKey = sourceCard.attendeesKeys[source.index];
    if (srcKey) {
      let srcKeys = [...sourceCard.attendeesKeys];
      let srcAttendees = sourceCard.attendees.filter((item: any) => {
        return item.key != srcKey;
      });
      let destKeys = [...destCard.attendeesKeys];
      let destAttendees = [...destCard.attendees];
      if (sInd === dInd) {
        const [reorderedKeys] = srcKeys.splice(result.source.index, 1);
        srcKeys.splice(result.destination.index, 0, reorderedKeys);
        sourceCard.attendeesKeys = srcKeys;

        modifyList[sInd - 1] = sourceCard;
      } else {
        const itemExists = destKeys.indexOf(srcKey);
        if (itemExists === -1) {
          destKeys.push(srcKey);
          destCard.attendeesKeys = destKeys;

          let srcAttendee = sourceCard.attendees
            .filter((item: any) => {
              return item.key == srcKey;
            })
            .shift();
          destAttendees.push(srcAttendee);
          destCard.attendees = destAttendees;

          modifyList[dInd - 1] = destCard;

          srcKeys.splice(source.index, 1);
          sourceCard.attendeesKeys = srcKeys;

          sourceCard.attendees = srcAttendees;

          modifyList[sInd - 1] = sourceCard;
        }
      }
    }
    //console.log(modifyList);
    setAttendeeCardData(modifyList);
  };

  const handleDropdownRenderClick = (e: any) => {
    if (!isDropdownClicked) {
      // Perform your desired logic here when the dropdownRender button is clicked
      console.log("DropdownRender button clicked");
      navigate(FrontEndPoints.trainer.groupsBoard, {
        state: {
          prevPath: location.pathname,
        },
      });
    } else {
      setIsDropdownClicked(false);
    }
  };
  return (
    <div className="create-edit-school-training">
      <Spin spinning={spinning} size="large">

      <div className="eq-create-school-training-container">
        <div className="">
          <Row gutter={gutter} className="event-info-input">
            <Col className="gutter-row" span={24}>
              <Row>
                <Col className="gutter-row" span={24}>
                  <Row justify="space-between">
                    <Col className="col-1" xs={24} sm={24} md={12}>
                      <div className="eq-input-group">
                        <div className="input-label">{t("title")}</div>
                        <EqInput
                          placeholder={t("enterTitle")}
                          className="name-input"
                          value={formData.title}
                          disabled={isStudent}
                          onBlur={(e: any) =>
                            onActivityTitleChange(e.currentTarget.value)
                          }
                          onChange={(event: any) =>
                            setFormData({
                              ...formData,
                              title: event?.target?.value,
                            })
                          }
                        />
                      </div>
                      {eventTypes.length > 1 &&
                      <div className="eq-input-group">
                        <div className="event-type">
                          <div className="input-label">{t("type")}</div>
                          <div className="toggler">
                            <EqSelect
                              isDisabled={isStudent}
                              {...typeSelectProps}
                              />
                          </div>
                        </div>
                      </div>
                            }
                      {formData.disciplineType === EqDisciplineType.RidingCourse && (
                        <div className="eq-input-group">
                          <div className="event-type">
                            <div className="input-label">{t("products")}</div>
                            <div className="toggler">
                              <EqSelect
                                isDisabled={isStudent || isEdit}
                                {...productSelectProps}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Show Data on 1nd Column on Create */}
                      {!isEdit && (
                        <div className="date-time-inputs">
                          <Row className="eq-input-group">
                            <Col className="input-label">{t("startDate")}</Col>
                            <Col span={24}>
                              <EqDatePicker
                                isDisabled={isStudent}
                                {...startOnProps}
                              ></EqDatePicker>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                    <Col className="col-2" xs={24} sm={24} md={12}>
                      <Row className="eq-input-group">
                        <Col className="input-label">{t("trainer")}</Col>
                        <Col span={24}>
                          <EqSelect
                            isDisabled={isStudent || isTrainer} 
                            extraHeight={true}
                            {...trainerSelectProps}
                          />
                        </Col>
                      </Row>

                      {isEdit == false && (
                        <>
                          {isMultipleDateSelected == false && (
                            <div className="eq-input-group">
                              <label className="input-label">
                                {t("repeats")}
                              </label>
                              <div className="repeat-time">
                                <Switch
                                  disabled={
                                    formData.disciplineType ===
                                    EqDisciplineType.RidingCourse
                                  }
                                  checked={formData.isRepeat}
                                  onChange={(checked: any) =>
                                    setFormData({
                                      ...formData,
                                      isRepeat: checked,
                                    })
                                  }
                                />
                                {formData.isRepeat && (
                                  <EqSelect
                                    isDisabled={isStudent}
                                    {...repeatSelectProps}
                                  />
                                )}
                              </div>
                            </div>
                          )}

                          {isMultipleDateSelected == false && (
                            <Row className="eq-input-group">
                              {formData.isRepeat &&
                                (formData.repeats == EqEventRepeatType.Weekly ||
                                  formData.repeats ==
                                    EqEventRepeatType.Fortnightly) && (
                                  <>
                                    <Col className="input-label">
                                      {t("week")} {t("days")}
                                    </Col>
                                    <Col span={24}>
                                      <Row>
                                        {weekDays?.weekDayTags?.length > 0 && (
                                          <>
                                            {weekDays?.weekDayTags?.map(
                                              (item: any) => (
                                                <Col
                                                  className="eq-pl-discipline-list"
                                                  key={item.key}
                                                >
                                                  <span className="eq-pl-discipline-list-active">
                                                    {item.description}
                                                  </span>
                                                </Col>
                                              )
                                            )}
                                          </>
                                        )}
                                      </Row>

                                      <Row>
                                        <Col flex="auto">
                                          <EqSelect
                                            isDisabled={isStudent}
                                            extraHeight={true}
                                            {...weekDaysSelectProps}
                                          ></EqSelect>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                )}
                            </Row>
                          )}
                        </>
                      )}

                      {/* when Training is Repeated select end Date */}
                      {formData.isRepeat == true &&
                        isMultipleDateSelected == false && (
                          <Row className="eq-input-group">
                            <Col className="input-label">{t("endsOnDate")}</Col>
                            <Col span={24}>
                              <EqDatePicker
                                isDisabled={
                                  formData.disciplineType ===
                                  EqDisciplineType.RidingCourse
                                }
                                {...endOnProps}
                              ></EqDatePicker>
                            </Col>
                          </Row>
                        )}

                      {/* groups only show if jumping is Selected */}
                      {formData.disciplineType == EqDisciplineType.Jumping && (
                        <>
                          <Row
                            gutter={gutter}
                            className="eq-cg-members-header eq-input-group"
                          >
                            <Col className="gutter-row" span={24}>
                              <Row align="middle" justify="start">
                                <Col span={24} className="input-label">
                                  <Row
                                    className="eq-cg-right"
                                    justify="end"
                                    align="middle"
                                  >
                                    <Col flex="auto">{t("groups")}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="eq-cg-search">
                                  <EqSelect
                                    isDisabled={isStudent}
                                    {...GroupProps}
                                    dropdownRender={(menu: any) => (
                                      <>
                                        {menu}
                                        <Divider style={{ margin: "8px 0" }} />
                                        <Space
                                          align="center"
                                          style={{ padding: "0 8px 4px" }}
                                          onClick={() =>
                                            setIsDropdownClicked(true)
                                          }
                                        >
                                          <Typography.Link
                                            onClick={handleDropdownRenderClick}
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <PlusOutlined /> {t("createNew")}
                                          </Typography.Link>
                                        </Space>
                                      </>
                                    )}
                                  ></EqSelect>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}

                      {/* Show Data on 2nd Column on Edit */}
                      {isEdit && (
                        <div className="date-time-inputs">
                          <Row className="eq-input-group">
                            <Col className="input-label">{t("date")}</Col>
                            <Col span={24}>
                              <EqDatePicker
                                isDisabled={isStudent}
                                {...startOnProps}
                              ></EqDatePicker>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className="attendee-card-section"
                align="middle"
                justify="space-around"
              >
                <Col xs={24} sm={24} md={24}>
                  <div className="attendee-card-box">
                    {/* <div className={`attendee-card-box ${isStudent ? "attendee-card-box-student" : ""}`}> */}
                    <DragDropContext onDragEnd={onDragAttendee}>
                      <>
                        {attendeeCardData && attendeeCardData.length > 0 ? (
                          attendeeCardData?.sort((a:any,b:any)=>a.timeFrom-b.timeFrom).map(
                            (
                              item: IEqSchoolTrainingCardData,
                              index: number
                            ) => (
                              <Droppable
                                // isDropDisabled={ isStudent}
                                key={item.cardkey}
                                droppableId={`${item.cardkey.toString()}`}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {/* {item.cardkey} */}
                                    <EqSchoolTrainingCard
                                      key={item.cardkey}
                                      // key={index}
                                      {...{
                                        data: item,
                                        productOptions: productOptions,
                                        selectedProduct: selectedProduct,
                                        timeFromChange:
                                          attendeeCardTimeFromChange,
                                        timeToChange: attendeeCardTimeToChange,
                                        durationChange:
                                          attendeeCardDurationChange,
                                        maxSpaceCountChange:
                                          attendeeCardAvailableSpaceChange,
                                        attendeeCardPriceChange:
                                          attendeeCardPriceChange,
                                        trainingLevelChange:
                                          attendeeCardTrainingLevelChange,
                                        // trainingProductChange:
                                        //   attendeeCardTrainingProductChange,
                                        attendeesChange:
                                          attendeeCardAttendeesChange,
                                        attendeeCardHorseChange:
                                          attendeeCardHorseChange,
                                        removeCard: removeAttendeeCard,
                                        removeAttendeeFromCard:
                                          removeAttendeeFromCard,
                                        groupKeys: [...groupKeys],
                                        selectedGroups: [...selectedGroups],
                                        groupStudentPopulation: [
                                          ...groupStudentPopulation,
                                        ],
                                        studentsByGroup: [...studentsByGroup],
                                        studentPopulation: studentPopulation,
                                        searchStudentsList: [
                                          ...searchStudentsList,
                                        ],
                                        studentHorsesList: [
                                          ...studentHorsesList,
                                        ],
                                        multipleStartOn: [...multipleStartOn],
                                        startDate: formData.startDate,
                                        trainingType: formData.disciplineType,
                                        isStudent: isStudent,
                                        isEdit: isEdit,
                                        isPreviousTraining:
                                          isPreviousTraining,
                                          SettleAttendeeOrder:
                                                SettleAttendeeOrder,
                                                OnAcceptAttendeeRequest:OnAcceptAttendeeRequest,
                                        enableCardRemove:
                                          attendeeCardData?.length > 1
                                            ? true
                                            : false,
                                      }}
                                    ></EqSchoolTrainingCard>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            )
                          )
                        ) : (
                          <Row className="empty-card">
                            <Col span={24}>
                              <ToolFilled style={{ fontSize: "2rem" }} />
                            </Col>

                            <Col>{t("noActivityYet")}...</Col>
                          </Row>
                        )}
                        {/* {!isEdit && ( */}
                        {formData.disciplineType !=
                          EqDisciplineType.RidingCourse && (
                          <Row
                            className="add-btn-icon"
                            onClick={addNewAttendeeCard}
                          >
                            <img alt="" className="add" src="/plus-black.svg" />
                          </Row>
                        )}
                      </>
                    </DragDropContext>
                  </div>
                </Col>
              </Row>

              {true && (
                <Row justify="center" align="bottom" className="eq-cg-btn">
                  <Col className="eq-cg-buttons">
                    {isEdit ? (
                      !isStudent && (
                        <EqButton
                          block
                          disabled={isStudent}
                          type="submit"
                          classNames="btn-background-orange"
                          onClick={UpdateTrainingLesson}
                          // onClick={editTraining}
                        >
                          {t("saveChanges")}
                        </EqButton>
                      )
                    ) : attendeeCardData && attendeeCardData.length > 0 ? (
                      <EqButton
                        disabled={isStudent}
                        type="submit"
                        classNames="btn-background-orange"
                        onClick={createTraining}
                      >
                        {t("create")}
                      </EqButton>
                    ) : (
                      ""
                    )}
                  </Col>

                  {!isEdit && (
                    <Col className="eq-cg-buttons">
                      <EqButton
                        type="button"
                        bordered="true"
                        classNames="btn-light-gray"
                        onClick={onCancelSchoolTraining}
                      >
                        {t("cancel")}
                      </EqButton>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
      </Spin>
    </div>
  );
};
export default CreateOrEditSchoolTraining;
