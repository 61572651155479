import React, { useEffect, useState } from "react";
import "./EqRegister.scss";
import { Col, Divider, Row, Steps, Typography } from "antd";
import EqButton from "../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";
import EqUserOption from "./eqUserOption/EqUserOption";
import EqRegistrationForm from "./eqRegistrationForm/EqRegistrationForm";
import { useLocation, useNavigate } from "react-router-dom";
import { Professions } from "../shared/enums";
import Api from "../shared/AApi";
import {
    dateFormat,
    EqStatusCode,
    FrontEndPoints,
    GetRegistrationMessageKey,
} from "../shared/Common";
import moment from "moment";
import ConfirmRegister from "./confirmRegister/ConfirmRegister";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { EqSpace } from "../custom/eqSpace/EqSpace";

const { Step } = Steps;

const EqRegister = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    var defaultUserValue = {
        fullName: "",
        email: "",
        repeatEmail: "",
        password: "",
        repeatPassword: "",
        userType: Professions.trainer,
        isUnderAge: false,
    };
    var defaultFeedback = { error: "", success: "" };

    const { Text, Title } = Typography;
    const [current, setCurrent] = useState(0);
    const [feedback, setFeedback] = useState(defaultFeedback) as any;
    const [formData, setFormData] = useState(defaultUserValue) as any;

    const [showInviteTxt, setShowInviteTxt] = useState(false);

    const steps = [
        {
            content: <EqUserOption formData={formData} setFormData={setFormData} />,
        },
        {
            content: <EqRegistrationForm formData={formData} setFormData={setFormData} />,
        },
        {
            content: <ConfirmRegister />,
        },
        {
            content: "4-content",
        },
    ];

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const validateRegisterForm = (): string => {
        console.log(defaultUserValue);
        var output = "";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!formData.userType || !formData.userType.toString().length) {
            output += "userTypeError";
        } else if (
            formData.userType === 8 &&
            (!formData.companyName || !formData.companyName.length)
        ) {
            output += "userNameError";
        } else if (formData.userType !== 8 && (!formData.fullName || !formData.fullName.length)) {
            output += "userNameError";
        } else if (!formData.email || !formData.email.length) {
            output += "emailError";
        } else if (!emailRegex.test(formData.email)) {
            output += "emailError";
        }
        // else if (!formData.repeatEmail || !formData.repeatEmail.length) {
        //     output += "repeatEmailError";
        // }
        // else if (formData.email !== formData.repeatEmail) {
        //     output += "emailMatchError";
        // }
        else if (!formData.passIsValid) {
            output += "passwordError";
        } else if (!formData.repeatPassword || !formData.repeatPassword.length) {
            output += "repeatPasswordError";
        } else if (formData.password !== formData.repeatPassword) {
            output += "pwdMatchError";
        } else if (!formData.userType || !formData.userType.toString().length) {
            output += "userTypeError";
        }
        // else if (!isUnderAge && formData.userType == Professions.student) {
        //   if (!formData.personalNumber || !formData.personalNumber.length)
        //     output += t("personalNumberError");
        // }

        // else if (isUnderAge && formData.userType == Professions.student) {
        //   if (!formData.dateOfBirth) output += t("dateOfBirthError");
        // }
        // else if (formData.userType == Professions.student) {
        //     if (!formData.dateOfBirth) output += t("dateOfBirthError");
        // }
        return output;
    };
    useEffect(() => {
        selectProfession(Professions.trainer);
        // const params = new URLSearchParams(props.location?.search);
        const params = new URLSearchParams(window.location.search);

        if (params != null || undefined) {
            var professionKey = params.get("profession");
            var email = params.get("email");
            var trainerKey = params.get("trainerKey");
            var schoolKey = params.get("schoolKey");
            if (professionKey && email) {
                setShowInviteTxt(true);
                setFormData({ ...formData, email: email, userType: +professionKey });
            } else if (trainerKey && professionKey) {
                setShowInviteTxt(true);
                setFormData({
                    ...formData,
                    trainerKey: trainerKey,
                    userType: +professionKey,
                });
            } else if (schoolKey && professionKey) {
                setShowInviteTxt(true);
                setFormData({
                    ...formData,
                    schoolKey: schoolKey,
                    userType: +professionKey,
                });
            } else if (professionKey) {
                selectProfession(professionKey);
            }
        }
    }, []);

    const RegisterUser = (event: any) => {
        var res = validateRegisterForm();
        
        //console.log(formData);
        if (!res.length) {
            setFeedback(defaultFeedback);
            // format
            var apiFormData = { ...formData };
            apiFormData.dateOfBirth = moment(formData.dateOfBirth, dateFormat).utc().valueOf();

            var path = "Account/Register";

            const params = new URLSearchParams(window.location.search);
            if (params != null || undefined) {
                var trainerKey = params.get("trainerKey");
                var schoolKey = params.get("schoolKey");

                if (trainerKey) {
                    path = "Account/RegisterStudentByInvitedLink";
                } else if (schoolKey) {
                    path = "Account/RegisterStudentBySchoolInvitedLink";
                }
            }
            Api.post(path, apiFormData).then(
                (response) => {
                    if (response && (response.status == 200 || response.status == 202)) {
                        setFormData(defaultUserValue);
                        // setFeedback({
                        //     ...feedback,
                        //     success: t(GetRegistrationMessageKey(response.status)),
                        // });
                        setCurrent(current + 1);
                        // NavigateToLogin(response.status);
                    } else {
                        showError(GetRegistrationMessageKey(EqStatusCode.preConditionFailed409));
                    }
                },
                (err) => {
                    console.log(err.response);
                    showError(GetRegistrationMessageKey(err.response.status));
                }
            );
        } else {
            showError(res);
        }
        //setFeedback({ ...feedback, success: t(GetRegistrationMessageKey(200)) });
    };

    const showError = (msg: string) => {
        setFeedback({ ...feedback, error: msg });
    };
    const selectProfession = (profession: any) => {
        setFormData({ ...formData, userType: profession });
    };

    const NavigateToLogin = (e: any) => {
        // e.stopPropagation();

        const params = new URLSearchParams(window.location.search);

        if (params != null || undefined) {
            var trainerKey = params.get("ByTrainer") || params.get("trainerKey");
            var schoolKey = params.get("BySchool") || params.get("schoolKey");

            if (trainerKey) {
                // navigate(`${FrontEndPoints.login}?key=${trainerKey}`);
                navigate(FrontEndPoints.login, {
                    state: { trainerKey: trainerKey, prevPath: location.pathname },
                });
            } else if (schoolKey) {
                // navigate(`${FrontEndPoints.login}?key=${trainerKey}`);
                navigate(FrontEndPoints.login, {
                    state: { schoolKey: schoolKey, prevPath: location.pathname },
                });
            } else {
                navigate(FrontEndPoints.login, {
                    state: { prevPath: location.pathname },
                });
            }
        }
    };

    return (
        <div className="eq-Register">
            {current === 0 && (
                <Row className="register-heading">
                    <Title className="heading">{t("selectYourRole")}</Title>
                    <Text className="text">{t("selectYourRoleText")}</Text>
                </Row>
            )}
            {current === 1 && (
                <>
                    <Row className="back-btn" onClick={prev}>
                        <ArrowLeftOutlined />{" "}
                    </Row>
                    <Row className="register-heading">
                        <Title className="heading">{t("register")}</Title>
                        {/* <Text className='text'>{t("provideBasicDetails")}</Text> */}
                    </Row>
                </>
            )}
            {current === 2 && (
                <Row className="register-heading">
                    <Title className="heading">{t("verifyEmail")}</Title>
                </Row>
            )}

            {current !== 2 && (
                <div className="register-steps">
                    <Steps current={current} labelPlacement="vertical">
                        {steps.map((item, index) => (
                            <Step key={index} />
                        ))}
                    </Steps>
                </div>
            )}

            <div className="steps-content">
                <div>{steps[current].content}</div>
            </div>
            <div className="error-show">
                {feedback.error.length > 0 && (
                    <Text id="merrorMsg" className="show invalid">
                        {t(feedback.error)}
                    </Text>
                )}
                {feedback.success.length > 0 && (
                    <Text id="msuccessMsg" className="show valid">
                        {t(feedback.success)}
                    </Text>
                )}
            </div>
            {current !== 2 &&(

            
            <Row className="steps-btn" justify="center">
                {current === 0 && (
                    <EqButton type="primary" onClick={next}>
                        {t("next")}
                    </EqButton>
                )}
                {current === 1 && (
                    <EqButton type="primary" onClick={RegisterUser}>
                        {t("register")}
                    </EqButton>
                )}
            </Row>
)}
            {current === 2 && (
                <>
                    <div className="eq-signIn">
                        <EqSpace
                            direction="vertical"
                            style={{
                                width: "100%",
                                padding: "1% 8%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div className="eq-anchor-signIn">
                                {t("alreadyVerified")}{" "}
                                {/* <a
            href={FrontEndPoints.login}
            className="eq-signIn"
        >
            {t("signInHere")}
        </a> */}
                            </div>
                        </EqSpace>
                    </div>
                    <Row justify="center">
                        <EqButton type="primary" onClick={NavigateToLogin}>
                            {t("logIn")}
                        </EqButton>
                    </Row>
                </>
            )}

            {current !== 2 && (
                <>
                    <Divider plain>{t("or")}</Divider>
                    <div className="eq-signIn">
                        <EqSpace
                            direction="vertical"
                            style={{
                                width: "100%",
                                padding: "1% 8%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div className="eq-anchor-signIn">
                                {t("alreadyRegistered")}{" "}
                                <a href={FrontEndPoints.login} className="eq-signIn">
                                    {t("signInHere")}
                                </a>
                            </div>
                        </EqSpace>
                    </div>
                </>
            )}
        </div>
    );
};

export default EqRegister;
